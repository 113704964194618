import SaveIcon from "@mui/icons-material/Save";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { usePartialEditSubTaskMutation } from "../../../api/services";
import { SubTask } from "../../../api/taskTypes";
import { durationFormatter, parseDuration } from "../../../api/tasksUtils";

interface Values {
  executionDuration: string;
}

type DurationFormProps = {
  subtask: SubTask;
};

const DurationForm = ({ subtask }: DurationFormProps) => {
  const partialEditSubTaskMutation = usePartialEditSubTaskMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      executionDuration: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    formMethods.reset({
      executionDuration: durationFormatter(subtask?.executionDuration) ?? ""
    });
  }, [subtask, formMethods]);

  const onSubmit = async (values: Values) => {
    partialEditSubTaskMutation.mutate({
      id: subtask.id,
      executionDuration: parseDuration(values.executionDuration),
      executionDetails: subtask.executionDetails
    });
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Controller
        name="executionDuration"
        control={formMethods.control}
        rules={{
          validate: {
            value: (value) => {
              if (value) {
                const duration = durationFormatter(parseDuration(value));
                if (!duration) {
                  return "Durée invalide";
                }
              }
              return true;
            }
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            error={!!formMethods.formState.errors.executionDuration}
            helperText={
              formMethods.formState.errors.executionDuration?.message ?? "En heures ou minutes : 01h30m, 01h ou 30m"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" type="submit">
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </form>
  );
};
export default DurationForm;
