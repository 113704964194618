import { Route } from "react-router-dom";
import CalendarPageMermaid from "./pages/CalendarPageMermaid";
import CalendarPagePrepare from "./pages/CalendarPagePrepare";
import CalendarsArchivedPage from "./pages/CalendarsArchivedPage";
import CalendarsPage from "./pages/CalendarsPage";
import CompaniesPage from "./pages/CompaniesPage";
import ThemesPage from "./pages/ThemesPage";

const routes = (
  <Route path="/preparation/*">
    <Route path="themes" element={<ThemesPage />} />
    <Route path="companies" element={<CompaniesPage />} />
    <Route path="calendars" element={<CalendarsPage />} />
    <Route path="calendars/archived" element={<CalendarsArchivedPage />} />
    <Route path="planning/:calendarId" element={<CalendarPageMermaid />} />
    <Route path="calendars/:calendarId" element={<CalendarPagePrepare />} />
  </Route>
);

export default routes;
