import IconDashboard from "@mui/icons-material/Dashboard";
import IconDateRange from "@mui/icons-material/DateRange";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Divider, IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useToken } from "../../api/servicesBase";
import { UserContext } from "../../global/providers/UserProvider";
export default function NavList({ onClose }) {
  const userContext = useContext(UserContext);
  const { setToken } = useToken();

  // Sous-Navigation
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  // Déconnexion
  const logout = () => {
    setToken(null);
    window.location.href = "/authenticate";
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      subheader={
        <>
          <ListSubheader component="div" id="nested-list-subheader" sx={{ pt: 2, pb: 2 }}>
            Cloturer
            <IconButton
              style={{ float: "right" }}
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <MenuOpenIcon />
            </IconButton>
          </ListSubheader>
        </>
      }
    >
      <Divider />

      <ListItemButton component={Link} to="/dashboard" onClick={onClose}>
        <ListItemIcon>
          <IconDashboard />
        </ListItemIcon>
        <ListItemText primary="Tableau de bord" />
      </ListItemButton>

      {userContext?.user?.authorities.includes("ROLE_ADMIN") && (
        <>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary="Préparation" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 9 }} component={Link} to="/preparation/calendars" onClick={onClose}>
                <ListItemText primary="Calendriers" />
              </ListItemButton>

              <ListItemButton sx={{ pl: 9 }} component={Link} to="/preparation/companies" onClick={onClose}>
                <ListItemText primary="Sociétés" />
              </ListItemButton>

              <ListItemButton sx={{ pl: 9 }} component={Link} to="/preparation/themes" onClick={onClose}>
                <ListItemText primary="Thèmes" />
              </ListItemButton>
            </List>

            <Divider />
          </Collapse>
        </>
      )}

      <ListItemButton component={Link} to="/execution/tasks" onClick={onClose}>
        <ListItemIcon>
          <FormatListBulletedIcon />
        </ListItemIcon>
        <ListItemText primary="Tâches" />
      </ListItemButton>

      <ListItemButton component={Link} to="/tracking" onClick={onClose}>
        <ListItemIcon>
          <IconDateRange />
        </ListItemIcon>
        <ListItemText primary="Suivi" />
      </ListItemButton>

      <Divider />

      {/* DÉCONNEXION */}
      <ListItemButton onClick={logout}>
        <ListItemIcon>
          <ExitToAppRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Déconnexion" />
      </ListItemButton>
    </List>
  );
}
