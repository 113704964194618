import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface MainTitleProps {
  title: string;
  children?: ReactNode;
}

export default function MainTitle({ title, children }: MainTitleProps) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1
      }}
    >
      <Typography variant="h4" component="h2">
        {title}
      </Typography>

      {children && <>{children}</>}
    </Box>
  );
}
