import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Calendrier,
  CalendrierStatus,
  useListThemes,
  useListeCalendriers,
  useTranslocateSubTaskMutation
} from "../../../api/services";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { useThemesWithUnspecified } from "./Hooks";
import TaskDropDown from "./TaskDropDown";
import { EditTaskContext } from "./Types";

interface Values {
  calendarId: number | "";
  choice: number | "";
  taskId: number | "";
  taskLabel?: string;
  themeId?: number | "";
}

type TranslocateSubTaskFormProps = {
  editContext: EditTaskContext;
  clearEditContext: Function;
};

enum Choice {
  SUBTASK = 0,
  NEW_TASK = 1
}

const TranslocateSubTaskForm = ({ editContext, clearEditContext }: TranslocateSubTaskFormProps) => {
  const { data: calendars } = useListeCalendriers();

  const [filteredCalendriers, setFilteredCalendriers] = useState<Calendrier[]>([]);

  useEffect(() => {
    if (calendars) {
      setFilteredCalendriers(calendars?.filter((calendar) => calendar.status !== CalendrierStatus.ARCHIVED));
    }
  }, [calendars, setFilteredCalendriers]);
  const { data: themes } = useListThemes();
  const availableThemes = useThemesWithUnspecified(themes);

  const translocateSubTaskMutation = useTranslocateSubTaskMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      calendarId: "",
      choice: 0,
      taskId: "",
      taskLabel: "",
      themeId: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (editContext) {
      if (editContext.taskId) {
        formMethods.setValue("taskId", editContext.taskId);
      }
      if (
        editContext.calendarId &&
        filteredCalendriers &&
        filteredCalendriers.length &&
        editContext.calendarId &&
        filteredCalendriers.map((calendar) => calendar.id).includes(editContext.calendarId)
      ) {
        formMethods.setValue("calendarId", editContext.calendarId);
      }
    }
  }, [editContext, filteredCalendriers, formMethods]);

  const choiceWatch = useWatch({ control: formMethods.control, name: "choice" });
  const calendarId = useWatch({ name: "calendarId", control: formMethods.control });

  const onSubmit = async (values: Values) => {
    await translocateSubTaskMutation.mutateAsync({
      subTaskId: editContext.subTaskId,
      taskId: values.taskLabel || values.taskId === "" ? null : values.taskId,
      calendarId: values.calendarId === "" ? null : values.calendarId,
      taskLabel: values.taskLabel,
      themeId: values.themeId === "" ? null : values.themeId
    });

    if (clearEditContext) {
      clearEditContext();
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        {filteredCalendriers && (
          <Grid item xs={12}>
            <ControllerSelect
              name="calendarId"
              formMethods={formMethods}
              required
              label="Déplacer vers le calendrier :"
              helperText="Choisissez un calendrier"
              mappedMenuItems={filteredCalendriers.map((calendar, index) => (
                <MenuItem key={calendar.id} value={calendar.id}>
                  {calendar.companyLabel} - {calendar.shortName}
                </MenuItem>
              ))}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControllerSelect
            name="choice"
            formMethods={formMethods}
            required
            label="Type de déplacement"
            helperText="Choisissez le type de déplacement"
            mappedMenuItems={[
              <MenuItem key={Choice.SUBTASK} value={Choice.SUBTASK}>
                Imbriquer sous une tache existante
              </MenuItem>,
              <MenuItem key={Choice.NEW_TASK} value={Choice.NEW_TASK}>
                Créer une nouvelle tache
              </MenuItem>
            ]}
          />
        </Grid>

        {choiceWatch === Choice.SUBTASK && calendarId !== "" && (
          <Grid item xs={12}>
            <TaskDropDown formMethods={formMethods} calendarId={calendarId} />
          </Grid>
        )}

        {choiceWatch === Choice.NEW_TASK && (
          <>
            <Grid item xs={12}>
              <Controller
                name="taskLabel"
                control={formMethods.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nom"
                    helperText="Choisissez un nom pour cette nouvelle tâche"
                    fullWidth
                  />
                )}
              />
            </Grid>

            {themes && (
              <Grid item xs={12}>
                <ControllerSelect
                  name="themeId"
                  formMethods={formMethods}
                  label="Thème"
                  helperText="Choisissez le thème de cette nouvelle tâche"
                  mappedMenuItems={availableThemes.map((theme, index) => (
                    <MenuItem key={theme.value} value={theme.value}>
                      {theme.label}
                    </MenuItem>
                  ))}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Déplacer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default TranslocateSubTaskForm;
