import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAuthenticateMutation } from "../../../api/services";
import { InlineError } from "../../../components/ErrorUtils";

interface LoginFormValues {
  username: string;
  password: string;
}

export function LoginForm() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormValues>();
  const authenticateMutation = useAuthenticateMutation();

  const onSubmit = async (data: LoginFormValues) => {
    await authenticateMutation.mutate(data);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Identifiant"
        autoComplete="username"
        {...register("username", { required: "Champs requis" })}
      />

      {errors.username && <InlineError error={errors.username.message} />}

      <TextField
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        {...register("password", { required: "Champs requis" })}
      />

      {errors.password && <InlineError error={errors.password.message} />}

      <Grid container>
        <Grid item>
          <Link to="/account/reset/start">Mot de passe oublié ?</Link>
        </Grid>
      </Grid>

      <Box style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button variant="outlined" startIcon={<ChevronRightRoundedIcon />} type="submit">
          Se connecter
        </Button>
      </Box>
    </Box>
  );
}
