import _ from "lodash";
import moment from "moment";
import { ProjectedTask, SubTask, Task } from "./taskTypes";

function asTaskWithMultipleSubTasks(task: Task): ProjectedTask {
  return {
    id: task.id,
    rank: task.rank,
    label: task.label,
    taskId: task.id,
    taskLabel: task.label,
    subtaskLabel: "",
    theoricStart: task.theoricStart,
    theoricStartDate: task.theoricStartDate,
    deadline: task.deadline,
    deadlineDate: task.deadlineDate,
    companyId: task.companyId,
    companyLabel: task.companyLabel,
    calendarId: task.calendarId,
    calendarShortName: task.calendarShortName,
    themeId: task.themeId,
    themeLabel: task.themeLabel,
    avancement: task.avancement,
    nbSubTasks: task.nbSubTasks,
    estimatedDuration: task.estimatedDuration
  };
}
function asGroupedTask(task: Task): ProjectedTask {
  const r = asTaskWithMultipleSubTasks(task);
  r.displayType = "GROUPED_TASK";
  r.assignedToLogin = _.join(_.uniq(_.map(task.subTasks, "assignedToLogin")), " ");
  return r;
}

function asUngroupedTask(task: Task): ProjectedTask {
  const r = asTaskWithMultipleSubTasks(task);
  r.displayType = "UNGROUPED_TASK";
  return r;
}

function asPlainSubTask(subtask: SubTask, task: Task) {
  return {
    id: "s" + subtask.id,
    instructions: subtask.instructions,
    rank: task.rank ? task.rank + subtask.rank / 10000 : null,
    label: subtask.label,
    subtaskId: subtask.id,
    subtaskLabel: subtask.label,
    taskId: task.id,
    taskLabel: task.label,
    status: subtask.status,
    estimatedDuration: subtask.estimatedDuration,
    executionDuration: subtask.executionDuration,
    assignedToLogin: subtask.assignedToLogin,
    theoricStart: subtask.theoricStart,
    theoricStartDate: subtask.theoricStartDate,
    deadline: subtask.deadline,
    deadlineDate: subtask.deadlineDate,
    companyId: task.companyId,
    companyLabel: task.companyLabel,
    calendarId: task.calendarId,
    calendarShortName: task.calendarShortName,

    themeId: task.themeId,
    themeLabel: task.themeLabel,

    executedOn: subtask.executedOn,
    executedByLogin: subtask.executedByLogin,
    displayType: "SUBTASK"
  };
}

export function projectTasks(
  tasks: Task[],
  groupedBy?: (number | null | undefined)[],
  focusedTaskId?: number | null | undefined
): ProjectedTask[] {
  return tasks.flatMap((t) => {
    if (focusedTaskId && focusedTaskId !== t.id) {
      return [];
    }
    if (groupedBy && groupedBy.includes(t.id)) {
      return asGroupedTask(t);
    }
    const r = [];
    r.push(asUngroupedTask(t));
    t.subTasks!.forEach((s) => {
      return r.push(asPlainSubTask(s, t));
    });
    return r;
  });
}

export const relatedToDateJFormatter = (x: number | null | undefined): string =>
  x !== undefined && x !== null ? (x === 0 ? "j" : x > 0 ? `j+${x}` : `j-${-x}`) : "";

export const durationFormatter = (x: number | null | undefined): string | null => {
  if (x === undefined || x === null) {
    return null;
  }
  if (x < 1) {
    return Math.round(x * 60) + "m";
  }
  const hours = Math.trunc(x);
  const minutes = Math.round((x - Math.trunc(x)) * 60);
  if (minutes === 0) {
    return hours + "h00";
  }
  return hours + "h" + (minutes + "m").padStart(3, "0");
};

const NUMERIC_ONLY = /^\d+\.?\d*$/;
const MINUTES_ONLY = /^(\d+)m$/;
const HOURS_ONLY = /^(\d+)h$/;
const HOURS_MINUTES = /^(\d+)h(\d*)m?/;
export const parseDuration = (x: string | null): number | null => {
  if (x == null || x === "") {
    return null;
  }
  let v = ("" + x).trim();
  if (v === "") {
    return null;
  }
  v = v.toLowerCase().replace(/ /g, "").replace(/:/, "h").replace(/,/, ".");
  if (NUMERIC_ONLY.test(v)) {
    return parseFloat(v);
  }
  if (HOURS_ONLY.test(v)) {
    const groups = HOURS_ONLY.exec(v);
    return groups === null ? null : parseFloat(groups[1]);
  }
  if (MINUTES_ONLY.test(v)) {
    const groups = MINUTES_ONLY.exec(v);
    return groups === null ? null : Math.round((parseFloat(groups[1]) / 60) * 10000) / 10000;
  }
  if (HOURS_MINUTES.test(v)) {
    const groups = HOURS_MINUTES.exec(v);
    return groups === null ? null : Math.round((parseFloat(groups[1]) + parseFloat(groups[2]) / 60) * 10000) / 10000;
  }
  return null;
};

export const dateYYYYMMDDFormatter = (x: string | Date | null | undefined): string | null => {
  if (x === undefined) return null;
  if (x === null) return null;
  if (!moment(x).isValid()) return null;
  return moment(x).format("YYYY-MM-DD");
};
