import { ListItemIcon, MenuItem, Typography } from "@mui/material";

const RowToolbarButton = ({ title, onClick, children }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{children}</ListItemIcon>
      <Typography variant="inherit">{title}</Typography>
    </MenuItem>
  );
};

export default RowToolbarButton;
