import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Logo from "../components/header/Logo";

const SimpleLayout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh"
      }}
    >
      <Logo variant="blue" />

      <Outlet />
    </Box>
  );
};

export default SimpleLayout;
