import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useAddCompanyMutation, useListCompanies } from "../../../api/services";
import DialogSide from "../../../components/DialogSide";
import MainTitle from "../../../components/MainTitle";
import CompanieFormAdd from "../components/CompanieFormAdd";
import CompaniesTable from "../components/CompaniesTable";
import { CrudEditMode, EditCompanyContext } from "../components/Types";

interface Values {
  label: string;
}

const CompaniesPage = () => {
  const theme = useTheme();

  const { data: companies } = useListCompanies();

  const [editContext, setEditContext] = useState<EditCompanyContext>({ editMode: CrudEditMode.NONE, companyId: null });

  const clearEditContext = () => {
    setEditContext({ editMode: CrudEditMode.NONE, companyId: null });
  };

  const editModeAdd = () => {
    setEditContext({ editMode: CrudEditMode.ADD, companyId: null });
  };

  const addCompanyMutation = useAddCompanyMutation();
  const handleSubmit = async (values: Values) => {
    if (clearEditContext) clearEditContext();
  };

  // const trigger = useScrollTrigger({
  //   threshold: 400, // Valeur pour le déclenchement du scroll
  //   disableHysteresis: true,
  // });

  return (
    <>
      <Container maxWidth="xl">
        <MainTitle title="Gestion des sociétés">
          {companies && (
            <Typography variant="h5" component="h3" gutterBottom style={{ color: theme.palette.secondary.main }}>
              <strong>{companies.length}</strong> sociétés
            </Typography>
          )}
        </MainTitle>

        {/* Table actions */}
        {/* <Box
          position={trigger ? "fixed" : "relative"}
          top={trigger ? 0 : "auto"}
          left={trigger ? 0 : "auto"}
          zIndex={100}
          width="100%"
          bgcolor="white"
          boxShadow={trigger ? 1 : 0}
          marginTop={trigger ? 0 : 2}
          display="flex"
          justifyContent="space-evenly"
          mb={4}
          p={2}
        > */}
        <Box
          position={"relative"}
          top={"auto"}
          left={"auto"}
          zIndex={100}
          width="100%"
          bgcolor="white"
          boxShadow={0}
          display="flex"
          justifyContent="space-evenly"
          mt={2}
          mb={2}
          p={2}
        >
          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => editModeAdd()}>
            Ajouter une société
          </Button>
        </Box>

        {companies && <CompaniesTable companies={companies} />}
      </Container>

      {/* Dialogs */}
      <>
        {/* AJOUT SOCIETE */}
        <DialogSide
          title={"Ajouter une société"}
          onClose={clearEditContext}
          open={editContext.editMode === CrudEditMode.ADD}
          submitFunction={handleSubmit}
        >
          <CompanieFormAdd clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default CompaniesPage;
