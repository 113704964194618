import { Route } from "react-router";

import Dashboard from "./pages/Dashboard";

const routes = (
  <Route>
    <Route path="/" element={<Dashboard />} />
    <Route path="/dashboard" element={<Dashboard />} />
  </Route>
);

export default routes;
