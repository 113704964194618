import { Route } from "react-router";
import AuthenticatePage from "./pages/AuthenticatePage";
import ResetPasswordFinishPage from "./pages/ResetPasswordFinishPage";
import ResetPasswordStartPage from "./pages/ResetPasswordStartPage";

const routes = (
  <Route>
    <Route path="/login" element={<AuthenticatePage />} />
    <Route path="/authenticate" element={<AuthenticatePage />} />
    <Route path="/account/reset/start" element={<ResetPasswordStartPage />} />
    <Route path="/account/reset/finish/:key" element={<ResetPasswordFinishPage />} />
  </Route>
);

export default routes;
