import { Route } from "react-router-dom";

import TasksPage from "./pages/TasksPage";

const routes = (
  <Route path="/execution/*">
    <Route path="tasks" element={<TasksPage />} />
  </Route>
);

export default routes;
