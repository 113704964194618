import { MenuItem } from "@mui/material";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";
import { useCalendrierTasks } from "../../../api/services";
import Loading from "../../../components/Loading";
import ControllerSelect from "../../../components/form/ControllerSelect";

interface TaskDropDownProps<T extends FieldValues> {
  formMethods: UseFormReturn<T, any, undefined>;
  calendarId: number;
}

const TaskDropDown = <T extends FieldValues>({ formMethods, calendarId }: TaskDropDownProps<T>) => {
  const { data: calendarTasks } = useCalendrierTasks({ id: calendarId });

  if (!calendarTasks) {
    return <Loading />;
  }
  return (
    <ControllerSelect
      name={"taskId" as FieldPath<T>}
      formMethods={formMethods}
      label="Tâche choisie"
      helperText="Choisisez une tâche parmis celles du calendrier"
      mappedMenuItems={calendarTasks.map((task, index) => (
        <MenuItem key={task.id} value={task.id}>
          {task.label}
        </MenuItem>
      ))}
    />
  );
};

export default TaskDropDown;
