import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Account, UserContext, UserContextInterface } from "../global/providers/UserProvider";
import { fetchGet, fetchPost, fetchPostTextPlain } from "./apiConfig";
import { useToken } from "./servicesBase";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

export interface AuthenticateRequest {
  username: string;
  password: string;
}

export const useAuthenticateMutation = () => {
  const { setToken } = useToken();
  return useMutation<any, any, AuthenticateRequest, any>(
    ({ username, password }) => {
      const payload = {
        username: username,
        password: password
      };
      return fetchPost("authenticate", payload);
    },
    {
      onSuccess: (data) => {
        if (data.id_token) {
          setToken(data.id_token);
          window.location.href = "/";
          toast.success("Authentification réussie");
        } else {
          setToken(null);
          toast.error("Erreur d'authentification : token non reçu");
        }
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          toast.error(
            "Erreur d'authentification : " +
              (error.response != null && error.response.data.detail != null
                ? error.response.data.detail
                : error.message)
          );
        } else {
          toast.error("Erreur d'authentification");
        }
      }
    }
  );
};

export const useWhoAmI = () => {
  const { token, setToken } = useToken();
  const userContext = useContext<UserContextInterface | null>(UserContext);
  const { data, status } = useQuery(
    ["account", token],
    (): Promise<Account> => {
      return fetchGet("account", token);
    },
    {
      enabled: token !== undefined,
      onSuccess: (data) => {
        if (data.login) {
          userContext!.setUser(data);
        } else {
          setToken(null);
        }
      },
      onError: () => {
        setToken(null);
      }
    }
  );
  return { data, status };
};

export interface ResetPasswordRequest {
  mail: string;
}

export const useResetPasswordMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<void, any, ResetPasswordRequest, any>(
    ({ mail }) => {
      const payload = mail;
      return fetchPostTextPlain("account/reset-password/init", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("account/reset-password/init");
      },
      onSuccess: () => {
        toast.success("Demande de réinitialisation envoyée");
      },
      onError: () => {
        toast.error("Erreur lors de la demande de réinitialisation");
      }
    }
  );
};

export interface ResetPasswordFinishRequest {
  key: string;
  newpassword: string;
}

export const useResetPasswordFinishMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<void, any, ResetPasswordFinishRequest, any>(
    ({ newpassword, key }) => {
      const payload = {
        newPassword: newpassword,
        key: key
      };
      return fetchPost("account/reset-password/finish", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("account/reset-password/finish");
      },
      onSuccess: () => {
        toast.success("Mot de passe réinitialisé");
      },
      onError: () => {
        toast.error("Erreur lors de la réinitialisation du mot de passe");
      }
    }
  );
};
