import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useTheme } from "@mui/material";

const DialogTask = (props) => {
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      props.onClose();
    }

    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };

  const handleConfirm = () => {
    if (typeof props.submitFunction === "function") {
      props.submitFunction();
    }
  };

  const theme = useTheme();

  // TODO: style temporaire
  const dialogStyle = {
    display: "flex",
    justifyContent: "flex-end"
  };

  const dialogTitleStyle = {
    backgroundColor: theme.palette.secondary.main
  };

  const titleStyle = {
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  };

  const dialogContentStyle = {
    width: 600
  };

  const dialogActionsStyle = {
    backgroundColor: theme.palette.grey[100],
    // display: "flex",
    flexDirection: "column",
    gap: 20,
    justifyContent: "space-between",
    padding: 20,
    display: "none"
  };

  return (
    <Dialog fullScreen open={props.open} onClose={handleClose} style={dialogStyle}>
      <DialogTitle id="scroll-dialog-title" style={dialogTitleStyle}>
        <div style={titleStyle}>
          <div>Sous-Tâche</div>
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            style={{ color: theme.palette.grey[100] }}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent style={dialogContentStyle}>{props.children}</DialogContent>

      <DialogActions style={dialogActionsStyle}>
        {/* TODO: Ajouter les actions dans la modales */}
        <Button variant="outlined" startIcon={<CheckBoxIcon />} fullWidth onClick={() => {}}>
          Compléter
        </Button>
        <div>
          <Button variant="outlined" startIcon={<CheckBoxOutlineBlankIcon />} onClick={() => {}}>
            Remettre en cours
          </Button>

          <Button variant="outlined" startIcon={<HighlightOffIcon />} onClick={() => {}}>
            Rendre non applicable
          </Button>
          {props.dialogAction && (
            <Button variant="outlined" autoFocus onClick={handleConfirm}>
              {props.dialogAction}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTask;
