import ApartmentIcon from "@mui/icons-material/Apartment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { HasId, useTasksFor } from "../../../api/services";
import { SubTaskStatus, SubTaskV } from "../../../api/taskTypes";
import DialogSide from "../../../components/DialogSide";
import DialogTask from "../../../components/DialogTask";
import MainTitle from "../../../components/MainTitle";
import { UserContext } from "../../../global/providers/UserProvider";
import AddReliquatForm from "../../execution/components/ReliquatFormAdd";
import SubTaskView from "../../execution/components/SubTaskView";
import MyTasksTable from "../components/MyTasksTable";
import { EditContext, EditMode, MyTasksPageFilters } from "../components/Types";

const Dashboard = () => {
  // TODO: style temporaire
  const theme = useTheme();
  const itemStyle = {
    backgroundColor: "#eee",
    marginTop: "12px",
    padding: "6px"
  };

  const currentUser = useContext(UserContext);

  const clearEditContext = () => {
    setEditContext({ editMode: EditMode.NONE });
  };

  // FILTRES
  const [viewFilter, setViewFilter] = useState<MyTasksPageFilters>({
    login: null,
    statuses: [],
    deadlines: "Toutes",
    label: "",
    themeLabel: [],
    calendars: [],
    companies: []
  });

  useEffect(() => {
    if (viewFilter.login === null && currentUser !== null && currentUser.user !== null) {
      setViewFilter({
        login: currentUser!.user!.login,
        statuses: [SubTaskStatus.PENDING],
        deadlines: "Toutes",
        label: "",
        themeLabel: [],
        calendars: [],
        companies: []
      });
    }
  }, [currentUser, setViewFilter, viewFilter]);

  const { tasks } = useTasksFor(viewFilter.login);

  // TODO: ajout de la complettion
  const onClickComplete = () => {
    console.log("🚀 ~ file: Dashboard.tsx:47 ~ onClickComplete ~ onClickComplete:", onClickComplete);
  };

  // GESTION DES TABS
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  // MODE TABLE
  const [filteredData, setFilteredData] = useState<SubTaskV[]>([]);

  const [editContext, setEditContext] = useState<EditContext>({
    editMode: EditMode.NONE
  });

  const editModeViewSubTask = ({ id }: HasId) => {
    if (!id) {
      return;
    }
    setEditContext({ editMode: EditMode.VIEW_SUBTASK, subTaskId: id });
  };

  const editModeCreateReliquat = ({ id }: HasId) => {
    if (!id) {
      return;
    }
    setEditContext({ editMode: EditMode.CREATE_RELIQUAT, subTaskId: id });
  };

  return (
    <>
      <Container maxWidth="xl">
        <MainTitle title="Tableau de bord">
          {tasks && (
            <Typography variant="h5" component="h3" gutterBottom style={{ color: theme.palette.secondary.main }}>
              {tasks.length === 0 ? "" : tasks.length === 1 ? "Ma tâche" : `Mes ${tasks.length} tâches`}
            </Typography>
          )}
        </MainTitle>

        {Array.isArray(tasks) && tasks.length >= 1 && (
          <Grid container justifyContent="center">
            <Grid item>
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Mode table" />
                <Tab label="Mode liste" />
              </Tabs>
            </Grid>
          </Grid>
        )}

        <Alert>Page en cours de développement</Alert>

        {/* MODE LISTE */}
        {tasks && selectedTab === 1 && (
          <>
            {/* <TasksList /> */}

            {tasks.map((task) => (
              <Card variant="outlined" style={itemStyle} key={task.id}>
                <CardContent
                  style={{
                    display: "grid",
                    alignItems: "center",
                    gridTemplateColumns: "5% 70% 20%",
                    padding: "5px 0"
                  }}
                >
                  <div className="flex-center">
                    <IconButton title="Compléter la tâche" onClick={onClickComplete}>
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                  </div>

                  {/* {task && (
                  <details>
                    <summary>task</summary>
                    <pre>
                      <code>{JSON.stringify(task, null, 2)}</code>
                    </pre>
                  </details>
                )} */}

                  <div style={{ marginLeft: "10px" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                      {task.taskLabel}
                    </Typography>

                    <Typography variant="h6">
                      {task.label}
                      {/* {task.label.length > 80 ? task.label.substring(0, 80) + "…" : task.label} */}
                    </Typography>

                    <Typography variant="body2">{task.instructions}</Typography>
                  </div>

                  <div style={{ marginLeft: "10px" }}>
                    <Typography sx={{ fontSize: 14, display: "flex", alignItems: "center" }} color="text.secondary">
                      <ApartmentIcon fontSize="small" sx={{ mr: 1 }} /> {task.companyLabel}
                    </Typography>

                    <Typography variant="body1">{task.calendarShortName}</Typography>
                  </div>
                </CardContent>
              </Card>
            ))}
          </>
        )}
      </Container>

      {/* MODE TABLE */}
      {tasks && selectedTab === 0 && (
        <>
          {Array.isArray(tasks) && tasks.length === 0 ? (
            <Grid container alignItems="center" justifyContent="center" style={{ height: "60vh" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <AssignmentTurnedInIcon style={{ fontSize: 48, color: "lightgrey" }} />
                <Typography variant="h6" style={{ color: "grey" }}>
                  Vos tâches assignées s&rsquo;affichent ici.
                </Typography>
              </Box>
            </Grid>
          ) : (
            <MyTasksTable
              tasks={tasks}
              editModeViewSubTask={editModeViewSubTask}
              editModeCreateReliquat={editModeCreateReliquat}
            />
          )}
        </>
      )}

      {/* Dialogs */}
      <>
        <DialogTask
          title={"Sous-tâche"}
          onClose={clearEditContext}
          open={editContext.editMode === EditMode.VIEW_SUBTASK}
        >
          <SubTaskView editContext={editContext} clearEditContext={clearEditContext} />
        </DialogTask>

        <DialogSide
          title={"Créer un reliquat et compléter la Sous-tâche"}
          onClose={clearEditContext}
          open={editContext.editMode === EditMode.CREATE_RELIQUAT}
        >
          <AddReliquatForm editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default Dashboard;
