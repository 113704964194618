import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { fetchPost } from "./apiConfig";
import { HasId, useToken } from "./servicesBase";
import { SubTaskV, Task } from "./taskTypes";

export interface PartialEditSubTaskRequest {
  id: number;
  executionDuration?: number | null;
  executionDetails: any | null;
}

// TODO: code dupliqué à déplacer dans une lib globale
function b64EncodeUnicode(str: string): string {
  const uriEncoding: string = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match: string, p1: string): string {
      return String.fromCharCode(parseInt(p1, 16));
    }
  );
  return btoa(uriEncoding);
}

export const usePartialEditSubTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Task, any, PartialEditSubTaskRequest, any>(
    ({ id, executionDuration, executionDetails }) => {
      const payload = {
        id,
        executionDuration,
        executionDetails: executionDetails ? b64EncodeUnicode(executionDetails) : null
      };
      return fetchPost("cloture/edit-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["sub-tasks", variables.id]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      },
      onSuccess: () => {
        toast.success("Sous-tâche modifiée");
      },
      onError: () => {
        toast.error("Erreur lors de la modification de la sous-tâche");
      }
    }
  );
};

export const useCompleteSubTask = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  const {
    mutate: callCompleteSubTask,
    status,
    data,
    error
  } = useMutation<SubTaskV, any, HasId, any>(
    ({ id }) => {
      const payload = { id };
      return fetchPost("cloture/complete-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["cloture/calendar/tasks", data?.calendarId]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
        queryCache.invalidateQueries("cloture/subtasks/in_progress");
      },
      onSuccess() {
        toast.success("Sous-tâche complétée");
      },
      onError() {
        toast.error("Erreur lors de la complétion de la sous-tâche");
      }
    }
  );

  return { callCompleteSubTask, status, data, error };
};

export const useUndoSubTask = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  const {
    mutate: callUndoSubTask,
    status,
    data,
    error
  } = useMutation<SubTaskV, any, HasId, any>(
    ({ id }) => {
      const payload = { id };
      return fetchPost("cloture/undo-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["cloture/calendar/tasks", data?.calendarId]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
        queryCache.invalidateQueries("cloture/subtasks/in_progress");
      },
      onSuccess: () => {
        toast.success("Complétion de la sous-tâche annulée");
      },
      onError: () => {
        toast.error("Erreur lors de l'annulation de la complétion de la sous-tâche");
      }
    }
  );

  return { callUndoSubTask, status, data, error };
};

export const useSkipSubTask = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  const {
    mutate: callSkipSubTask,
    status,
    data,
    error
  } = useMutation<SubTaskV, any, HasId, any>(
    ({ id }) => {
      const payload = { id };
      return fetchPost("cloture/skip-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["cloture/calendar/tasks", data?.calendarId]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
        queryCache.invalidateQueries("cloture/subtasks/in_progress");
      },
      onSuccess: () => {
        toast.success("Sous-tâche ignorée");
      },
      onError: () => {
        toast.error("Erreur lors de la tentative d'ignorer la sous-tâche");
      }
    }
  );

  return { callSkipSubTask, status, data, error };
};

export const usePostponedSubTask = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  const {
    mutate: callPostponedSubTask,
    status,
    data,
    error
  } = useMutation<SubTaskV, any, HasId, any>(
    ({ id }) => {
      const payload = { id };
      return fetchPost("cloture/postponed-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["cloture/calendar/tasks", data?.calendarId]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
        queryCache.invalidateQueries("cloture/subtasks/in_progress");
      },
      onSuccess: () => {
        toast.success("Sous-tâche reportée");
      },
      onError: () => {
        toast.error("Erreur lors du report de la sous-tâche");
      }
    }
  );

  return { callPostponedSubTask, status, data, error };
};
