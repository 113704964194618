import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { frFR } from "@mui/x-date-pickers/locales";
import { LicenseInfo } from "@mui/x-license-pro";
import fr from "date-fns/locale/fr";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import myMUITheme from "./styles/theme.json";

const theme = createTheme(myMUITheme);
LicenseInfo.setLicenseKey(
  "31b91b5126a8d18d3feac441ea32635bTz03NjkzNixFPTE3MjkzMjg5OTMwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale={fr}
    >
      <App />
    </LocalizationProvider>
  </ThemeProvider>
);
