import { Box, Container, Typography, useTheme } from "@mui/material";
import { useCalendrierDetail, useCalendrierMermaidView } from "../../../api/services";
import Loading from "../../../components/Loading";
import Mermaid from "../../../components/Mermaid";
import { useNumericParam } from "../components/Hooks";

const CalendrierPagePrepare = () => {
  const calendarId = useNumericParam("calendarId");
  const { data: calendarDetail } = useCalendrierDetail({ id: calendarId });
  const { data: mermaidChart } = useCalendrierMermaidView({ id: calendarId });
  const theme = useTheme();

  if (!mermaidChart || !calendarDetail) {
    return <Loading />;
  }
  return (
    <>
      <Container maxWidth="xl">
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Planning :<span style={{ color: theme.palette.secondary.main }}> {calendarDetail.shortName}</span>
          </Typography>
        </Box>
      </Container>
      <Mermaid chart={mermaidChart} id={calendarId} />;
    </>
  );
};

export default CalendrierPagePrepare;
