import { Box, Chip, useTheme } from "@mui/material";

function Footer() {
  const isDevEnvironment = process.env.NODE_ENV === "development";
  const appVersion = "1.0.1";

  const theme = useTheme();

  // TODO: style temporaire
  const footerStyle = {
    backgroundColor: "#eee",
    marginTop: "20px",
    padding: "12px"
  };

  return (
    <>
      <Box
        component="footer"
        style={footerStyle}
        sx={{
          py: 1,
          px: 2,
          mt: 2,
          textAlign: "center"
        }}
      >
        version{" "}
        <Chip
          label={appVersion}
          size="small"
          component="a"
          href="https://gitlab.com/groups/myje/poc-cloture"
          target="_blank"
          clickable
        />
      </Box>
    </>
  );
}

export default Footer;
