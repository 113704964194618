import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/FilterAlt";
import VisibilityOffIcon from "@mui/icons-material/FilterAltOff";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useListThemes } from "../../../api/services";
import { useListeCalendriersInProgress } from "../../../api/servicesCalendriers";
import { useListCompanies } from "../../../api/servicesCompanies";
import { useListUsers } from "../../../api/servicesUsers";
import { SubTaskStatus, SubTaskStatusLabels } from "../../../api/taskTypes";
import { UserContext } from "../../../global/providers/UserProvider";
import { MyTasksPageFilters } from "./Types";

type TasksFiltersProps = {
  viewFilter: MyTasksPageFilters;
  setViewFilter: React.Dispatch<React.SetStateAction<MyTasksPageFilters>>;
};

const statuses = [
  SubTaskStatus.PENDING,
  SubTaskStatus.DONE,
  SubTaskStatus.SKIPPED,
  SubTaskStatus.POSTPONED,
  SubTaskStatus.REMAINDER
];

const deadlines = ["Toutes", "Echues", "Echues & En cours", "En cours", "Futures"];

const TasksFilters = ({ viewFilter, setViewFilter }: TasksFiltersProps) => {
  const { data: users } = useListUsers();
  const currentUser = useContext(UserContext);
  const { data: calendarList } = useListeCalendriersInProgress();
  const [calendars, setCalendriers] = useState<string[]>([]);
  const { data: companies } = useListCompanies();
  const { data: themes } = useListThemes();

  useEffect(() => {
    if (calendarList) {
      setCalendriers(_.uniq(calendarList.map((calendar) => calendar.shortName)));
      // console.log(calendars);
    }
  }, [calendarList, setCalendriers]);

  const filterByAssignedTo = (event: any) => {
    const users = event.target.value as Array<number | undefined>;
    setViewFilter({ ...viewFilter, assignedToUsers: users });
  };

  const filterByCompany = (event: any) => {
    setViewFilter({ ...viewFilter, companies: event.target.value });
  };

  const filterByCalendrier = (event: any, newValue: any) => {
    setViewFilter({ ...viewFilter, calendars: newValue });
  };

  const filterByStatus = (event: any) => {
    setViewFilter({ ...viewFilter, statuses: event.target.value });
  };

  const filterByDeadline = (event: any) => {
    setViewFilter({ ...viewFilter, deadlines: event.target.value });
  };

  const filterByText = (event: any) => {
    setViewFilter({ ...viewFilter, label: event.target.value });
  };

  const filterByThemeLabel = (event: any) => {
    setViewFilter({ ...viewFilter, themeLabel: event.target.value });
  };

  const resetFilter = () => {
    setViewFilter({
      ...viewFilter,
      calendars: [],
      companies: [],
      deadlines: "Toutes",
      label: "",
      assignedToUsers: [currentUser!.user!.id],
      statuses: [],
      themeLabel: []
    });
  };

  // Plus de Filtres
  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const toggleList = () => {
    const newValue = !isFiltersOpen;
    setFiltersOpen(newValue);
    localStorage.setItem("isFiltersOpen", JSON.stringify(newValue));
  };

  useEffect(() => {
    // Vérifier si la valeur est déjà présente dans le localStorage
    const storedValue = localStorage.getItem("isFiltersOpen");
    if (storedValue) {
      setFiltersOpen(JSON.parse(storedValue));
    }
  }, []);

  return (
    <>
      {/* {viewFilter && (
        <pre>
          <code>{JSON.stringify(viewFilter, null, 2)}</code>
        </pre>
      )} */}

      <Grid container spacing={1} justifyContent="center">
        {isFiltersOpen && (
          <>
            {/* Rechercher par label */}
            <Grid item xs={12} mb={2}>
              <FormControl fullWidth>
                <InputLabel>Rechercher une sous-tâche</InputLabel>
                <Input value={viewFilter.label} onChange={filterByText} />
                <FormHelperText>
                  Filtrer avec le nom de votre tâche ou sous-tâche. Vous pouvez saisir une partie du terme recherché
                  pour obtenir des résultats pertinents.
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Calendrier */}
            <Grid item xs={12} sm={6}>
              {calendars && (
                <Autocomplete
                  multiple
                  options={calendars}
                  value={viewFilter.calendars}
                  onChange={filterByCalendrier}
                  noOptionsText="Aucun calendrier trouvé"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Calendrier"
                      variant="outlined"
                      placeholder="Sélectionnez un ou plusieurs calendrier"
                      helperText="Filtrer par calendrier. Vous pouvez aussi saisir une partie du calendrier recherché."
                    />
                  )}
                />
              )}
            </Grid>

            {/* Theme */}
            <Grid item xs={12} sm={3}>
              {themes && (
                <FormControl fullWidth>
                  <InputLabel>Thème</InputLabel>
                  <Select
                    displayEmpty
                    label="Thème"
                    multiple
                    value={viewFilter.themeLabel}
                    onChange={filterByThemeLabel}
                  >
                    {themes.map((theme, index) => (
                      <MenuItem key={theme.id} value={theme.id}>
                        {theme.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par thème</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* Société */}
            <Grid item xs={12} sm={3}>
              {companies && (
                <FormControl fullWidth>
                  <InputLabel>Société</InputLabel>
                  <Select displayEmpty label="Société" multiple value={viewFilter.companies} onChange={filterByCompany}>
                    {companies.map((company, index) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par société</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* users */}
            <Grid item xs={12} sm={4}>
              {users && (
                <FormControl fullWidth>
                  <InputLabel>Assigné à</InputLabel>
                  <Select
                    label="assignedToUsers"
                    multiple
                    value={viewFilter.assignedToUsers}
                    onChange={filterByAssignedTo}
                  >
                    <MenuItem key={"assignedoIdNull"}>Non assignée</MenuItem>
                    {users.map((user) => (
                      <MenuItem key={"assignedToId" + user.id} value={user.id}>
                        {user.login}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Filtrer par utilisateur. <br />
                    [1] indique que plusieurs utilisateurs sont sélectionnés.
                  </FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* statuses */}
            <Grid item xs={12} sm={4}>
              {statuses && (
                <FormControl fullWidth>
                  <InputLabel>Statut</InputLabel>
                  <Select label="Statut" multiple value={viewFilter.statuses} onChange={filterByStatus}>
                    {statuses.map((status, index) => (
                      <MenuItem key={status} value={status}>
                        {SubTaskStatusLabels[status]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par statut</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* deadlines */}
            <Grid item xs={12} sm={4}>
              {deadlines && (
                <FormControl fullWidth>
                  <InputLabel>Echéance</InputLabel>
                  <Select label="Echéance" value={viewFilter.deadlines} onChange={filterByDeadline}>
                    {deadlines.map((deadline, index) => (
                      <MenuItem key={deadline} value={deadline}>
                        {deadline}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par date butoire</FormHelperText>
                </FormControl>
              )}
            </Grid>
          </>
        )}

        {/* Reset */}
        <Grid container mt={2} spacing={2} justifyContent="center">
          <Grid item>
            <Button
              onClick={toggleList}
              color="inherit"
              variant="outlined"
              size="small"
              startIcon={isFiltersOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
            >
              {isFiltersOpen ? "Masquer les filtres" : "Afficher les filtres"}
            </Button>
          </Grid>

          <Grid item>
            {isFiltersOpen && (
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={resetFilter}
                startIcon={<ClearRoundedIcon />}
              >
                Réinitialiser les filtres
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TasksFilters;
