import axios, { AxiosRequestConfig } from "axios";
import { QueryClient } from "react-query";
const BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8082/api/"
    : process.env.NODE_ENV === "test"
      ? "http://localhost/api/"
      : "/api/";

const axiosInstance = axios.create({
  maxRedirects: 0
});

const queryClientConfig = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: true,
        staleTime: 300000
      }
    }
  });
};

/*
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log(BACKEND_URL);
    // console.log(config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
*/

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // 403 : retour à l'accueil pour login
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      window.location.pathname !== "/authenticate" &&
      !/^\/account\/reset\//.test(window.location.pathname)
    ) {
      window.location.href = "/authenticate";
    }
    return Promise.reject(error);
  }
);

const fetchGet = async function (url: string, token?: string | null) {
  const p: AxiosRequestConfig = {
    method: "GET",
    headers: { secFetchMode: "cors" },
    withCredentials: true,
    withXSRFToken: true,
    url: BACKEND_URL + url
  };
  if (token) {
    p.headers = {
      Authorization: "Bearer " + token
    };
  }
  const res = await axiosInstance(p);

  return await res.data;
};

const fetchDelete = async function (url: string, token?: string | null) {
  const p: AxiosRequestConfig = {
    method: "DELETE",
    headers: { secFetchMode: "cors" },
    withCredentials: true,
    withXSRFToken: true,
    url: BACKEND_URL + url
  };
  if (token) {
    p.headers = { ...p.headers, Authorization: "Bearer " + token };
  }
  const res = await axiosInstance(p);

  return await res.data;
};

const fetchPost = async function (url: string, payload: any, token?: string | null) {
  const p: AxiosRequestConfig = {
    method: "POST",
    headers: {
      secFetchMode: "cors",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    withCredentials: true,
    withXSRFToken: true,
    data: JSON.stringify(payload),
    url: BACKEND_URL + url
  };
  if (token) {
    p.headers = { ...p.headers, Authorization: "Bearer " + token };
  }
  const res = await axiosInstance(p);

  return await res.data;
};

const fetchPostTextPlain = async function (url: string, payload: any, token?: string | null) {
  const p: AxiosRequestConfig = {
    method: "POST",
    headers: {
      secFetchMode: "cors",
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: "Bearer " + token
    },
    withCredentials: true,
    withXSRFToken: true,
    data: payload,
    url: BACKEND_URL + url
  };

  const res = await axiosInstance(p);

  return await res.data;
};

const fetchPostBinary = async function (url: string, payload: any) {
  const p: AxiosRequestConfig = {
    method: "POST",
    headers: {
      secFetchMode: "cors",
      "Content-Type": "application/json"
    },
    withCredentials: true,
    withXSRFToken: true,
    data: JSON.stringify(payload),
    url: BACKEND_URL + url
  };

  const res = await axiosInstance(p);

  return await res.data;
};

const fetchPostFormEncoded = async function (url: string, payload: any) {
  let formBody: any = [];
  for (const property in payload) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const p: AxiosRequestConfig = {
    method: "POST",
    headers: {
      secFetchMode: "cors",
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded"
    },
    withCredentials: true,
    withXSRFToken: true,
    data: formBody,
    url: BACKEND_URL + url
  };

  const res = await axiosInstance(p);

  return res.data;
};

const fetchPut = async function (url: string, payload: any, token?: string | null) {
  const p: AxiosRequestConfig = {
    method: "PUT",
    headers: {
      secFetchMode: "cors",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    withCredentials: true,
    withXSRFToken: true,
    data: JSON.stringify(payload),
    url: BACKEND_URL + url
  };

  const res = await axiosInstance(p);

  return res.data;
};

const fetchStub = async function (stub: any) {
  return await stub;
};

export {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPostBinary,
  fetchPostFormEncoded,
  fetchPostTextPlain,
  fetchPut,
  fetchStub,
  queryClientConfig
};
