import { Button, FormControlLabel, FormHelperText, InputAdornment, MenuItem, Switch, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useListThemes, useListUsers, useSubTaskDetail, useUpdateSubTaskMutation } from "../../../api/services";
import { durationFormatter, parseDuration } from "../../../api/tasksUtils";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { useThemesWithUnspecified, useUsersWithUnspecified } from "./Hooks";
import { EditTaskContext, EditTaskMode } from "./Types";

interface Values {
  rank: string;
  themeId: number | "";
  taskLabel: string;
  label: string;
  instructions: string;
  assignedToId: number | "";
  // These are strings because TextField always return a string, even when type="number" is specified
  theoricStart: string;
  deadline: string;
  estimatedDuration: string;
  eligibleRemainder: boolean;
}

interface EditSubTaskFormProps {
  editContext: EditTaskContext;
  clearEditContext: Function;
}

const EditSubTaskForm = ({ editContext, clearEditContext }: EditSubTaskFormProps) => {
  const { data: subtask } = useSubTaskDetail({ id: editContext.subTaskId });
  const { data: users } = useListUsers();
  const { data: themes } = useListThemes();

  const availableUsers = useUsersWithUnspecified(users);
  const availableThemes = useThemesWithUnspecified(themes);
  const updateSubTaskMutation = useUpdateSubTaskMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      rank: "",
      themeId: "",
      taskLabel: "",
      label: "",
      instructions: "",
      assignedToId: "",
      theoricStart: "",
      deadline: "",
      estimatedDuration: "",
      eligibleRemainder: false
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (subtask && users && themes) {
      formMethods.reset({
        ...formMethods.getValues(),
        label: subtask?.label,
        assignedToId: subtask?.assignedToId,
        theoricStart: subtask?.theoricStart?.toString(),
        deadline: subtask?.deadline?.toString(),
        estimatedDuration: durationFormatter(subtask?.estimatedDuration) ?? "",
        instructions: subtask?.instructions ?? "",
        eligibleRemainder: subtask?.eligibleRemainder
      });
    }
  }, [subtask, formMethods, users, themes]);

  const onSubmit = async (values: Values) => {
    if (subtask === undefined || subtask === null) {
      return;
    }

    const theoricStart = values.theoricStart === "" ? null : parseInt(values.theoricStart);
    const deadline = values.deadline === "" ? null : parseInt(values.deadline);

    if (theoricStart !== null && deadline !== null && theoricStart !== undefined && deadline !== undefined) {
      if (deadline < theoricStart) {
        toast.error("Erreur : La date de début doit être avant la Date butoir");
        return;
      }
    }

    await updateSubTaskMutation.mutateAsync({
      id: subtask!.id,
      status: subtask!.status,
      executedOn: subtask.executedOn,
      taskId: subtask.taskId,
      executedById: subtask.executedById,
      label: values.label,
      rank: subtask.rank,
      assignedToId: values.assignedToId === "" ? null : values.assignedToId,
      theoricStart: theoricStart,
      deadline: deadline,
      estimatedDuration: parseDuration(values.estimatedDuration),
      executionDuration: subtask.executionDuration,
      instructions: values.instructions,
      executionDetails: subtask.executionDetails,
      eligibleRemainder: values.eligibleRemainder
    });

    if (clearEditContext) {
      clearEditContext();
    }
  };
  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      {/* {subtask && (
        <pre>
          <code>{JSON.stringify(subtask, null, 2)}</code>
        </pre>
      )} */}

      <Grid container spacing={4} mt={1}>
        {(editContext.editMode === EditTaskMode.ADD_TASK || editContext.editMode === EditTaskMode.EDIT_TASK) && (
          <Grid item xs={12}>
            <ControllerSelect
              name="themeId"
              label="Thème"
              formMethods={formMethods}
              mappedMenuItems={availableThemes.map((theme, index) => (
                <MenuItem key={theme.value} value={theme.value}>
                  {theme.label}
                </MenuItem>
              ))}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Controller
            name="label"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Sous-tâche"
                error={!!formMethods.formState.errors.label}
                helperText={formMethods.formState.errors?.label?.message ?? "Nom de la sous-tâche"}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="instructions"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Instructions"
                fullWidth
                multiline
                minRows={4}
                helperText={"Saisissez ici toutes les instructions pour réaliser cette sous-tâche"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          {users && (
            <ControllerSelect
              name="assignedToId"
              label="Assigné à"
              formMethods={formMethods}
              mappedMenuItems={availableUsers.map((user, index) => (
                <MenuItem key={user.value} value={user.value}>
                  {user.label}
                </MenuItem>
              ))}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="theoricStart"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date début"
                fullWidth
                type="number"
                helperText="En jours par rapport à J"
                InputProps={{
                  endAdornment: <InputAdornment position="end">jours</InputAdornment>
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="deadline"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date butoir"
                fullWidth
                type="number"
                helperText="En jours par rapport à J"
                InputProps={{
                  endAdornment: <InputAdornment position="end">jours</InputAdornment>
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="estimatedDuration"
            control={formMethods.control}
            rules={{
              validate: {
                value: (value) => {
                  if (value) {
                    const duration = durationFormatter(parseDuration(value));
                    if (!duration) {
                      return "Durée invalide";
                    }
                  }
                  return true;
                }
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Durée estimée"
                type="text"
                error={!!formMethods.formState.errors.estimatedDuration}
                helperText={
                  formMethods.formState.errors?.estimatedDuration?.message ??
                  "En heures ou minutes : 01h30m, 01h ou 30m"
                }
                sx={{
                  "& .MuiFormHelperText-root": {
                    whiteSpace: "nowrap"
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="eligibleRemainder"
            control={formMethods.control}
            render={({ field }) => (
              <>
                <FormControlLabel control={<Switch {...field} checked={field.value} />} label="Eligible au reliquat" />
                {field.value && (
                  <FormHelperText>
                    Vous pourrez reporter cette tâche sur un autre calendrier, et utiliser le temps ou les ressources
                    restantes pour la réaliser.
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Mettre à jour
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default EditSubTaskForm;
