import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Chip, Typography } from "@mui/material";
import { GridHeaderFilterCellProps } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Calendrier,
  CalendrierStatus,
  CalendrierStatusLabels,
  useDeleteCalendrierMutation,
  useProgressCalendrierMutation
} from "../../../api/services";
import ConfirmPopIn from "../../../components/DialogConfirm";
import DialogSide from "../../../components/DialogSide";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import RowToolbar from "../../../components/datagrid/RowToolbar";
import RowToolbarButton from "../../../components/datagrid/RowToolbarButton";
import CalendarFormEdit from "./CalendarFormEdit";
import { EditCalendrierContext, EditCalendrierMode } from "./Types";

type CalendriersTableProps = {
  calendars: Calendrier[];
};

const CalendarsTable = ({ calendars }: CalendriersTableProps) => {
  const navigate = useNavigate();

  const [openPopInPublishCalendrier, setOpenPopInPublishCalendrier] = useState(false);
  const [openPopInArchiveCalendrier, setOpenPopInArchiveCalendrier] = useState(false);
  const [openPopInDeleteCalendrier, setOpenPopInDeleteCalendrier] = useState(false);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [editContext, setEditContext] = useState<EditCalendrierContext>({
    editMode: EditCalendrierMode.NONE,
    calendarId: null
  });
  const [editCalendrierId, setEditCalendrierId] = useState<number | null>(null);
  const progressCalendrierMutation = useProgressCalendrierMutation();
  const deleteCalendrierMutation = useDeleteCalendrierMutation();

  const clearEditContext = () => {
    setEditContext({ editMode: EditCalendrierMode.NONE, calendarId: null });
  };

  const editModeEditCalendrier = (calendarId: number) => {
    setEditContext({ editMode: EditCalendrierMode.EDIT, calendarId });
  };

  function popInPublishCalendrierOnClose() {
    setOpenPopInPublishCalendrier(false);
  }

  function popInArchiveCalendrierOnClose() {
    setOpenPopInArchiveCalendrier(false);
  }

  function popInDeleteCalendrierOnClose() {
    setOpenPopInDeleteCalendrier(false);
  }

  function updateCurrentlySelectedRows(selections: { rowIds: any[] }) {
    if (!_.isEqual(selectedRows.sort(), selections.rowIds.sort())) {
      setSelectedRows(selections.rowIds);
    }
  }

  const doPublishCalendrier = async () => {
    if (editCalendrierId !== null) {
      await progressCalendrierMutation.mutateAsync({ id: editCalendrierId, status: CalendrierStatus.IN_PROGRESS });
      setEditCalendrierId(null);
    }
    popInPublishCalendrierOnClose();
  };

  const doArchiveCalendrier = async () => {
    if (editCalendrierId !== null) {
      await progressCalendrierMutation.mutateAsync({ id: editCalendrierId, status: CalendrierStatus.ARCHIVED });
      setEditCalendrierId(null);
    }
    popInArchiveCalendrierOnClose();
  };

  const doDeleteCalendrier = async () => {
    if (editCalendrierId !== null) {
      await deleteCalendrierMutation.mutateAsync({ id: editCalendrierId });
      setEditCalendrierId(null);
    }
    popInDeleteCalendrierOnClose();
  };

  const columns = [
    {
      field: "status",
      headerName: "Statut",
      width: 120,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: { row: Calendrier }) => {
        return (
          <>
            <Chip
              label={CalendrierStatusLabels[params.row.status]}
              color={
                CalendrierStatusLabels[params.row.status] === "En cours"
                  ? "success"
                  : CalendrierStatusLabels[params.row.status] === "Provisoire"
                    ? "warning"
                    : "default"
              }
            />
          </>
        );
      }
    },
    {
      field: "companyLabel",
      headerName: "Société",
      width: 200,
      minWidth: 200
    },
    {
      field: "shortName",
      headerName: "Calendrier",
      width: 250,
      minWidth: 250,
      disableClickEventBubbling: true,
      renderCell: (params: { row: Calendrier }) => {
        const handleClick = () => {
          navigate(`/preparation/calendars/${params.row.id}`);
        };

        return (
          <Typography variant="body2" color="primary" fontWeight="bold" onClick={handleClick} className="truncate">
            <span className="clickable">{params.row.shortName}</span>
          </Typography>
        );
      }
    },
    {
      field: "longName",
      headerName: "Description",
      width: 200,
      minWidth: 200,
      flex: 1,
      sortable: false
    },
    {
      field: "dateJ",
      headerName: "Date J",
      align: "center",
      headerAlign: "center",
      width: 220,
      minWidth: 220,
      type: "date",
      valueGetter: (params: { row: Calendrier }) => {
        const dateJ = params.row.dateJ;
        const selectedDateJ = dateJ ? new Date(dateJ) : null;
        return selectedDateJ;
      }
      // renderCell: (params: { row: Calendrier }) => {
      //   const dateJ = params.row.dateJ;
      //   const selectedDateJ = dateJ ? new Date(dateJ) : null;
      //   const formattedDateJ = selectedDateJ ? format(selectedDateJ, "dd/MM/yy", { locale: frLocale }) : "";

      //   return <>{formattedDateJ ? <>{formattedDateJ}</> : <span> - </span>}</>;
      // },
    },
    {
      field: "actions",
      headerName: "Actions",
      disableReorder: true,
      width: 90,
      resizable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: { row: Calendrier }) => {
        const onClickEdit = () => {
          editModeEditCalendrier(params.row.id);
        };

        const onClickOpenTask = (id: number) => {
          // history.push(`/preparation/calendars/${id}`);
          navigate(`/preparation/calendars/${id}`);
        };

        const onClickOpenPlanning = (id: number) => {
          // history.push(`/preparation/planning/${id}`);
          navigate(`/preparation/planning/${id}`);
        };

        const onClickPublish = () => {
          setEditCalendrierId(params.row.id);
          setOpenPopInPublishCalendrier(true);
        };

        const onClickArchive = () => {
          setEditCalendrierId(params.row.id);
          setOpenPopInArchiveCalendrier(true);
        };

        const onClickDelete = () => {
          setEditCalendrierId(params.row.id);
          setOpenPopInDeleteCalendrier(true);
        };

        return (
          <>
            <RowToolbar maxItems="0">
              <RowToolbarButton
                title="Afficher les tâches du calendrier"
                onClick={() => onClickOpenTask(params.row.id)}
              >
                <ViewListRoundedIcon />
              </RowToolbarButton>

              <RowToolbarButton title="Afficher une vue Planning" onClick={() => onClickOpenPlanning(params.row.id)}>
                <AccountTreeRoundedIcon />
              </RowToolbarButton>

              {(params.row.status === CalendrierStatus.PROVISIONAL ||
                params.row.status === CalendrierStatus.IN_PROGRESS) && (
                <RowToolbarButton title="Editer les infos du calendrier" onClick={onClickEdit}>
                  <EditRoundedIcon />
                </RowToolbarButton>
              )}

              {params.row.status === CalendrierStatus.PROVISIONAL && (
                <RowToolbarButton title="Publier le calendrier" onClick={onClickPublish}>
                  <PlayCircleFilledIcon />
                </RowToolbarButton>
              )}

              {params.row.status === CalendrierStatus.IN_PROGRESS && (
                <RowToolbarButton title="Archiver le calendrier" onClick={onClickArchive}>
                  <ArchiveIcon />
                </RowToolbarButton>
              )}

              <RowToolbarButton title="Supprimer le calendrier" onClick={onClickDelete}>
                <DeleteForeverIcon />
              </RowToolbarButton>
            </RowToolbar>
          </>
        );
      }
    }
  ];

  const rows = calendars;

  return (
    <>
      <DataGrid
        datagridKey="CalendarsTable"
        rows={rows}
        columns={columns}
        onSelectionChange={updateCurrentlySelectedRows}
        initialState={{
          pinnedColumns: { right: ["actions"] },
          sorting: {
            sortModel: [{ field: "companyLabel", sort: "asc" }]
          }
        }}
      />

      {/* Dialogs */}
      <>
        <ConfirmPopIn
          open={openPopInPublishCalendrier}
          onClose={popInPublishCalendrierOnClose}
          onConfirm={() => doPublishCalendrier()}
          dialogTitle={"Publier le calendrier"}
          dialogContent={"Confirmez-vous la publication de ce calendrier ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInArchiveCalendrier}
          onClose={popInArchiveCalendrierOnClose}
          onConfirm={() => doArchiveCalendrier()}
          dialogTitle={"Archiver le calendrier"}
          dialogContent={"Confirmez-vous l'archivage de ce calendrier ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInDeleteCalendrier}
          onClose={popInDeleteCalendrierOnClose}
          onConfirm={() => doDeleteCalendrier()}
          dialogTitle={"Supprimer le calendrier"}
          dialogContent={
            "Confirmez-vous la suppression de ce calendrier ? Cette opération est irréversible ! La fonction Archiver fait presque la même chose mais sans perte de données..."
          }
          dialogAction={"Confirmer"}
        />

        <DialogSide
          title={"Editer le calendrier"}
          onClose={clearEditContext}
          open={editContext.editMode === EditCalendrierMode.EDIT}
        >
          <CalendarFormEdit editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default CalendarsTable;
