import { Route, Routes } from "react-router-dom";

import Erreur404 from "../components/Erreur404";
import Mainlayout from "../layouts/MainLayout";
import SimpleLayout from "../layouts/SimpleLayout";
import { default as authenticationRoutes } from "../modules/authentication/routes";
import { default as dashboardRoutes } from "../modules/dashboard/routes";
import { default as executionRoutes } from "../modules/execution/routes";
import { default as preparationRoutes } from "../modules/preparation/routes";
import { default as suiviRoutes } from "../modules/suivi/routes";
// import { default as globalRoutes } from "./global";

const routes = (
  <Routes>
    <Route element={<SimpleLayout />}>{authenticationRoutes}</Route>

    <Route element={<Mainlayout />}>
      {dashboardRoutes}
      {preparationRoutes}
      {executionRoutes}
      {suiviRoutes}
    </Route>

    {/* NoPage */}
    <Route element={<SimpleLayout />}>
      <Route path="*" element={<Erreur404 />} />
    </Route>
  </Routes>
);

export default routes;
