import CommentIcon from "@mui/icons-material/Comment";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, Button, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { GridHeaderFilterCellProps } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { useCallback, useEffect, useState } from "react";
import { utils, writeFile } from "xlsx";
import { ProjectedTask, SubTaskStatusLabels, Task } from "../../../api/taskTypes";
import {
  dateYYYYMMDDFormatter,
  durationFormatter,
  projectTasks,
  relatedToDateJFormatter
} from "../../../api/tasksUtils";
import { DataGrid } from "../../../components/datagrid/DataGrid";

interface RowsOfProjectedTasks {
  row: ProjectedTask;
}

type InProgressCalendriersTasksTableProps = {
  tasks: Task[];
  editModeViewSubTask: Function;
};

const InProgressCalendarsTasksTable = ({ tasks, editModeViewSubTask }: InProgressCalendriersTasksTableProps) => {
  const [groupedTaskIds, setGroupedTaskIds] = useState<(number | null | undefined)[]>([]);
  const [rows, setRows] = useState<ProjectedTask[]>([]);

  // Group/Ungroup
  const groupAll = useCallback(() => {
    const ids: number[] = tasks.filter((t) => t.subTasks!.length >= 1).map((t) => t.id);

    setGroupedTaskIds([...ids]);
  }, [tasks]);

  useEffect(() => {
    if (tasks) {
      groupAll();
    }
  }, [tasks, groupAll]);

  function ungroupAll() {
    setGroupedTaskIds([]);
  }

  useEffect(() => {
    if (tasks) {
      // projette les taches suivant les choix de regroupement qui serviront a l'affichage
      setRows(projectTasks(tasks, groupedTaskIds));
    }
  }, [tasks, groupedTaskIds]);

  function groupByTaskId(taskId: number | number | undefined) {
    setGroupedTaskIds([...groupedTaskIds, taskId]);
  }

  function ungroupByTaskId(taskId: number | number | undefined) {
    setGroupedTaskIds(groupedTaskIds.filter((i) => i !== taskId));
  }

  const columns = [
    {
      field: "group",
      headerName: "",
      width: 40,
      minWidth: 40,
      resizable: false,
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        const onUngroup = () => {
          ungroupByTaskId(params.row.taskId);
        };

        const onGroup = () => {
          groupByTaskId(params.row.taskId);
        };
        return (
          <>
            {params.row.displayType === "GROUPED_TASK" && (
              <Tooltip title="Dégrouper les sous-tâches">
                <IconButton onClick={onUngroup} size="large">
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            )}

            {params.row.displayType === "UNGROUPED_TASK" && (
              <Tooltip title="Grouper les sous-tâches">
                <IconButton onClick={onGroup} size="large">
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      }
    },
    {
      field: "avancement",
      headerName: "Avancement",
      maxWidth: 300,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        return !params.row.subtaskId && <>{params.row.avancement}%</>;
      }
    },
    {
      field: "status",
      headerName: "Statut",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        if (params.row.status != null && params.row.status != null)
          return (
            <>
              <Chip
                label={SubTaskStatusLabels[params.row.status]}
                size="small"
                color={
                  SubTaskStatusLabels[params.row.status] === "Fait"
                    ? "success"
                    : SubTaskStatusLabels[params.row.status] === "En attente"
                      ? "warning"
                      : "default"
                }
                variant="outlined"
              />
            </>
          );
      }
    },
    {
      field: "themeLabel",
      headerName: "Thème",
      minWidth: 250,
      renderCell: (params: RowsOfProjectedTasks) => {
        return params.row.subtaskId && <></>;
      }
    },
    {
      field: "taskLabel",
      headerName: "Tâche",
      minWidth: 400,
      renderCell: (params: RowsOfProjectedTasks) => {
        return params.row.subtaskId && <></>;
      }
    },
    {
      field: "subtaskLabel",
      headerName: "Sous-tâche",
      minWidth: 500,
      // flex: 1,
      sortable: false,
      renderCell: (params: RowsOfProjectedTasks) => {
        const onClickViewSubTask = () => {
          editModeViewSubTask({ id: params.row.subtaskId });
        };

        return params.row.subtaskId ? (
          <Typography
            variant="body2"
            color="primary"
            fontWeight="bold"
            onClick={onClickViewSubTask}
            className="truncate"
          >
            - <span className="clickable">{params.row.label}</span>
          </Typography>
        ) : (
          <>
            <Chip label={`${params.row.nbSubTasks}`} size="small" />
          </>
        );
      }
    },
    {
      field: "executionDetails",
      headerName: "",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        // TODO: afficher une icone lorsqu'il y a un commentaire
        return <>{params.row.executionDetails && <CommentIcon sx={{ fontSize: 16 }} color="primary" />}</>;
      }
    },
    {
      field: "companyLabel",
      headerName: "Société",
      width: 200,
      minWidth: 200,
      renderCell: (params: RowsOfProjectedTasks) => {
        return params.row.subtaskId && <></>;
      }
    },
    {
      field: "calendarShortName",
      headerName: "Calendrier",
      width: 250,
      minWidth: 250,
      renderCell: (params: RowsOfProjectedTasks) => {
        return params.row.subtaskId && <></>;
      }
    },
    {
      field: "assignedToLogin",
      headerName: "Assigné à",
      maxWidth: 250,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params: RowsOfProjectedTasks) => {
        const personnes = params.row.assignedToLogin ? params.row.assignedToLogin.split(" ") : null;
        const nombreDePersonnes = personnes ? personnes.length : null;
        return <>{!nombreDePersonnes ? " … " : nombreDePersonnes === 1 ? <>{params.row.assignedToLogin}</> : " … "}</>;
      }
    },
    {
      field: "theoricStartDate",
      headerName: "Date début",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        // Date début
        const dateDebut = params.row.theoricStartDate;
        const selectedDateDebut = dateDebut ? new Date(dateDebut) : null;
        const formattedDateDebut = selectedDateDebut ? format(selectedDateDebut, "dd/MM/yy", { locale: frLocale }) : "";

        return (
          <>
            {params.row?.theoricStartDate ? (
              <>
                <Tooltip title={`min ${relatedToDateJFormatter(params.row.theoricStart)}`}>
                  <span>{formattedDateDebut}</span>
                </Tooltip>
              </>
            ) : (
              <>{relatedToDateJFormatter(params.row.theoricStart)}</>
            )}
          </>
        );
      }
    },
    {
      field: "deadlineDate",
      headerName: "Date butoir",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        // Date butoir
        const dateButoir = params.row.deadlineDate;
        const selectedDateButoir = dateButoir ? new Date(dateButoir) : null;
        const formatteDateButoir = selectedDateButoir
          ? format(selectedDateButoir, "dd/MM/yy", { locale: frLocale })
          : "";

        return (
          <>
            {params.row.deadlineDate ? (
              <Tooltip title={`max ${relatedToDateJFormatter(params.row.deadline)}`}>
                <span>{formatteDateButoir}</span>
              </Tooltip>
            ) : (
              <>{relatedToDateJFormatter(params.row.deadline)}</>
            )}
          </>
        );
      }
    },
    {
      field: "estimatedDuration",
      headerName: "Durée estimée",
      maxWidth: 400,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        return (
          <>
            {params.row.estimatedDuration ? (
              <>
                {!params.row.subtaskId ? (
                  <Typography variant="body2" fontWeight="bold" color="primary">
                    {durationFormatter(params.row.estimatedDuration)}
                  </Typography>
                ) : (
                  durationFormatter(params.row.estimatedDuration)
                )}
              </>
            ) : (
              <> - </>
            )}
          </>
        );
      }
    },
    {
      field: "executedByLogin",
      headerName: "Exécuté par",
      maxWidth: 400,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        return <>{params.row.executedByLogin ? <>{params.row.executedByLogin}</> : <> - </>}</>;
      }
    },
    {
      field: "executionDuration",
      headerName: "Durée réelle",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        return <>{params.row.executionDuration ? <>{durationFormatter(params.row.executionDuration)}</> : <> - </>}</>;
      }
    },
    {
      field: "executedOn",
      headerName: "Exécutée le",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        // Date réalisation
        const dateReal = params.row?.executedOn;
        const selectedDateReal = dateReal ? new Date(dateReal) : null;
        const formattedDateReal = selectedDateReal ? format(selectedDateReal, "dd/MM/yy", { locale: frLocale }) : "";
        const formattedTimeReal = selectedDateReal ? format(selectedDateReal, "HH:mm", { locale: frLocale }) : "";

        return (
          <>
            {params.row.executedOn ? (
              <Tooltip title={`à ${formattedTimeReal}`}>
                <span> {formattedDateReal}</span>
              </Tooltip>
            ) : (
              <> - </>
            )}
          </>
        );
      }
    },
    {
      field: "rank",
      headerName: "Rang",
      minWidth: 100,
      sortable: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: RowsOfProjectedTasks) => {
        return <small>{params.row.rank}</small>;
      }
    }
  ];

  // // Pour afficher les boutons actions au scroll
  // const trigger = useScrollTrigger({
  //   threshold: 100, // Valeur pour le déclenchement du scroll
  //   disableHysteresis: true,
  // });

  // EXPORT en xlsx
  const exportFile = () => {
    const data = rows.map((row) => {
      return [
        row.status,
        row.themeLabel,
        row.taskLabel,
        row.subtaskLabel,
        row.companyLabel,
        row.calendarShortName,
        row.assignedToLogin,
        row.theoricStartDate,
        row.deadlineDate,
        durationFormatter(row.estimatedDuration),
        row.executedByLogin,
        durationFormatter(row.executionDuration),
        dateYYYYMMDDFormatter(row.executedOn),
        row.avancement
        // row.rank,
      ];
    });

    data.unshift([
      "Statut",
      "Theme",
      "Sous-Tâche",
      "Prévue pour",
      "Société",
      "Calendrier",
      "Assigné à",
      "Date début",
      "Date butoir",
      "Durée estimée",
      "Exécutée par",
      "Durée réelle",
      "Exécutée le",
      "Avancement"
    ]);

    /* convert state to workbook */
    const ws = utils.aoa_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Suivi");

    /* generate XLSX file and send to client */
    writeFile(wb, "cloturer-suivi.xlsx");
  };

  // Clic sur une ligne
  const handleRowClick = (params: RowsOfProjectedTasks) => {
    editModeViewSubTask({ id: params.row.subtaskId });
  };

  return (
    <>
      {/* Table actions */}
      {/* <Box
        position={trigger ? "fixed" : "relative"}
        top={trigger ? 0 : "auto"}
        left={trigger ? 0 : "auto"}
        zIndex={100}
        width="100%"
        bgcolor="white"
        boxShadow={trigger ? 1 : 0}
        marginTop={trigger ? 0 : 2}
        display="flex"
        justifyContent="space-evenly"
        mb={4}
        p={2}
      > */}
      <Box
        position={"relative"}
        top={"auto"}
        left={"auto"}
        zIndex={100}
        width="100%"
        bgcolor="white"
        boxShadow={0}
        display="flex"
        justifyContent="space-evenly"
        mt={2}
        mb={2}
        p={2}
      >
        {groupedTaskIds.length === 0 && (
          <Button
            variant="outlined"
            startIcon={<UnfoldLessIcon />}
            onClick={() => {
              groupAll();
            }}
          >
            Grouper
          </Button>
        )}

        {groupedTaskIds.length > 0 && (
          <Button
            variant="outlined"
            startIcon={<UnfoldMoreIcon />}
            onClick={() => {
              ungroupAll();
            }}
          >
            Dégrouper
          </Button>
        )}

        <Button
          variant="outlined"
          startIcon={<SaveAltIcon />}
          onClick={() => {
            exportFile();
          }}
        >
          Exporter
        </Button>
      </Box>

      {/* {rows && (
        <details>
          <summary>Filtre</summary>
          <pre>
            <code>{JSON.stringify(rows, null, 2)}</code>
          </pre>
        </details>
      )} */}

      <DataGrid
        datagridKey="CalendarsTasksTableInProgress"
        rows={rows}
        columns={columns}
        density="compact"
        onRowClick={handleRowClick}
        initialState={{
          columns: {
            columnVisibilityModel: {
              rank: false
            }
          },
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          },
          sorting: {
            sortModel: [
              { field: "companyLabel", sort: "asc" },
              { field: "calendarShortName", sort: "asc" }
            ]
          }
        }}
      />
    </>
  );
};

export default InProgressCalendarsTasksTable;
