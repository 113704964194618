import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useListThemes } from "../../../api/services";
import DialogSide from "../../../components/DialogSide";
import MainTitle from "../../../components/MainTitle";
import AddThemeForm from "../components/ThemeFormAdd";
import ThemesTable from "../components/ThemesTable";
import { CrudEditMode, EditThemeContext } from "../components/Types";

const ThemesPage = () => {
  const theme = useTheme();

  const { data: themes } = useListThemes();
  const [editContext, setEditContext] = useState<EditThemeContext>({ editMode: CrudEditMode.NONE, themeId: null });

  const clearEditContext = () => {
    setEditContext({ editMode: CrudEditMode.NONE, themeId: null });
  };

  const editModeAdd = () => {
    setEditContext({ editMode: CrudEditMode.ADD, themeId: null });
  };

  // const trigger = useScrollTrigger({
  //   threshold: 200, // Valeur pour le déclenchement du scroll
  //   disableHysteresis: true,
  // });

  return (
    <>
      <Container maxWidth="xl">
        <MainTitle title="Gestion des thèmes">
          {themes && (
            <Typography variant="h5" component="h3" gutterBottom style={{ color: theme.palette.secondary.main }}>
              <strong>{themes.length}</strong> themes
            </Typography>
          )}
        </MainTitle>

        {/* Table actions */}
        {/* <Box
          position={trigger ? "fixed" : "relative"}
          top={trigger ? 0 : "auto"}
          left={trigger ? 0 : "auto"}
          zIndex={100}
          width="100%"
          bgcolor="white"
          boxShadow={trigger ? 1 : 0}
          marginTop={trigger ? 0 : 2}
          display="flex"
          justifyContent="space-evenly"
          mb={4}
          p={2}
        > */}
        <Box
          position={"relative"}
          top={"auto"}
          left={"auto"}
          zIndex={100}
          width="100%"
          bgcolor="white"
          boxShadow={0}
          display="flex"
          justifyContent="space-evenly"
          mt={2}
          mb={2}
          p={2}
        >
          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => editModeAdd()}>
            Ajouter un thème
          </Button>
        </Box>

        {themes && <ThemesTable themes={themes} />}
      </Container>

      {/* Dialogs */}
      <>
        {/* AJOUTER THEME */}
        <DialogSide
          title={"Ajouter un theme"}
          onClose={clearEditContext}
          open={editContext.editMode === CrudEditMode.ADD}
        >
          <AddThemeForm clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default ThemesPage;
