import { identity } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "./apiConfig";
import { HasId, useToken } from "./servicesBase";

export enum CalendrierStatus {
  PROVISIONAL = "PROVISIONAL",
  IN_PROGRESS = "IN_PROGRESS",
  ARCHIVED = "ARCHIVED"
}

export const CalendrierStatusLabels = {
  [CalendrierStatus.PROVISIONAL]: "Provisoire",
  [CalendrierStatus.IN_PROGRESS]: "En cours",
  [CalendrierStatus.ARCHIVED]: "Archivé"
};

export interface Calendrier extends HasId {
  id: number;
  shortName: string;
  longName: string;
  status: CalendrierStatus;
  dateJ: any;
  ancestorId?: number | null;
  ancestorShortName?: string | null;
  companyId: number;
  companyLabel?: string;
}

export const useListeCalendriers = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    "calendarsNotArchived",
    (): Promise<Array<Calendrier>> => {
      return fetchGet("calendars?sort=shortName&status.notEquals=ARCHIVED&page=0&size=200", token);
    },
    {
      enabled: token !== undefined
    }
  );

  return { data, status };
};

export const useListeCalendriersAll = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    "calendarsAll",
    (): Promise<Array<Calendrier>> => {
      return fetchGet("calendars?sort=shortName&page=0&size=200", token);
    },
    {
      enabled: token !== undefined
    }
  );

  return { data, status };
};

export const useListeCalendriersProvisional = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    "calendarsProvisional",
    (): Promise<Array<Calendrier>> => {
      return fetchGet("calendars?sort=shortName&status.in=PROVISIONAL&page=0&size=200", token);
    },
    {
      enabled: token !== undefined
    }
  );

  return { data, status };
};

export const useListeCalendriersInProgress = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    "calendarsInProgress",
    (): Promise<Array<Calendrier>> => {
      return fetchGet("calendars?sort=shortName&status.in=IN_PROGRESS&page=0&size=200", token);
    },
    {
      enabled: token !== undefined
    }
  );

  return { data, status };
};

export const useListeCalendriersArchived = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    "calendarsArchived",
    (): Promise<Array<Calendrier>> => {
      // return fetchGet("calendars?page=0&size=200", token);
      return fetchGet("calendars?sort=shortName&status.equals=ARCHIVED&page=0&size=200", token);
    },
    {
      enabled: token !== undefined
    }
  );

  return { data, status };
};

export const useCalendrierDetail = ({ id }: HasId) => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["calendars", id],
    (): Promise<Calendrier> => {
      return fetchGet(`calendars/${id}`, token);
    },
    {
      enabled: token !== undefined && id !== null
    }
  );

  return { data, status };
};

export interface AddCalendrierRequest {
  shortName: string;
  longName: string;
  status: CalendrierStatus;
  dateJ: string | null;
  companyId: number;
}

export const useAddCalendrierMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Calendrier, any, AddCalendrierRequest, any>(
    ({ shortName, longName, status, dateJ, companyId }) => {
      const payload = { shortName, longName, status, dateJ, companyId };
      return fetchPost("calendars", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("calendars");
        queryCache.invalidateQueries("calendarsNotArchived");
      },
      onSuccess: (data) => {
        toast.success('Calendrier "' + data.shortName + '" ajouté');
      },
      onError: () => {
        toast.error("Erreur lors de l'ajout du calendrier");
      }
    }
  );
};

export const useUpdateCalendrierMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Calendrier, any, Calendrier, any>(
    ({ id, shortName, longName, status, dateJ, ancestorId, companyId }) => {
      const payload = { id, shortName, longName, status, dateJ, ancestorId, companyId };
      return fetchPut("calendars", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("calendars");
        queryCache.invalidateQueries("calendarsNotArchived");
        queryCache.invalidateQueries("cloture");
      },
      onSuccess: (data) => {
        toast.success('Calendrier "' + data.shortName + '" modifié');
      },
      onError: () => {
        toast.error("Erreur lors de la modification du calendrier");
      }
    }
  );
};

export interface ProgressCalendrierRequest {
  id: number;
  status: CalendrierStatus;
}

export const useProgressCalendrierMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Calendrier, any, ProgressCalendrierRequest, any>(
    ({ id, status }) => {
      const payload = { id, status };
      return fetchPost("cloture/progress-calendar", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("calendars");
        queryCache.invalidateQueries("calendarsNotArchived");
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      }
    }
  );
};

export const useDeleteCalendrierMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<void, any, HasId, any>(
    ({ id }) => {
      return fetchDelete(`cloture/calendars/${id}`, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("calendars");
        queryCache.invalidateQueries("calendarsNotArchived");
      },
      onSuccess: () => {
        toast.success("Calendrier supprimé");
      },
      onError: () => {
        toast.error("Erreur lors de la suppression du calendrier");
      }
    }
  );
};

export interface DuplicateCalendrierRequest {
  ancestorId: number;
  companyId: number;
  shortName: string;
  longName: string;
  dateJ: Date;
}

export const useDuplicateCalendrierMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Calendrier, any, DuplicateCalendrierRequest, any>(
    ({ ancestorId, companyId, shortName, longName, dateJ }) => {
      const payload = { ancestorId, companyId, shortName, longName, dateJ };
      return fetchPost("cloture/duplicate-calendar", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["calendars"]);
        queryCache.invalidateQueries("calendarsNotArchived");
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
      },
      onSuccess: () => {
        toast.success("Calendrier dupliqué");
      },
      onError: () => {
        toast.error("Erreur lors de la duplication du calendrier");
      }
    }
  );
};

export const useCalendrierMermaidView = ({ id }: HasId) => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["cloture/calendar/mermaid", identity],
    (): Promise<string> => {
      return fetchGet(`cloture/calendar/${id}/mermaid`, token);
    },
    {
      enabled: token !== undefined,
      cacheTime: 0
    }
  );

  return { data, status };
};
