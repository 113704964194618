import { Alert, Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers-pro";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDuplicateCalendrierMutation, useListCompanies, useListeCalendriersAll } from "../../../api/services";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { EditCalendrierContext } from "./Types";

interface Values {
  ancestorId: number | "";
  companyId: number | "";
  shortName: string;
  longName: string;
  dateJ: Date;
}

type DuplicateCalendrierFormProps = {
  editContext: EditCalendrierContext;
  clearEditContext: Function;
};

const CalendarFormDuplicate = ({ editContext, clearEditContext }: DuplicateCalendrierFormProps) => {
  const { data: companies } = useListCompanies();
  const { data: calendars } = useListeCalendriersAll();

  const duplicateCalendrierMutation = useDuplicateCalendrierMutation();

  const [isChecked, setIsChecked] = useState(false);
  const [filteredCalendar, setFilteredCalendar] = useState(calendars);

  useEffect(() => {
    setFilteredCalendar(
      isChecked
        ? calendars?.filter((calendar) => calendar.status === "ARCHIVED")
        : calendars?.filter((calendar) => calendar.status === "IN_PROGRESS" || calendar.status === "PROVISIONAL")
    );
  }, [isChecked, calendars]);

  const formMethods = useForm<Values>({
    defaultValues: {
      ancestorId: "",
      companyId: "",
      shortName: "",
      longName: "",
      dateJ: new Date()
    },
    mode: "onChange"
  });

  const onSubmit = async (values: Values) => {
    if (values.ancestorId === "") {
      toast.error("Veuillez choisir un calendrier à dupliquer.");
      return;
    }
    if (values.companyId === "") {
      toast.error("Veuillez choisir une société cible.");
      return;
    }

    await duplicateCalendrierMutation.mutateAsync({
      ancestorId: values.ancestorId,
      companyId: values.companyId,
      shortName: values.shortName,
      longName: values.longName,
      dateJ: values.dateJ
    });

    if (clearEditContext) {
      clearEditContext();
    }
  };
  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          <Controller
            name="shortName"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nom du calendrier"
                error={!!formMethods.formState.errors.shortName}
                helperText={formMethods.formState.errors?.shortName?.message ?? "Saisir un nom pour le calendrier."}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="longName"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                error={!!formMethods.formState.errors.longName}
                helperText={formMethods.formState.errors?.longName?.message ?? "Saisir une description au calendrier."}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        {filteredCalendar && (
          <>
            <Grid item xs={12}>
              <ControllerSelect
                name="ancestorId"
                formMethods={formMethods}
                required
                label={isChecked ? `Dupliquer à partir d'un calendrier archivé ` : `Dupliquer à partir de `}
                helperText="Veuillez choisir un calendrier à dupliquer."
                mappedMenuItems={filteredCalendar?.map((calendar, index) => (
                  <MenuItem value={calendar.id} key={calendar.id}>
                    {calendar.companyLabel} - {calendar.shortName}
                  </MenuItem>
                ))}
              />
              <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} color="primary" />}
                label="Dupliquer un calendrier archivé"
              />
            </Grid>
          </>
        )}

        {companies && (
          <Grid item xs={6}>
            <ControllerSelect
              name="companyId"
              formMethods={formMethods}
              required
              label="Société cible"
              helperText="Veuillez choisir une société cible."
              mappedMenuItems={companies.map((company, index) => (
                <MenuItem value={company.id} key={company.id}>
                  {company.label}
                </MenuItem>
              ))}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Controller
            name="dateJ"
            control={formMethods.control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <>
                <DatePicker
                  value={value}
                  label="Date J"
                  slotProps={{
                    textField: {
                      helperText: formMethods.formState.errors?.dateJ?.message ?? "Sélectionnez une date J."
                    }
                  }}
                />
              </>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Alert severity="info">
            <strong>Notes : </strong> Toutes les tâches seront ensuite calculée en fonction du nombre de jours avant ou
            après la <strong>date J</strong>.
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Dupliquer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default CalendarFormDuplicate;
