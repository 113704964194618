import { Alert, Button, Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CalendrierStatus, useAddCalendrierMutation, useListCompanies } from "../../../api/services";
import { dateYYYYMMDDFormatter } from "../../../api/tasksUtils";
import ControllerSelect from "../../../components/form/ControllerSelect";

interface Values {
  label: string;
  shortName: string;
  longName: string;
  companyId: number | "";
  dateJ: Date | null;
}

interface AddCalendrierFormProps {
  clearEditContext: Function;
}

const CalendarFormAdd: React.FC<AddCalendrierFormProps> = ({ clearEditContext }) => {
  const { data: companies } = useListCompanies();

  const addCalendrierMutation = useAddCalendrierMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      shortName: "",
      longName: "",
      companyId: "",
      dateJ: undefined
    },
    mode: "onChange"
  });

  const onSubmit = async (values: Values) => {
    if (values.companyId === "") {
      toast.error("Veuillez choisir une société.");
      return;
    }

    await addCalendrierMutation.mutateAsync({
      shortName: values.shortName,
      longName: values.longName,
      status: CalendrierStatus.PROVISIONAL,
      dateJ: dateYYYYMMDDFormatter(values.dateJ),
      companyId: values.companyId
    });
    if (clearEditContext) clearEditContext();
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          <Controller
            name="shortName"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nom du calendrier"
                error={!!formMethods.formState.errors.shortName}
                helperText={formMethods.formState.errors?.shortName?.message ?? "Saisir un nom pour le calendrier."}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="longName"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                error={!!formMethods.formState.errors.longName}
                helperText={formMethods.formState.errors?.longName?.message ?? "Saisir une description au calendrier"}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        {companies && (
          <Grid item xs={12} sm={6}>
            <ControllerSelect
              name="companyId"
              formMethods={formMethods}
              required
              label="Société"
              helperText={formMethods.formState.errors?.shortName?.message ?? "Sélectionnez la société rattachée."}
              mappedMenuItems={companies.map((company) => (
                <MenuItem value={company.id} key={company.id}>
                  {company.label}
                </MenuItem>
              ))}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Controller
            name="dateJ"
            control={formMethods.control}
            render={({ field: { onChange, value, name, ref } }) => (
              <DatePicker
                value={value}
                label="Date J"
                onChange={onChange}
                slotProps={{
                  textField: {
                    helperText: formMethods.formState.errors?.dateJ?.message ?? "Sélectionnez une date J."
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Alert severity="info">
            <strong>Notes : </strong> Toutes les tâches seront ensuite calculée en fonction du nombre de jours avant ou
            après la <strong>date J</strong>.
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Ajouter
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default CalendarFormAdd;
