import { CalendrierStatus } from "../../../api/services";

export enum CrudEditMode {
  NONE,
  ADD,
  EDIT,
  DEL
}

export interface EditThemeContext {
  editMode: CrudEditMode;
  themeId: number | null;
}

export interface EditCompanyContext {
  editMode: CrudEditMode;
  companyId: number | null;
}

export enum EditCalendrierMode {
  NONE,
  ADD,
  EDIT,
  DEL,
  DUPLICATE
}

export interface EditCalendrierContext {
  editMode: EditCalendrierMode;
  calendarId: number | null;
}

export interface CalendrierPageFilters {
  statuses?: CalendrierStatus[];
  companies: number[];
  calendars: string[];
}

export enum EditTaskMode {
  NONE,
  ADD_TASK,
  EDIT_TASK,
  EDIT_CALENDAR,
  ADD_SUBTASK,
  EDIT_SUBTASK,
  DEL,
  DUPLICATE,
  TRANSLOCATE_TASK,
  TRANSLOCATE_SUBTASK
}

export interface EditTaskContext {
  editMode: EditTaskMode;
  calendarId?: number;
  taskId?: number;
  subTaskId?: number;
  parentTaskId?: number;
}
