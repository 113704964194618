import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/header/Header";

const Mainlayout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Header />
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default Mainlayout;
