import { Box, Container, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useCalendrierDetail, useCalendrierOverview, useCalendrierTasks } from "../../../api/services";
import DialogSide from "../../../components/DialogSide";
import Loading from "../../../components/Loading";
import CalendarOverview from "../components/CalendarOverview";
import CalendarTasksTable from "../components/CalendarTasksTable";
import { useNumericParam } from "../components/Hooks";
import SubTaskFormEdit from "../components/SubTaskFormEdit";
import SubTaskFormTranslocate from "../components/SubTaskFormTranslocate";
import TaskFormAdd from "../components/TaskFormAdd";
import TaskFormEdit from "../components/TaskFormEdit";
import TaskFormTranslocate from "../components/TaskFormTranslocate";
import { EditTaskContext, EditTaskMode } from "../components/Types";

const PrepareCalendarPage = () => {
  const theme = useTheme();

  const calendarId = useNumericParam("calendarId");
  const [editContext, setEditContext] = useState<EditTaskContext>({
    editMode: EditTaskMode.NONE
  });
  const { data: calendarDetail } = useCalendrierDetail({ id: calendarId });
  const { data: calendarTasks } = useCalendrierTasks({ id: calendarId });
  const { data: calendarOverview } = useCalendrierOverview({ id: calendarId });

  const clearEditContext = () => {
    setEditContext({ editMode: EditTaskMode.NONE });
  };

  const editModeAddTask = () => {
    setEditContext({ editMode: EditTaskMode.ADD_TASK, calendarId });
  };

  const editModeAddSubTaskTo = (parentTaskId: number) => {
    setEditContext({ editMode: EditTaskMode.ADD_SUBTASK, calendarId, parentTaskId });
  };

  const editModeEditTask = (taskId: number) => {
    setEditContext({ editMode: EditTaskMode.EDIT_TASK, calendarId, taskId });
  };

  const editModeEditSubTask = (subTaskId: number) => {
    setEditContext({ editMode: EditTaskMode.EDIT_SUBTASK, calendarId, subTaskId });
  };

  const editModeTranslocateTask = (taskId: number) => {
    setEditContext({ editMode: EditTaskMode.TRANSLOCATE_TASK, calendarId, taskId });
  };

  const editModeTranslocateSubTask = (subTaskId: number) => {
    setEditContext({ editMode: EditTaskMode.TRANSLOCATE_SUBTASK, calendarId, subTaskId });
  };

  if (!calendarDetail || !calendarTasks || !calendarOverview) {
    return <Loading />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Préparation du calendrier :
            <span style={{ color: theme.palette.secondary.main }}> {calendarDetail.shortName}</span>
          </Typography>
        </Box>

        <CalendarOverview calendar={calendarDetail} calendarOverview={calendarOverview} />
      </Container>

      <CalendarTasksTable
        calendar={calendarDetail}
        tasks={calendarTasks}
        editModeAddTask={editModeAddTask}
        editModeAddSubTaskTo={editModeAddSubTaskTo}
        editModeEditTask={editModeEditTask}
        editModeEditSubTask={editModeEditSubTask}
        editModeTranslocateTask={editModeTranslocateTask}
        editModeTranslocateSubTask={editModeTranslocateSubTask}
      />

      {/* DialogSide */}
      <>
        {/* NOUVELLE TACHE */}
        <DialogSide
          title={editContext.editMode === EditTaskMode.ADD_SUBTASK ? "Nouvelle Sous-tâche" : "Nouvelle tache"}
          onClose={clearEditContext}
          open={editContext.editMode === EditTaskMode.ADD_TASK || editContext.editMode === EditTaskMode.ADD_SUBTASK}
        >
          <TaskFormAdd editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>

        {/* EDITER TACHE */}
        <DialogSide
          title={"Editer la tache"}
          onClose={clearEditContext}
          open={editContext.editMode === EditTaskMode.EDIT_TASK}
        >
          <TaskFormEdit editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>

        {/* EDITER SOUS-TACHE */}
        <DialogSide
          title={"Editer la sous-tâche"}
          onClose={clearEditContext}
          open={editContext.editMode === EditTaskMode.EDIT_SUBTASK}
        >
          <SubTaskFormEdit editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>

        {/* DEPLACER TACHE */}
        <DialogSide
          title={"Déplacer la tache"}
          onClose={clearEditContext}
          open={editContext.editMode === EditTaskMode.TRANSLOCATE_TASK}
        >
          <TaskFormTranslocate editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>

        {/* DEPLACER SOUS-TACHE */}
        <DialogSide
          title={"Déplacer la sous-tâche"}
          onClose={clearEditContext}
          open={editContext.editMode === EditTaskMode.TRANSLOCATE_SUBTASK}
        >
          <SubTaskFormTranslocate editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default PrepareCalendarPage;
