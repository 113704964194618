import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Chip, IconButton, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
// import DoneIcon from "@mui/icons-material/DoneRounded";
// import ListIcon from "@mui/icons-material/List";
// import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
// import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EditRoundedIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import SkipIcon from "@mui/icons-material/NotInterested";
// import UndoIcon from "@mui/icons-material/Undo";
import { GridHeaderFilterCellProps } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { useState } from "react";
import { useCompleteSubTask, useSkipSubTask, useUndoSubTask } from "../../../api/services";
import { SubTaskStatusLabels, SubTaskV } from "../../../api/taskTypes";
import { durationFormatter, relatedToDateJFormatter } from "../../../api/tasksUtils";
import ConfirmPopIn from "../../../components/DialogConfirm";
import DataGrid from "../../../components/datagrid/DataGrid";
import RowToolbar from "../../../components/datagrid/RowToolbar";
import RowToolbarButton from "../../../components/datagrid/RowToolbarButton";

interface RowsOfSubTaskV {
  row: SubTaskV;
}

type MyTasksTableProps = {
  tasks: SubTaskV[];
  editModeViewSubTask: Function;
  editModeCreateReliquat: Function;
};

const MyTasksTableProps = ({ tasks, editModeViewSubTask, editModeCreateReliquat }: MyTasksTableProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [openPopInCompleteTaskMass, setOpenPopInCompleteTaskMass] = useState(false);
  const [openPopInUndoTask, setOpenPopInUndoTask] = useState(false);
  const [openPopInUndoTaskMass, setOpenPopInUndoTaskMass] = useState(false);
  const [openPopInSkipTask, setOpenPopInSkipTask] = useState(false);
  const [openPopInSkipTaskMass, setOpenPopInSkipTaskMass] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<SubTaskV | null>(null);
  const { callCompleteSubTask } = useCompleteSubTask();
  const { callUndoSubTask } = useUndoSubTask();
  const { callSkipSubTask } = useSkipSubTask();

  function popInCompleteTaskMassOnClose() {
    setOpenPopInCompleteTaskMass(false);
  }

  function popInUndoTaskOnClose() {
    setOpenPopInUndoTask(false);
  }

  function popInUndoTaskMassOnClose() {
    setOpenPopInUndoTaskMass(false);
  }

  function popInSkipTaskOnClose() {
    setOpenPopInSkipTask(false);
  }

  function popInSkipTaskMassOnClose() {
    setOpenPopInSkipTaskMass(false);
  }

  function updateCurrentlySelectedRows(gridSelectionModelChangeParams: {
    selectionModel: number[] | ((prevState: never[]) => never[]);
  }) {
    if (Array.isArray(gridSelectionModelChangeParams.selectionModel)) {
      if (!_.isEqual(selectedRows.sort(), gridSelectionModelChangeParams.selectionModel.sort())) {
        setSelectedRows(gridSelectionModelChangeParams.selectionModel);
      }
    }
  }

  const doCompleteTaskMass = async (listTasks: number[]) => {
    listTasks.forEach((t) => {
      callCompleteSubTask({ id: t });
    });
    popInCompleteTaskMassOnClose();
  };

  const doUndoTask = async () => {
    if (taskToDelete) {
      callUndoSubTask({ id: taskToDelete.id });

      popInUndoTaskOnClose();
      setTaskToDelete(null);
    }
  };

  const doUndoTaskMass = async (listTasks: number[]) => {
    listTasks.forEach((t) => {
      callUndoSubTask({ id: t });
    });
    popInUndoTaskMassOnClose();
  };

  const doSkipTask = async () => {
    if (taskToDelete) {
      callSkipSubTask({ id: taskToDelete.id });

      popInSkipTaskOnClose();
      setTaskToDelete(null);
    }
  };

  const doSkipTaskMass = async (listTasks: number[]) => {
    listTasks.forEach((t) => {
      callSkipSubTask({ id: t });
    });
    popInSkipTaskMassOnClose();
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 10,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        const onClickComplete = () => {
          callCompleteSubTask({ id: params.row.id });
        };

        const onClickUndo = () => {
          setTaskToDelete(params.row);
          setOpenPopInUndoTask(true);
        };

        return (
          <>
            {(params.row.status === "PENDING" || params.row.status === "REMAINDER") && (
              <IconButton title="Compléter la tâche" onClick={onClickComplete}>
                <CheckBoxOutlineBlankIcon />
              </IconButton>
            )}

            {params.row.status === "DONE" && (
              <IconButton title="Remettre la tâche en cours" onClick={onClickUndo}>
                <CheckBoxIcon />
              </IconButton>
            )}
          </>
        );
      }
    },
    {
      field: "status",
      headerName: "Statut",
      width: 120,
      align: "left",
      headerAlign: "left",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        return (
          <>
            <Chip
              label={SubTaskStatusLabels[params.row.status]}
              color={
                SubTaskStatusLabels[params.row.status] === "Fait"
                  ? "success"
                  : SubTaskStatusLabels[params.row.status] === "En attente"
                    ? "warning"
                    : "default"
              }
              variant="outlined"
            />
          </>
        );
      }
    },
    {
      field: "themeLabel",
      headerName: "Thème",
      width: 250,
      renderCell: (params: RowsOfSubTaskV) => {
        return (
          <>
            <>{params.row.themeLabel && <>{params.row.themeLabel}</>}</>
          </>
        );
      }
    },
    {
      field: "taskLabel",
      headerName: "Tâche",
      width: 400,
      renderCell: (params: RowsOfSubTaskV) => {
        return (
          <>
            <>{params.row.taskLabel}</>
          </>
        );
      }
    },
    {
      field: "label",
      headerName: "Sous-Tâche",
      width: 500,
      renderCell: (params: RowsOfSubTaskV) => {
        const onClickViewSubTask = () => {
          editModeViewSubTask({ id: params.row.id });
        };

        return (
          <Typography
            variant="body2"
            color="primary"
            fontWeight="bold"
            onClick={onClickViewSubTask}
            className="truncate"
          >
            - <span className="clickable">{params.row.label}</span>
          </Typography>
        );
      }
    },
    {
      field: "companyLabel",
      headerName: "Société",
      width: 150
    },
    {
      field: "calendarShortName",
      headerName: "Calendrier",
      width: 300
    },
    {
      field: "theoricStartDate",
      headerName: "Date début",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        // Date début
        const dateDebut = params.row.theoricStartDate;
        const selectedDateDebut = dateDebut ? new Date(dateDebut) : null;
        const formattedDateDebut = selectedDateDebut ? format(selectedDateDebut, "dd/MM/yy", { locale: frLocale }) : "";

        return (
          <>
            {params.row.theoricStartDate ? (
              <>
                <Tooltip title={relatedToDateJFormatter(params.row.theoricStart)}>
                  <span>{formattedDateDebut}</span>
                </Tooltip>
              </>
            ) : (
              <>{relatedToDateJFormatter(params.row.theoricStart)}</>
            )}
          </>
        );
      }
    },
    {
      field: "deadlineDate",
      headerName: "Date butoir",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        // Date butoir
        const dateButoir = params.row?.deadlineDate;
        const selectedDateButoir = dateButoir ? new Date(dateButoir) : null;
        const formatteDateButoir = selectedDateButoir
          ? format(selectedDateButoir, "dd/MM/yy", { locale: frLocale })
          : "";

        return (
          <>
            {params.row.deadlineDate ? (
              <Tooltip title={relatedToDateJFormatter(params.row.deadline)}>
                <span>{formatteDateButoir}</span>
              </Tooltip>
            ) : (
              <>{relatedToDateJFormatter(params.row.deadline)}</>
            )}
          </>
        );
      }
    },
    {
      field: "estimatedDuration",
      headerName: "Durée estimée",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      // valueFormatter: (params: SubTaskV) => durationFormatter(params.estimatedDuration),
      renderCell: (params: RowsOfSubTaskV) => {
        return <>{params.row.estimatedDuration ? <>{durationFormatter(params.row.estimatedDuration)}</> : <> - </>}</>;
      }
    },
    {
      field: "executionDuration",
      headerName: "Durée réelle",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      // valueFormatter: (params: SubTaskV) => durationFormatter(params.executionDuration),
      renderCell: (params: RowsOfSubTaskV) => {
        return <>{params.row.executionDuration ? <>{durationFormatter(params.row.executionDuration)}</> : <> - </>}</>;
      }
    },
    // {
    //   field: "executedByLogin",
    //   headerName: "Réalisé par",
    //   width: 200,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   renderCell: (params: RowsOfSubTaskV) => {
    //     return <>{params.row.executedByLogin ? <>{params.row.executedByLogin}</> : <> - </>}</>;
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      disableReorder: true,
      width: 90,
      resizable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      // disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        const onClickViewSubTask = () => {
          editModeViewSubTask({ id: params.row.id });
        };

        const onClickComplete = () => {
          callCompleteSubTask({ id: params.row.id });
        };

        const onClickUndo = () => {
          setTaskToDelete(params.row);
          setOpenPopInUndoTask(true);
        };

        const onClickSkip = () => {
          setTaskToDelete(params.row);
          setOpenPopInSkipTask(true);
        };

        const onClickCreateReliquat = () => {
          editModeCreateReliquat({ id: params.row.id });
        };

        return (
          <RowToolbar maxItems="0">
            <RowToolbarButton title="Détail de la tâche" onClick={onClickViewSubTask}>
              <EditRoundedIcon />
            </RowToolbarButton>

            {(params.row.status === "PENDING" || params.row.status === "REMAINDER") && (
              <RowToolbarButton title="Compléter la tâche" onClick={onClickComplete}>
                <CheckBoxIcon />
              </RowToolbarButton>
            )}

            {(params.row.status === "PENDING" || params.row.status === "REMAINDER") && params.row.eligibleRemainder && (
              <RowToolbarButton title="Créer un reliquat" onClick={onClickCreateReliquat}>
                <AlarmOnIcon />
              </RowToolbarButton>
            )}

            {(params.row.status === "PENDING" || params.row.status === "REMAINDER") && (
              <RowToolbarButton title="Rendre la tâche non applicable" onClick={onClickSkip}>
                <HighlightOffIcon />
              </RowToolbarButton>
            )}

            {params.row.status === "DONE" && (
              <RowToolbarButton title="Remettre la tâche en cours" onClick={onClickUndo}>
                <CheckBoxOutlineBlankIcon />
              </RowToolbarButton>
            )}

            {params.row.status === "SKIPPED" && (
              <RowToolbarButton title="Remettre la tâche en cours" onClick={onClickUndo}>
                <CheckBoxOutlineBlankIcon />
              </RowToolbarButton>
            )}
          </RowToolbar>
        );
      }
    }
  ];

  const rows = tasks;

  // const trigger = useScrollTrigger({
  //   threshold: 200, // Valeur pour le déclenchement du scroll
  //   disableHysteresis: true
  // });

  return (
    <>
      {/* Table Actions */}
      {/* <Box
        position={trigger ? "fixed" : "relative"}
        top={trigger ? 0 : "auto"}
        left={trigger ? 0 : "auto"}
        zIndex={100}
        width="100%"
        bgcolor="white"
        boxShadow={trigger ? 1 : 0}
        marginTop={trigger ? 0 : 2}
        display="flex"
        justifyContent="space-evenly"
        mb={4}
        p={2}
      >
        <Button
          startIcon={<CheckBoxIcon />}
          onClick={() => {
            setOpenPopInCompleteTaskMass(true);
          }}
        >
          Compléter la tâche
        </Button>

        <Button
          startIcon={<CheckBoxOutlineBlankIcon />}
          onClick={() => {
            setOpenPopInUndoTaskMass(true);
          }}
        >
          Remettre la tâche en cours
        </Button>

        <Button
          startIcon={<HighlightOffIcon />}
          onClick={() => {
            setOpenPopInSkipTaskMass(true);
          }}
        >
          Rendre la tâche non applicable
        </Button>
      </Box> */}

      <DataGrid
        datagridKey="MyTasksTable"
        // disableColumnFilter
        // disableColumnSelector
        disableDensitySelector
        rows={rows}
        columns={columns}
        // density="compact"
        // checkboxSelection
        disableRowSelectionOnClick
        unlimitedSize={true}
        // disableSelectionOnClick
        // hideFooterRowCount={true}
        // pageSize={rows.length < 99 ? rows.length + 2 : 100} //le +2 est un hack pour un pb de rendering après ajout
        // sortModel={sortModel}
        onSelectionModelChange={(updateCurrentlySelectedRows: any) => {
          setSelectedRows(updateCurrentlySelectedRows);
        }}
        rowSelectionModel={selectionModel}
        initialState={{
          pinnedColumns: { left: ["checkbox"], right: ["actions"] },
          pagination: {
            paginationModel: {
              pageSize: 20
            }
          },
          sorting: {
            sortModel: [{ field: "themeLabel", sort: "asc" }]
          }
        }}
      />

      {/* Dialogs */}
      <>
        <ConfirmPopIn
          open={openPopInCompleteTaskMass}
          onClose={popInCompleteTaskMassOnClose}
          onConfirm={() => doCompleteTaskMass(selectedRows)}
          dialogTitle={"Compléter les tâches sélectionnées"}
          dialogContent={"Confirmez-vous la complétion des tâches sélectionnées ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInUndoTask}
          onClose={popInUndoTaskOnClose}
          onConfirm={() => doUndoTask()}
          dialogTitle={"Remettre la tâche en cours"}
          dialogContent={"Confirmez-vous le retour au statut à faire pour cette tâche ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInUndoTaskMass}
          onClose={popInUndoTaskMassOnClose}
          onConfirm={() => doUndoTaskMass(selectedRows)}
          dialogTitle={"Marquer les tâches sélectionnées à faire"}
          dialogContent={"Confirmez-vous le retour au statut à faire pour les tâches sélectionnées ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInSkipTask}
          onClose={popInSkipTaskOnClose}
          onConfirm={() => doSkipTask()}
          dialogTitle={"Rendre la tâche non applicable"}
          dialogContent={"Confirmez-vous que cette tâche n'est pas applicable ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInSkipTaskMass}
          onClose={popInSkipTaskMassOnClose}
          onConfirm={() => doSkipTaskMass(selectedRows)}
          dialogTitle={"Rendre les tâches sélectionnées non applicable"}
          dialogContent={"Confirmez-vous que les tâches sélectionnées ne sont pas applicable ?"}
          dialogAction={"Confirmer"}
        />
      </>
    </>
  );
};

export default MyTasksTableProps;
