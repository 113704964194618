import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { Calendrier, CalendrierOverview, CalendrierStatusLabels } from "../../../api/services";

type CalendrierOverviewProps = {
  calendarOverview: CalendrierOverview;
  calendar: Calendrier;
};

const CalendarOverview = ({ calendar, calendarOverview }: CalendrierOverviewProps) => {
  // TODO: style temporaire
  const th = {
    backgroundColor: "#eee",
    width: "40%"
  };

  // DateJ
  const dateJ = calendar.dateJ;
  const selectedDateJ = dateJ ? new Date(dateJ) : null;
  const formattedDateJ = selectedDateJ ? format(selectedDateJ, "dd MMMM yyyy", { locale: frLocale }) : "";

  return (
    <>
      <Box display="flex">
        <Box sx={{ flexBasis: "50%", marginRight: "1rem" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Nom</strong>
                  </TableCell>
                  <TableCell>{calendar.shortName}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell>{calendar.longName}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Société</strong>
                  </TableCell>
                  <TableCell>{calendar.companyLabel}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Date J</strong>
                  </TableCell>
                  <TableCell>{dateJ ? <>{formattedDateJ}</> : <>non défini</>}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Statut</strong>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={CalendrierStatusLabels[calendar.status]}
                      color={
                        CalendrierStatusLabels[calendar.status] === "En cours"
                          ? "success"
                          : CalendrierStatusLabels[calendar.status] === "Provisoire"
                            ? "warning"
                            : "default"
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* {calendar && (
            <pre>
              <code>{JSON.stringify(calendar, null, 2)}</code>
            </pre>
          )} */}
        </Box>

        <Box sx={{ flexBasis: "50%" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Tâches / Process</strong>
                  </TableCell>
                  <TableCell>
                    <Chip label={`${calendarOverview.nbTasks}`} color="secondary" size="small" />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Sous-tâches</strong>
                  </TableCell>
                  <TableCell>
                    <Chip label={`${calendarOverview.nbSubTasks}`} color="secondary" size="small" />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>- en cours</strong>
                  </TableCell>
                  <TableCell>
                    <Chip label={`${calendarOverview.nbSubTasksPending}`} color="secondary" size="small" />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>- terminées</strong>
                  </TableCell>
                  <TableCell>
                    <Chip label={`${calendarOverview.nbSubTasksFinished}`} color="secondary" size="small" />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" style={th}>
                    <strong>Avancement</strong>
                  </TableCell>
                  <TableCell>
                    <strong>
                      {typeof calendarOverview.progress === "number" && <>{calendarOverview.progress.toFixed(0)}%</>}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* {calendarTasks && (
            <pre>
              <code>{JSON.stringify(calendarTasks, null, 2)}</code>
            </pre>
          )} */}
        </Box>
      </Box>
    </>
  );
};

export default CalendarOverview;
