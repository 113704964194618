// import RestoreIcon from "@mui/icons-material/Restore";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  frFR,
  gridVisibleColumnDefinitionsSelector,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import _ from "lodash";
import useLocalStorage from "../../global/hooks/useLocalStorage";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

const useStyles = makeStyles({
  even: {
    backgroundColor: "white"
  }
});

export default function DataGrid(props) {
  const classes = useStyles();

  const dataGridStyle = {
    width: "100%",
    margin: "20px 0 20px 0",
    padding: "0 24px"
  };

  // LOCALSTORAGE
  const customProps = { ...props };
  const apiRef = useGridApiRef();

  // Paramètres utilisateur
  const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
    props.datagridKey + "-columnVisibilityModel",
    {}
  );
  const [sortModel, setSortModel] = useLocalStorage(props.datagridKey + "-sortModel", []);
  const [columnWidths, setColumnWidths] = useLocalStorage(props.datagridKey + "-columnWidths", null);
  const [columnOrder, setColumnOrder] = useLocalStorage(props.datagridKey + "-columnOrder", null);

  const onColumnVisibilityModelChange = (model, details) => {
    setColumnVisibilityModel(model);
  };

  const onSortModelChange = (model, details) => {
    setSortModel(model);
  };

  const onColumnWidthChange = (params, event, details) => {
    // console.log("🚀 ~ file: DataGrid.jsx:51 ~ onColumnWidthChange ~ params, event, details:", params, event, details);

    setColumnWidths(
      gridVisibleColumnDefinitionsSelector(apiRef.current.state).map((col) => {
        return { field: col.field, width: col.width };
      })
    );
  };

  const onColumnReorder = (params, event, details) => {
    setColumnOrder(gridVisibleColumnDefinitionsSelector(apiRef.current.state).map((col) => col.field));
  };

  // Application du tri stocké
  if (columnOrder !== null) {
    customProps.columns.sort((a, b) => columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field));
  }

  // Application des largeurs de colonnes stockées
  if (columnWidths !== null) {
    customProps.columns = customProps.columns.map((column) => {
      return {
        ...column,
        width: _.find(columnWidths, { field: column.field }) ? _.find(columnWidths, { field: column.field }).width : 300
        // flex: _.find(columnWidths, { field: column.field }) ? undefined : column.flex,
      };
    });
  }

  const clearLocalStorageItem = (key) => {
    localStorage.removeItem(key);
  };

  const handleReset = () => {
    clearLocalStorageItem(props.datagridKey + "-columnVisibilityModel");
    clearLocalStorageItem(props.datagridKey + "-sortModel");
    clearLocalStorageItem(props.datagridKey + "-columnWidths");
    clearLocalStorageItem(props.datagridKey + "-columnOrder");

    // Réinitialiser les états locaux
    setColumnVisibilityModel({});
    setSortModel([]);
    setColumnWidths(null);
    setColumnOrder(null);

    // TODO: rafraichir la page proprement
    // apiRef.current.forceUpdate();
    window.location.reload();
  };

  return (
    <div style={dataGridStyle} className="datagrid">
      <DataGridPro
        {...props}
        apiRef={apiRef}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onColumnWidthChange={onColumnWidthChange}
        onColumnOrderChange={onColumnReorder}
        columns={customProps.columns}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        autoHeight
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
        sx={{
          boxShadow: 4,
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main"
          }
        }}
        className="custom-data-grid"
        unstable_headerFilters
        pagination
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => (
            <div style={{ display: "flex", justifyContent: "space-between", margin: "16px" }}>
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                {/* <GridToolbarFilterButton /> */}
                {/* <GridToolbarExport /> */}
              </GridToolbarContainer>
              <Button startIcon={<ClearRoundedIcon />} onClick={handleReset} size="small" color="inherit">
                Réinitialiser les colonnes
              </Button>
            </div>
          )
        }}
      />
    </div>
  );
}

export { DataGrid };
