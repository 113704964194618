import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "./apiConfig";
import { HasId, useToken } from "./servicesBase";

export interface Company extends HasId {
  id: number;
  label: string;
  description: string;
}

export const useListCompanies = () => {
  const { token } = useToken();
  return useQuery(
    ["companies"],
    (): Promise<Array<Company>> => {
      return fetchGet(`companies?page=0&size=200&sort=label`, token);
    },
    {
      enabled: token !== undefined
    }
  );
};

export const useCompanyDetail = ({ id }: HasId) => {
  const { token } = useToken();
  return useQuery(
    ["companies", id],
    (): Promise<Company> => {
      return fetchGet(`companies/${id}`, token);
    },
    {
      enabled: token !== undefined && id !== null
    }
  );
};

export interface AddCompanyRequest {
  label: string;
  description: string;
}

export const useAddCompanyMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Company, any, AddCompanyRequest, any>(
    ({ label, description }) => {
      const payload = { label, description };
      return fetchPost("companies", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("companies");
      },
      onSuccess: (data) => {
        toast.success('Société "' + data.label + '" ajoutée');
      },
      onError: () => {
        toast.error("Erreur lors de l'ajout de la société");
      }
    }
  );
};

export const useUpdateCompanyMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Company, any, Company, any>(
    ({ id, label, description }) => {
      const payload = { id, label, description };
      return fetchPut("companies", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("companies");
      },
      onSuccess: (data) => {
        toast.success('Société "' + data.label + '" modifiée');
      },
      onError: () => {
        toast.error("Erreur lors de la modification de la société");
      }
    }
  );
};

export const useDeleteCompanyMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<void, any, HasId, any>(
    ({ id }) => {
      return fetchDelete(`companies/${id}`, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("companies");
      },
      onSuccess: () => {
        toast.success("Société supprimée");
      },
      onError: () => {
        toast.error("Erreur lors de la suppression de la société");
      }
    }
  );
};
