import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import lucemBlue from "./lucem-blue.png";
import lucemWhite from "./lucem-white.png";

export default function Logo({ variant }) {
  const logoPath = variant === "white" ? lucemWhite : lucemBlue;

  return (
    <>
      <Typography variant="h6" component="h1" style={{ margin: "10px 0 8px 0" }}>
        <Link to="/">
          <img alt="logo lucem" width="150px" src={logoPath} />
        </Link>
      </Typography>

      <Typography component="p" style={{ color: variant === "white" ? "#fff" : "#000" }}>
        CLOTURER
      </Typography>
    </>
  );
}
