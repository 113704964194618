import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/FilterAlt";
import VisibilityOffIcon from "@mui/icons-material/FilterAltOff";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CalendrierStatus, CalendrierStatusLabels, useListCompanies, useListeCalendriers } from "../../../api/services";
import { CalendrierPageFilters } from "./Types";

type CalendriersFiltersProps = {
  viewFilter: CalendrierPageFilters;
  setViewFilter: React.Dispatch<React.SetStateAction<CalendrierPageFilters>>;
};

const CalendarsFilters = ({ viewFilter, setViewFilter }: CalendriersFiltersProps) => {
  const statuses = [CalendrierStatus.IN_PROGRESS, CalendrierStatus.PROVISIONAL];
  const { data: companies } = useListCompanies();
  const { data: calendarList } = useListeCalendriers();
  const [calendars, setCalendriers] = useState<string[]>([]);

  useEffect(() => {
    if (calendarList) {
      setCalendriers(_.uniq(calendarList.map((calendar) => calendar.shortName)));
    }
  }, [calendarList, setCalendriers]);

  const filterByCompany = (event: any) => {
    setViewFilter({ ...viewFilter, companies: event.target.value });
  };

  const filterByCalendrier = (event: any, newValue: any) => {
    setViewFilter({ ...viewFilter, calendars: newValue });
  };

  const filterByStatus = (event: any) => {
    setViewFilter({ ...viewFilter, statuses: event.target.value });
  };

  const resetFilter = () => {
    setViewFilter({ companies: [], calendars: [], statuses: [] });
  };

  // Plus de Filtres
  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const toggleList = () => {
    const newValue = !isFiltersOpen;
    setFiltersOpen(newValue);
    localStorage.setItem("isFiltersOpen", JSON.stringify(newValue));
  };

  useEffect(() => {
    // Vérifier si la valeur est déjà présente dans le localStorage
    const storedValue = localStorage.getItem("isFiltersOpen");
    if (storedValue) {
      setFiltersOpen(JSON.parse(storedValue));
    }
  }, []);

  return (
    <>
      <Grid container spacing={1} mt={2}>
        {isFiltersOpen && (
          <>
            {/* Calendrier */}
            <Grid item xs={12} sm={7}>
              {calendars && (
                <Autocomplete
                  multiple
                  options={calendars}
                  value={viewFilter.calendars}
                  onChange={filterByCalendrier}
                  noOptionsText="Aucun calendrier trouvé"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Calendrier"
                      variant="outlined"
                      placeholder="Sélectionnez un ou plusieurs calendrier"
                      helperText="Filtrer par calendrier. Vous pouvez aussi saisir une partie du calendrier recherché."
                    />
                  )}
                />
              )}
            </Grid>

            {/* Société */}
            <Grid item xs={12} sm={3}>
              {companies && (
                <FormControl fullWidth>
                  <InputLabel>Société</InputLabel>
                  <Select displayEmpty label="Société" multiple value={viewFilter.companies} onChange={filterByCompany}>
                    {companies.map((company, index) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par société</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* Statut */}
            <Grid item xs={9} sm={2}>
              {statuses && (
                <FormControl fullWidth>
                  <InputLabel>Statut</InputLabel>
                  <Select displayEmpty label="Statut" multiple value={viewFilter.statuses} onChange={filterByStatus}>
                    {statuses.map((status, index) => (
                      <MenuItem key={status} value={status}>
                        {CalendrierStatusLabels[status]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par statut</FormHelperText>
                </FormControl>
              )}
            </Grid>
          </>
        )}
      </Grid>

      {/* Reset */}
      <Grid container mt={0} spacing={2} justifyContent="center">
        <Grid item>
          <Button
            onClick={toggleList}
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={isFiltersOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
          >
            {isFiltersOpen ? "Masquer les filtres" : "Afficher les filtres"}
          </Button>
        </Grid>

        <Grid item>
          {isFiltersOpen && (
            <Button
              color="inherit"
              variant="outlined"
              size="small"
              onClick={resetFilter}
              startIcon={<ClearRoundedIcon />}
            >
              Réinitialiser les filtres
            </Button>
          )}
        </Grid>
      </Grid>

      {/* {viewFilter && (
        <details>
          <summary>Filtre</summary>
          <pre>
            <code>{JSON.stringify(viewFilter, null, 2)}</code>
          </pre>
        </details>
      )} */}
    </>
  );
};

export default CalendarsFilters;
