import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CommentIcon from "@mui/icons-material/Comment";
import EditRoundedIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import { GridHeaderFilterCellProps, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useCompleteSubTask, useSkipSubTask, useUndoSubTask } from "../../../api/services";
import { SubTaskStatusLabels, SubTaskV } from "../../../api/taskTypes";
import { durationFormatter } from "../../../api/tasksUtils";
import ConfirmPopIn from "../../../components/DialogConfirm";
import DataGrid from "../../../components/datagrid/DataGrid";
import RowToolbar from "../../../components/datagrid/RowToolbar";
import RowToolbarButton from "../../../components/datagrid/RowToolbarButton";

interface RowsOfSubTaskV {
  row: SubTaskV;
}

type TasksTableProps = {
  tasks: SubTaskV[];
  editModeViewSubTask: Function;
  editModeCreateReliquat: Function;
};

const TasksTable = ({ tasks, editModeViewSubTask, editModeCreateReliquat }: TasksTableProps) => {
  const [openPopInUndoTask, setOpenPopInUndoTask] = useState(false);
  const [openPopInSkipTask, setOpenPopInSkipTask] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<SubTaskV | null>(null);

  const { callCompleteSubTask } = useCompleteSubTask();
  const { callUndoSubTask } = useUndoSubTask();
  const { callSkipSubTask } = useSkipSubTask();

  function popInUndoTaskOnClose() {
    setOpenPopInUndoTask(false);
  }

  function popInSkipTaskOnClose() {
    setOpenPopInSkipTask(false);
  }

  const doUndoTask = async () => {
    if (taskToDelete) {
      callUndoSubTask({ id: taskToDelete.id });

      popInUndoTaskOnClose();
      setTaskToDelete(null);
    }
  };

  const doSkipTask = async () => {
    if (taskToDelete) {
      callSkipSubTask({ id: taskToDelete.id });
      popInSkipTaskOnClose();
      setTaskToDelete(null);
    }
  };

  // TaskMass
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [openPopInCompleteTaskMass, setOpenPopInCompleteTaskMass] = useState(false);
  const [openPopInUndoTaskMass, setOpenPopInUndoTaskMass] = useState(false);
  const [openPopInSkipTaskMass, setOpenPopInSkipTaskMass] = useState(false);

  function popInCompleteTaskMassOnClose() {
    setOpenPopInCompleteTaskMass(false);
  }

  function popInUndoTaskMassOnClose() {
    setOpenPopInUndoTaskMass(false);
  }

  function popInSkipTaskMassOnClose() {
    setOpenPopInSkipTaskMass(false);
  }

  const doCompleteTaskMass = async (listTasks: any) => {
    listTasks.forEach((t: any) => {
      callCompleteSubTask({ id: t });
    });
    popInCompleteTaskMassOnClose();
  };

  const doUndoTaskMass = async (listTasks: any) => {
    listTasks.forEach((t: any) => {
      callUndoSubTask({ id: t });
    });
    popInUndoTaskMassOnClose();
  };

  const doSkipTaskMass = async (listTasks: any) => {
    listTasks.forEach((t: any) => {
      callSkipSubTask({ id: t });
    });
    popInSkipTaskMassOnClose();
  };

  const columns = [
    {
      field: "status",
      headerName: "Statut",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        return (
          <>
            <Chip
              label={SubTaskStatusLabels[params.row.status]}
              size="small"
              color={
                SubTaskStatusLabels[params.row.status] === "Fait"
                  ? "success"
                  : SubTaskStatusLabels[params.row.status] === "En attente"
                    ? "warning"
                    : "default"
              }
              variant="outlined"
            />
          </>
        );
      }
    },
    {
      field: "themeLabel",
      headerName: "Thème",
      minWidth: 250,
      renderCell: (params: RowsOfSubTaskV) => {
        return (
          <>
            <>{params.row.themeLabel && <>{params.row.themeLabel}</>}</>
          </>
        );
      }
    },
    {
      field: "taskLabel",
      headerName: "Tâche",
      minWidth: 400,
      renderCell: (params: RowsOfSubTaskV) => {
        return (
          <>
            <>{params.row.taskLabel}</>
          </>
        );
      }
    },
    {
      field: "label",
      headerName: "Sous-Tâche",
      minWidth: 500,
      flex: 1,
      renderCell: (params: RowsOfSubTaskV) => {
        const onClickViewSubTask = () => {
          // console.log(params.row);
          editModeViewSubTask({ id: params.row.id });
        };

        return (
          <Typography
            variant="body2"
            color="primary"
            fontWeight="bold"
            onClick={onClickViewSubTask}
            className="truncate"
          >
            - <span className="clickable">{params.row.label}</span>
          </Typography>
        );
      }
    },
    {
      field: "companyLabel",
      headerName: "Société",
      width: 200,
      minWidth: 200
    },
    {
      field: "calendarShortName",
      headerName: "Calendrier",
      width: 250,
      minWidth: 250
    },
    {
      field: "assignedToLogin",
      headerName: "Assigné à",
      maxWidth: 250,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params: RowsOfSubTaskV) => {
        return <>{params.row.assignedToLogin}</>;
      }
    },
    {
      field: "theoricStartDate",
      headerName: "Date début",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueGetter: (params: RowsOfSubTaskV) => {
        const dateDebut = params.row.theoricStartDate;
        const selectedDateDebut = dateDebut ? new Date(dateDebut) : null;
        return selectedDateDebut;
      }
      // renderCell: (params: RowsOfSubTaskV) => {
      //   // Date début
      //   const dateDebut = params.row.theoricStartDate;
      //   const selectedDateDebut = dateDebut ? new Date(dateDebut) : null;
      //   const formattedDateDebut = selectedDateDebut ? format(selectedDateDebut, "dd/MM/yy", { locale: frLocale }) : "";

      //   return (
      //     <>
      //       {params.row.theoricStartDate ? (
      //         <>
      //           <Tooltip title={relatedToDateJFormatter(params.row.theoricStart)}>
      //             <span>{formattedDateDebut}</span>
      //           </Tooltip>
      //         </>
      //       ) : (
      //         <>{relatedToDateJFormatter(params.row.theoricStart)}</>
      //       )}
      //     </>
      //   );
      // },
    },
    {
      field: "deadlineDate",
      headerName: "Date butoir",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueGetter: (params: RowsOfSubTaskV) => {
        const dateButoir = params.row?.deadlineDate;
        const selectedDateButoir = dateButoir ? new Date(dateButoir) : null;
        return selectedDateButoir;
      }
      // renderCell: (params: RowsOfSubTaskV) => {
      //   // Date butoir
      //   const dateButoir = params.row?.deadlineDate;
      //   const selectedDateButoir = dateButoir ? new Date(dateButoir) : null;
      //   const formatteDateButoir = selectedDateButoir
      //     ? format(selectedDateButoir, "dd/MM/yy", { locale: frLocale })
      //     : "";

      //   return (
      //     <>
      //       {params.row.deadlineDate ? (
      //         <Tooltip title={relatedToDateJFormatter(params.row.deadline)}>
      //           <span>{formatteDateButoir}</span>
      //         </Tooltip>
      //       ) : (
      //         <>{relatedToDateJFormatter(params.row.deadline)}</>
      //       )}
      //     </>
      //   );
      // },
    },
    {
      field: "estimatedDuration",
      headerName: "Durée estimée",
      maxWidth: 400,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      // valueFormatter: (params: SubTaskV) => durationFormatter(params.estimatedDuration),
      renderCell: (params: RowsOfSubTaskV) => {
        return <>{params.row.estimatedDuration ? <>{durationFormatter(params.row.estimatedDuration)}</> : <> - </>}</>;
      }
    },
    {
      field: "executionDuration",
      headerName: "Durée réelle",
      maxWidth: 400,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      // valueFormatter: (params: SubTaskV) => durationFormatter(params.executionDuration),
      renderCell: (params: RowsOfSubTaskV) => {
        return <>{params.row.executionDuration ? <>{durationFormatter(params.row.executionDuration)}</> : <> - </>}</>;
      }
    },
    {
      field: "executionDetails",
      headerName: "",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        const onClickViewSubTask = () => {
          editModeViewSubTask({ id: params.row.id });
        };

        if (params.row.executionDetails !== null) {
          return (
            <Tooltip title={params.row.executionDetails}>
              <CommentIcon fontSize="small" color="warning" onClick={onClickViewSubTask} />
            </Tooltip>
          );
        }
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      disableReorder: true,
      minWidth: 90,
      resizable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      // disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfSubTaskV) => {
        const onClickViewSubTask = () => {
          editModeViewSubTask({ id: params.row.id });
        };

        const onClickComplete = () => {
          callCompleteSubTask({ id: params.row.id });
        };

        const onClickUndo = () => {
          setTaskToDelete(params.row);
          setOpenPopInUndoTask(true);
        };

        const onClickSkip = () => {
          setTaskToDelete(params.row);
          setOpenPopInSkipTask(true);
        };

        const onClickCreateReliquat = () => {
          editModeCreateReliquat({ id: params.row.id });
        };

        return (
          <RowToolbar maxItems="0">
            <RowToolbarButton title="Détail de la tâche" onClick={onClickViewSubTask}>
              <EditRoundedIcon />
            </RowToolbarButton>

            {(params.row.status === "PENDING" || params.row.status === "REMAINDER") && (
              <RowToolbarButton title="Compléter la tâche" onClick={onClickComplete}>
                <CheckBoxIcon />
              </RowToolbarButton>
            )}

            {(params.row.status === "PENDING" || params.row.status === "REMAINDER") && params.row.eligibleRemainder && (
              <RowToolbarButton title="Créer un reliquat" onClick={onClickCreateReliquat}>
                <AlarmOnIcon />
              </RowToolbarButton>
            )}

            {(params.row.status === "PENDING" || params.row.status === "REMAINDER") && (
              <RowToolbarButton title="Rendre la tâche non applicable" onClick={onClickSkip}>
                <HighlightOffIcon />
              </RowToolbarButton>
            )}

            {params.row.status === "DONE" && (
              <RowToolbarButton title="Remettre la tâche en cours" onClick={onClickUndo}>
                <CheckBoxOutlineBlankIcon />
              </RowToolbarButton>
            )}

            {params.row.status === "SKIPPED" && (
              <RowToolbarButton title="Remettre la tâche en cours" onClick={onClickUndo}>
                <CheckBoxOutlineBlankIcon />
              </RowToolbarButton>
            )}
          </RowToolbar>
        );
      }
    }
  ];

  const rows = tasks;

  // // Pour afficher les boutons actions au scroll
  // const trigger = useScrollTrigger({
  //   threshold: 200, // Valeur pour le déclenchement du scroll
  //   disableHysteresis: true,
  // });

  return (
    <>
      {/* Table Actions */}
      {rowSelectionModel.length > 0 && (
        // <Box
        //   position={trigger ? "fixed" : "relative"}
        //   top={trigger ? 0 : "auto"}
        //   left={trigger ? 0 : "auto"}
        //   zIndex={100}
        //   width="100%"
        //   bgcolor="white"
        //   boxShadow={trigger ? 1 : 0}
        //   marginTop={trigger ? 0 : 2}
        //   display="flex"
        //   justifyContent="space-evenly"
        //   mb={4}
        //   p={2}
        // >
        <Box
          position={"relative"}
          top={"auto"}
          left={"auto"}
          zIndex={100}
          width="100%"
          bgcolor="white"
          boxShadow={0}
          display="flex"
          justifyContent="space-evenly"
          mt={2}
          mb={2}
          p={2}
        >
          <Button
            variant="outlined"
            startIcon={<CheckBoxIcon />}
            onClick={() => {
              setOpenPopInCompleteTaskMass(true);
            }}
          >
            Compléter
          </Button>

          <Button
            variant="outlined"
            startIcon={<CheckBoxOutlineBlankIcon />}
            onClick={() => {
              setOpenPopInUndoTaskMass(true);
            }}
          >
            Remettre en cours
          </Button>

          <Button
            variant="outlined"
            startIcon={<HighlightOffIcon />}
            onClick={() => {
              setOpenPopInSkipTaskMass(true);
            }}
          >
            Rendre non applicable
          </Button>
        </Box>
      )}

      <DataGrid
        datagridKey="TasksTable"
        rows={rows}
        columns={columns}
        initialState={{
          pinnedColumns: { left: ["checkbox"], right: ["actions"] },
          pagination: {
            paginationModel: {
              pageSize: 20
            }
          },
          sorting: {
            sortModel: [{ field: "theoricStartDate", sort: "asc" }]
          }
        }}
        pageSizeOptions={[20]}
        // TaskMass
        onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection
      />

      {/* Dialogs */}
      <>
        <ConfirmPopIn
          open={openPopInCompleteTaskMass}
          onClose={popInCompleteTaskMassOnClose}
          onConfirm={() => doCompleteTaskMass(rowSelectionModel)}
          dialogTitle={"Compléter les tâches sélectionnées"}
          dialogContent={"Confirmez-vous la complétion de toutes ces tâches ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInUndoTaskMass}
          onClose={popInUndoTaskMassOnClose}
          onConfirm={() => doUndoTaskMass(rowSelectionModel)}
          dialogTitle={"Marquer les tâches sélectionnées à faire"}
          dialogContent={"Confirmez-vous le retour au statut à faire pour les tâches sélectionnées ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInSkipTaskMass}
          onClose={popInSkipTaskMassOnClose}
          onConfirm={() => doSkipTaskMass(rowSelectionModel)}
          dialogTitle={"Rendre les tâches sélectionnées non applicable"}
          dialogContent={"Confirmez-vous que les tâches sélectionnées ne sont pas applicable ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInUndoTask}
          onClose={popInUndoTaskOnClose}
          onConfirm={() => doUndoTask()}
          dialogTitle={"Remettre la tâche en cours"}
          dialogContent={"Confirmez-vous le retour au statut à faire pour toutes ces tâches ?"}
          dialogAction={"Confirmer"}
        />

        <ConfirmPopIn
          open={openPopInSkipTask}
          onClose={popInSkipTaskOnClose}
          onConfirm={() => doSkipTask()}
          dialogTitle={"Rendre la tâche non applicable"}
          dialogContent={"Confirmez-vous que toues ces tâches sont non-applicables ?"}
          dialogAction={"Confirmer"}
        />
      </>
    </>
  );
};

export default TasksTable;
