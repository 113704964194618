import { Button, Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Calendrier,
  CalendrierStatus,
  useCreateTaskMutation,
  useListUsers,
  useListeCalendriers,
  usePostponedSubTask,
  useSubTaskDetail
} from "../../../api/services";
import { durationFormatter, parseDuration } from "../../../api/tasksUtils";
import Loading from "../../../components/Loading";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { useUsersWithUnspecified } from "../../preparation/components/Hooks";
import TaskDropDown from "../../preparation/components/TaskDropDown";
import { EditContext } from "./Types";

interface Values {
  calendarId: number | "";
  taskId: number | "";
  taskLabel: string;
  themeId: number | "";
  label: string;
  instructions: string;
  executionDetails: string;
  assignedToId: number | "";
  theoricStart: string;
  deadline: string;
  estimatedDuration: string;
  eligibleRemainder: boolean;
}

interface ReliquatFormAddProps {
  editContext: EditContext;
  clearEditContext: Function;
}

const ReliquatForm = ({ editContext, clearEditContext }: ReliquatFormAddProps) => {
  const { data: subtask } = useSubTaskDetail({ id: editContext.subTaskId });
  const { data: calendars } = useListeCalendriers();

  const [filteredCalendriers, setFilteredCalendriers] = useState<Calendrier[]>([]);

  useEffect(() => {
    if (calendars) {
      setFilteredCalendriers(calendars?.filter((calendar) => calendar.status !== CalendrierStatus.ARCHIVED));
    }
  }, [calendars, setFilteredCalendriers]);

  const { data: users } = useListUsers();
  const availableUsers = useUsersWithUnspecified(users);

  const createTaskMutation = useCreateTaskMutation();
  const { callPostponedSubTask } = usePostponedSubTask();

  const formMethods = useForm<Values>({
    defaultValues: {
      calendarId: "",
      taskId: "",
      taskLabel: "",
      themeId: "",
      label: "",
      instructions: "",
      executionDetails: "",
      assignedToId: "",
      theoricStart: "",
      deadline: "",
      estimatedDuration: "",
      eligibleRemainder: false
    }
  });

  useEffect(() => {
    if (subtask && calendars && users) {
      formMethods.reset({
        ...formMethods.getValues(),
        calendarId: subtask?.calendarId,
        themeId: subtask?.themeId,
        label: subtask?.label,
        instructions: subtask?.instructions || "",
        executionDetails: subtask?.executionDetails || "",
        assignedToId: subtask?.assignedToId || "",
        theoricStart: subtask?.theoricStart?.toString(),
        deadline: subtask?.deadline?.toString(),
        estimatedDuration: durationFormatter(subtask?.estimatedDuration) ?? "",
        eligibleRemainder: false
      });
    }
  }, [subtask, calendars, users, formMethods]);

  const calendarId = useWatch({ name: "calendarId", control: formMethods.control });

  const onSubmit = async (values: Values) => {
    if (values.calendarId === "") {
      toast.error("Veuillez sélectionner un calendrier");
      return;
    }

    const theoricStart = values.theoricStart === "" ? null : parseInt(values.theoricStart);
    const deadline = values.deadline === "" ? null : parseInt(values.deadline);

    if (theoricStart !== null && deadline !== null && theoricStart !== undefined && deadline !== undefined) {
      if (deadline < theoricStart) {
        toast.error("Erreur : La date de début doit être avant la Date butoir");
        return;
      }
    }

    await createTaskMutation.mutateAsync({
      calendarId: values.calendarId,
      taskId: values.taskId === "" ? null : values.taskId,
      themeId: values.themeId === "" ? null : values.themeId,
      taskLabel: values.taskLabel,
      label: values.label,
      assignedToId: values.assignedToId === "" ? null : values.assignedToId,
      theoricStart: theoricStart,
      deadline: deadline,
      estimatedDuration: parseDuration(values.estimatedDuration),
      instructions: values.instructions,
      executionDetails: values.executionDetails,
      eligibleRemainder: values.eligibleRemainder,
      remainder: true
    });
    await callPostponedSubTask({ id: subtask?.id });

    if (clearEditContext) {
      clearEditContext();
    }
  };
  if (!subtask) {
    return <Loading />;
  }
  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          {filteredCalendriers && (
            <ControllerSelect
              name="calendarId"
              formMethods={formMethods}
              required
              label="Créer le reliquat dans le calendrier"
              mappedMenuItems={filteredCalendriers.map((calendar, index) => (
                <MenuItem key={calendar.id} value={calendar.id}>
                  {calendar.companyLabel} - {calendar.shortName}
                </MenuItem>
              ))}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {calendarId !== "" && <TaskDropDown formMethods={formMethods} calendarId={calendarId} />}
        </Grid>

        {users && (
          <Grid item xs={12}>
            <ControllerSelect
              name="assignedToId"
              formMethods={formMethods}
              label="Assigné à"
              mappedMenuItems={availableUsers.map((user, index) => (
                <MenuItem key={user.value} value={user.value}>
                  {user.label}
                </MenuItem>
              ))}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Controller
            name="theoricStart"
            control={formMethods.control}
            render={({ field }) => (
              <TextField {...field} label="Date début" fullWidth type="number" helperText="en jours par rapport à J" />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="deadline"
            control={formMethods.control}
            render={({ field }) => (
              <TextField {...field} label="Date butoir" fullWidth type="number" helperText="en jours par rapport à J" />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="estimatedDuration"
            control={formMethods.control}
            rules={{
              validate: {
                value: (value) => {
                  if (value) {
                    const duration = durationFormatter(parseDuration(value));
                    if (!duration) {
                      return "Durée invalide";
                    }
                  }
                  return true;
                }
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Durée estimée"
                type="text"
                error={!!formMethods.formState.errors.estimatedDuration}
                helperText={
                  formMethods.formState.errors?.estimatedDuration?.message ??
                  "En heures ou minutes : 01h30m, 01h ou 30m"
                }
                sx={{
                  "& .MuiFormHelperText-root": {
                    whiteSpace: "nowrap"
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="executionDetails"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Commentaire"
                error={!!formMethods.formState.errors.executionDetails}
                helperText={
                  formMethods.formState.errors?.instructions?.message ??
                  "Saisissez ici pourquoi vous créez un reliquat pour cette tâche"
                }
                multiline
                minRows={4}
                required
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Reporter la sous-tâche
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default ReliquatForm;
