import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Box, Button, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Calendrier, CalendrierStatusLabels, useListeCalendriers } from "../../../api/services";
import DialogSide from "../../../components/DialogSide";
import MainTitle from "../../../components/MainTitle";
import CalendarFormAdd from "../components/CalendarFormAdd";
import CalendarFormDuplicate from "../components/CalendarFormDuplicate";
import CalendarsFilters from "../components/CalendarsFilters";
import CalendarsTable from "../components/CalendarsTable";
import { CalendrierPageFilters, EditCalendrierContext, EditCalendrierMode } from "../components/Types";

const CalendarsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: calendars } = useListeCalendriers();

  const [editContext, setEditContext] = useState<EditCalendrierContext>({
    editMode: EditCalendrierMode.NONE,
    calendarId: null
  });

  const clearEditContext = () => {
    setEditContext({ editMode: EditCalendrierMode.NONE, calendarId: null });
  };

  const editModeDuplicate = () => {
    setEditContext({ editMode: EditCalendrierMode.DUPLICATE, calendarId: null });
  };

  const editModeAdd = () => {
    setEditContext({ editMode: EditCalendrierMode.ADD, calendarId: null });
  };

  const navigateToArchivedCalendar = () => {
    navigate("./archived");
  };

  const [viewFilter, setViewFilter] = useState<CalendrierPageFilters>({
    companies: [],
    calendars: [],
    statuses: []
  });

  const [filteredData, setFilteredData] = useState<Calendrier[]>([]);

  useEffect(() => {
    calendars &&
      setFilteredData(
        calendars.filter((calendar) => {
          return (
            (viewFilter.statuses?.length === 0 || viewFilter.statuses?.includes(calendar.status)) &&
            (viewFilter.companies.length === 0 || viewFilter.companies.includes(calendar.companyId)) &&
            (viewFilter.calendars.length === 0 || viewFilter.calendars.includes(calendar.shortName))
          );
        })
      );
  }, [viewFilter, calendars, navigate]);

  // // Pour afficher les boutons actions au scroll
  // const trigger = useScrollTrigger({
  //   threshold: 200, // Valeur pour le déclenchement du scroll
  //   disableHysteresis: true,
  // });

  return (
    <>
      <Container maxWidth="xl">
        <MainTitle title="Gestion des calendriers">
          {filteredData && viewFilter && (
            <Typography variant="h5" component="h3" gutterBottom style={{ color: theme.palette.secondary.main }}>
              <strong>{filteredData.length}</strong> calendrier{filteredData.length > 1 && "s"}{" "}
              {viewFilter.statuses &&
                viewFilter.statuses.length > 0 &&
                viewFilter.statuses.map((status) => CalendrierStatusLabels[status]).join(", ")}
            </Typography>
          )}
        </MainTitle>

        {/* Filters */}
        <CalendarsFilters viewFilter={viewFilter} setViewFilter={setViewFilter} />

        {/* Table Actions */}
        {/* <Box
        position={trigger ? "fixed" : "relative"}
        top={trigger ? 0 : "auto"}
        left={trigger ? 0 : "auto"}
        zIndex={100}
        width="100%"
        bgcolor="white"
        boxShadow={trigger ? 1 : 0}
        marginTop={trigger ? 0 : 2}
        display="flex"
        justifyContent="space-evenly"
        mb={4}
        p={2}
      > */}
        <Box
          position={"relative"}
          top={"auto"}
          left={"auto"}
          zIndex={100}
          width="100%"
          bgcolor="white"
          boxShadow={0}
          display="flex"
          justifyContent="space-evenly"
          mt={2}
          mb={2}
          p={2}
        >
          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => editModeAdd()}>
            Ajouter un calendrier
          </Button>

          <Button variant="outlined" startIcon={<FileCopyIcon />} onClick={() => editModeDuplicate()}>
            Dupliquer un calendrier
          </Button>

          <Button variant="outlined" startIcon={<InventoryIcon />} onClick={() => navigateToArchivedCalendar()}>
            Calendriers archivés
          </Button>
        </Box>
      </Container>

      {filteredData && <CalendarsTable calendars={filteredData} />}

      {/* Dialogs */}
      <>
        {/* AJOUT CALENDRIER */}
        <DialogSide
          title="Ajouter un calendrier"
          onClose={clearEditContext}
          open={editContext.editMode === EditCalendrierMode.ADD}
        >
          <CalendarFormAdd clearEditContext={clearEditContext} />
        </DialogSide>

        {/* DUPLICATION CALENDRIER */}
        <DialogSide
          title="Dupliquer le calendrier"
          onClose={clearEditContext}
          open={editContext.editMode === EditCalendrierMode.DUPLICATE}
        >
          <CalendarFormDuplicate editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default CalendarsPage;
