import { Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAddThemeMutation } from "../../../api/services";

interface ThemeValues {
  label: string;
  description: string;
}

interface AddThemeFormProps {
  clearEditContext: Function;
}

const ThemeFormAdd = ({ clearEditContext }: AddThemeFormProps) => {
  const addThemeMutation = useAddThemeMutation();

  const formMethods = useForm<ThemeValues>({
    defaultValues: {
      label: "",
      description: ""
    },
    mode: "onChange"
  });

  const onSubmit = async (values: ThemeValues) => {
    await addThemeMutation.mutateAsync({
      label: values.label,
      description: values.description
    });

    if (clearEditContext) clearEditContext();
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          <Controller
            name="label"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nom"
                error={!!formMethods.formState.errors.label}
                helperText={formMethods.formState.errors?.label?.message ?? "Veuillez saisir le nom de votre thème"}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="description"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                helperText="Veuillez saisir une description pour thème"
                multiline
                minRows={4}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Ajouter
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default ThemeFormAdd;
