import { SubTaskStatus } from "../../../api/taskTypes";

export interface MyTasksPageFilters {
  login: string | null | undefined;
  statuses: SubTaskStatus[];
  deadlines: string;
  label: string;
  themeLabel: any;
  calendars: string[];
  companies: number[];
}

export enum EditMode {
  NONE,
  VIEW_SUBTASK,
  CREATE_RELIQUAT
}
export interface EditContext {
  editMode: EditMode;
  calendarId?: number;
  subTaskId?: number;
}
