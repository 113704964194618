import { SubTaskStatus } from "../../../api/taskTypes";

export interface CalendrierPageFilters {
  statuses: SubTaskStatus[];
  companies: number[];
  calendars: number[];
  themes: number[];
  assignedToUsers: any;
  deadlines: string;
}

export enum EditMode {
  NONE,
  VIEW_SUBTASK
}

export interface EditContext {
  editMode: EditMode;
  calendarId?: number;
  subTaskId?: number;
}
