import { AppBar, Avatar, Box, Container, Toolbar, Tooltip, useTheme } from "@mui/material";
import { useContext } from "react";
import { useWhoAmI } from "../../api/services";
import { UserContext } from "../../global/providers/UserProvider";
import Menu from "../menu/Menu";
import Logo from "./Logo";

function Header() {
  useWhoAmI();
  const ctx = useContext(UserContext);

  const theme = useTheme();

  if (!ctx?.user) {
    return <></>;
  }

  // doc MUI https://mui.com/material-ui/react-avatar/
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
  }

  return (
    <AppBar
      position="static"
      style={{ margin: "0 0 20px 0", backgroundColor: "#2F71FF" }}
      color="inherit"
      elevation={2}
    >
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start"
            }}
          >
            <Menu />
            <Logo variant="white" />
          </Box>

          <Box
            style={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 20,
              marginRight: 20
            }}
          >
            {/* <TextField
            fullWidth
            variant="outlined"
            placeholder="Rechercher une tâche ou un calendrier"
            size="small"
            style={{ backgroundColor: "#fff" }}
          /> */}
          </Box>

          {ctx?.user && (
            <Box sx={{ mr: 2 }}>
              <Tooltip title={`${ctx?.user.firstName} ${ctx?.user.lastName}`}>
                <Avatar {...stringAvatar(`${ctx?.user.firstName} ${ctx?.user.lastName}`)} />
              </Tooltip>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
