import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";
import NavList from "./NavList";

const Menu = () => {
  const [isMainMenuOpen, setMainMenuOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setMainMenuOpen(open);
  };

  const handleDrawerClose = () => {
    setMainMenuOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={toggleDrawer(true)}
        onKeyDown={toggleDrawer(false)}
        color="inherit"
        style={{ color: "#fff" }}
        size="large"
      >
        <MenuIcon />
      </IconButton>

      <Drawer transitionDuration={250} anchor="left" open={isMainMenuOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 260, bgcolor: "background.paper" }}>
          <NavList onClose={handleDrawerClose} />
          {/* {list("mainMenu")} */}
        </Box>
      </Drawer>
    </>
  );
};

export default Menu;
