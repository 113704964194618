import { Container, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTasks } from "../../../api/services";
import { HasId } from "../../../api/servicesBase";
import { SubTaskV } from "../../../api/taskTypes";
import DialogSide from "../../../components/DialogSide";
import DialogTask from "../../../components/DialogTask";
import MainTitle from "../../../components/MainTitle";
import { UserContext } from "../../../global/providers/UserProvider";
import ReliquatFormAdd from "../components/ReliquatFormAdd";
import SubTaskView from "../components/SubTaskView";
import TasksFilters from "../components/TasksFilters";
import MyTasksTable from "../components/TasksTable";
import { EditContext, EditMode, MyTasksPageFilters } from "../components/Types";

const TasksPage = () => {
  const currentUser = useContext(UserContext);

  const [viewFilter, setViewFilter] = useState<MyTasksPageFilters>({
    calendars: [],
    companies: [],
    deadlines: "Echues & En cours",
    label: "null",
    login: null,
    currentUserId: null,
    assignedToUsers: [],
    statuses: [],
    themeLabel: []
  });

  //console.log(currentUser);
  useEffect(() => {
    if (viewFilter.assignedToUsers.length == 0 && currentUser !== null && currentUser.user !== null) {
      setViewFilter({
        calendars: [],
        companies: [],
        deadlines: "Toutes",
        label: "",
        login: currentUser!.user!.login,
        currentUserId: currentUser!.user!.id,
        assignedToUsers: [currentUser!.user!.id],
        statuses: [],
        themeLabel: []
      });
    }
  }, [currentUser, setViewFilter, viewFilter]);

  const [filteredData, setFilteredData] = useState<SubTaskV[]>([]);

  const { tasks } = useTasks();

  const [editContext, setEditContext] = useState<EditContext>({
    editMode: EditMode.NONE
  });

  const clearEditContext = () => {
    setEditContext({ editMode: EditMode.NONE });
  };

  const editModeViewSubTask = ({ id }: HasId) => {
    if (!id) {
      return;
    }
    setEditContext({ editMode: EditMode.VIEW_SUBTASK, subTaskId: id });
  };

  const editModeCreateReliquat = ({ id }: HasId) => {
    if (!id) {
      return;
    }
    setEditContext({ editMode: EditMode.CREATE_RELIQUAT, subTaskId: id });
  };

  useEffect(() => {
    const maintenant = moment();

    tasks &&
      setFilteredData(
        tasks.filter((task) => {
          return (
            // prettier-ignore
            (viewFilter.statuses.length === 0 || viewFilter.statuses.includes(task.status)) &&
            (viewFilter.assignedToUsers.length === 0
              || viewFilter.assignedToUsers.includes(task.assignedToId)
              || (task.assignedToId === null && viewFilter.assignedToUsers.includes(undefined))) &&
            (viewFilter.deadlines === "Toutes"
              || (viewFilter.deadlines === "Echues & En cours" && moment(task.theoricStartDate, "YYYY-MM-DD") < maintenant)
              || (viewFilter.deadlines === "Echues" && moment(task.deadlineDate, "YYYY-MM-DD") < maintenant)
              || (viewFilter.deadlines === "En cours" && moment(task.theoricStartDate, "YYYY-MM-DD") < maintenant && maintenant < moment(task.deadlineDate, "YYYY-MM-DD"))
              ||(viewFilter.deadlines === "Futures" && maintenant < moment(task.theoricStartDate, "YYYY-MM-DD"))) &&
            (viewFilter.calendars.length === 0 || viewFilter.calendars.includes(task.calendarShortName)) &&
            (viewFilter.companies.length === 0 || viewFilter.companies.includes(task.companyId)) &&
            (viewFilter.label?.length === 0 || task.label.includes(viewFilter.label) || task.taskLabel.includes(viewFilter.label)) &&
            (viewFilter.themeLabel.length === 0 || viewFilter.themeLabel.includes(task.themeId))
          );
        })
      );
  }, [viewFilter, tasks]);

  const theme = useTheme();

  return (
    <>
      <Container maxWidth="xl">
        <MainTitle title="Tâches">
          {tasks && (
            <Typography variant="h5" component="h3" gutterBottom style={{ color: theme.palette.secondary.main }}>
              {filteredData.length
                ? `${filteredData.length} tâche${filteredData.length > 1 ? "s" : ""}
                ${viewFilter.assignedToUsers.length <= 1 && viewFilter.assignedToUsers[0] === undefined ? `non` : ``}
                ${filteredData.length > 1 ? "assignées" : "assignée"}`
                : "Aucune tâche"}

              {viewFilter.assignedToUsers.length === 1 &&
                viewFilter.assignedToUsers[0] === viewFilter.currentUserId &&
                ` à ${viewFilter.login}`}

              {viewFilter.assignedToUsers.length > 1 && (
                <small>
                  <sup> [1]</sup>
                </small>
              )}
            </Typography>
          )}
        </MainTitle>

        <TasksFilters viewFilter={viewFilter} setViewFilter={setViewFilter} />
      </Container>

      {tasks && (
        <MyTasksTable
          tasks={filteredData}
          editModeViewSubTask={editModeViewSubTask}
          editModeCreateReliquat={editModeCreateReliquat}
        />
      )}

      {/* Dialogs */}
      <>
        <DialogTask
          title={"Sous-tâche"}
          onClose={clearEditContext}
          open={editContext.editMode === EditMode.VIEW_SUBTASK}
        >
          <SubTaskView editContext={editContext} clearEditContext={clearEditContext} />
        </DialogTask>

        <DialogSide
          title={"Créer un reliquat et compléter la Sous-tâche"}
          onClose={clearEditContext}
          open={editContext.editMode === EditMode.CREATE_RELIQUAT}
        >
          <ReliquatFormAdd editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default TasksPage;
