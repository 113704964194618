import React, { createContext, useState } from "react";

export const UserContext = createContext<UserContextInterface | null>(null);

export interface Account {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  langKey: string;
}
export interface UserContextInterface {
  user: Account | null;
  setUser: React.Dispatch<React.SetStateAction<Account | null>>;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface UserProviderProps {
  children: React.ReactNode;
  defaultUser?: Account;
}

export function UserProvider({ children, defaultUser }: UserProviderProps): JSX.Element {
  // Le user est stocké dans le state afin de pouvoir être modifiée à la volée
  const [user, setUser] = useState<Account | null>(defaultUser ? defaultUser : null);
  const [token, setToken] = useState(window.localStorage.getItem("token"));
  return <UserContext.Provider value={{ user, setUser, token, setToken }}>{children}</UserContext.Provider>;
}
