import { Button, MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useListThemes, useTaskDetail, useUpdateTaskMutation } from "../../../api/services";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { useThemesWithUnspecified } from "./Hooks";
import { EditTaskContext } from "./Types";

interface Values {
  rank: string;
  themeId: number | "";
  taskLabel: string;
  label: string;
  instructions: string;
  assignedToId: number;
  // These are strings because TextField always return a string, even when type="number" is specified
  theoricStart: string;
  deadline: string;
  estimatedDuration: string;
}

interface EditTaskFormProps {
  editContext: EditTaskContext;
  clearEditContext: Function;
}

const EditTaskForm = ({ editContext, clearEditContext }: EditTaskFormProps) => {
  const { data: task } = useTaskDetail({ id: editContext.taskId });
  const { data: themes } = useListThemes();
  const availableThemes = useThemesWithUnspecified(themes);

  const updateTaskMutation = useUpdateTaskMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      rank: "",
      themeId: "",
      label: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (task && themes) {
      formMethods.reset({
        rank: task.rank.toString(),
        themeId: task.themeId,
        label: task.label
      });
    }
  }, [task, formMethods, themes]);

  const onSubmit = async (values: Values) => {
    if (!task) {
      return;
    }

    await updateTaskMutation.mutateAsync({
      id: task.id,
      calendarId: task.calendarId,
      ancestorId: task.ancestorId,
      themeId: values.themeId === "" ? null : values.themeId,
      rank: task.rank,
      label: values.label
    });

    if (clearEditContext) {
      clearEditContext();
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          {
            <ControllerSelect
              name="themeId"
              formMethods={formMethods}
              label="Thème"
              helperText="Choisissez un thème"
              mappedMenuItems={availableThemes.map((theme, index) => (
                <MenuItem key={theme.value} value={theme.value}>
                  {formMethods && theme.label}
                </MenuItem>
              ))}
            />
          }
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="label"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Tache"
                error={!!formMethods.formState.errors.label}
                helperText={formMethods.formState.errors?.label?.message ? "" : "Nom de la tâche"}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit" disabled={formMethods.formState.isSubmitting}>
            Mettre à jour
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default EditTaskForm;
