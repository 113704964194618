import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, MenuList } from "@mui/material";
import React from "react";

const RowToolbar = ({ maxItems, children }) => {
  const maxVisibleItems = maxItems ? maxItems : 0;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const enfants = React.Children.toArray(children);
  //   const vChildren = children;
  //   const hChildren = [];
  const vChildren = enfants ? enfants.slice(0, maxVisibleItems) : [];
  const hChildren = enfants ? enfants.slice(maxVisibleItems) : [];

  return (
    <>
      {vChildren}

      {hChildren.length > 0 && (
        <IconButton onClick={handleClick} size="large">
          <MoreHorizIcon />
        </IconButton>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuList dense>
          {hChildren.map((child, index) => (
            <div key={index} onClick={handleClose}>
              {child}
            </div>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default RowToolbar;
