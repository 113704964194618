import { Button, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useThemeDetail, useUpdateThemeMutation } from "../../../api/services";
import { EditThemeContext } from "./Types";

interface ThemeValues {
  label: string;
  description: string;
}

type EditThemeFormProps = {
  editContext: EditThemeContext;
  clearEditContext: Function;
};

const ThemeFormEdit = ({ editContext, clearEditContext }: EditThemeFormProps) => {
  const { data: theme } = useThemeDetail({ id: editContext.themeId });

  const updateThemeMutation = useUpdateThemeMutation();

  const formMethods = useForm<ThemeValues>({
    defaultValues: {
      label: "",
      description: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (theme) {
      theme.description = theme.description ?? "";
      formMethods.reset(theme);
    }
  }, [theme, formMethods]);

  const onSubmit = async (values: ThemeValues) => {
    try {
      await updateThemeMutation.mutateAsync({
        id: theme!.id,
        label: values.label,
        description: values.description
      });

      if (clearEditContext) {
        clearEditContext();
      }
    } catch (error) {
      // NOTE: Gestion du champ description trop long
      if ((error as any).response?.data?.status === 400) {
        const fieldErrors = (error as any).response.data.fieldErrors;

        const errorMessages: { [key: string]: string } = {
          description: "La description est trop longue"
        };

        fieldErrors.forEach((fieldError: any) => {
          const fieldName = fieldError.field;
          const errorMessage = errorMessages[fieldName] || fieldError.message;
          formMethods.setError(fieldName, {
            type: "manual",
            message: errorMessage
          });
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={4} mt={1}>
          <Grid item xs={12}>
            <Controller
              name="label"
              control={formMethods.control}
              rules={{ required: { value: true, message: "Champ Requis" } }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nom"
                  error={!!formMethods.formState.errors.label}
                  helperText={formMethods.formState.errors?.label?.message ?? "Modifiez le nom du thème"}
                  fullWidth
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="description"
              control={formMethods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  error={!!formMethods.formState.errors.description}
                  helperText={formMethods.formState.errors?.description?.message ?? "Modifiez la description du thème"}
                  multiline
                  minRows={4}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="outlined" type="submit">
              Mettre à jour
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default ThemeFormEdit;
