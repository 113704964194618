import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/FilterAlt";
import VisibilityOffIcon from "@mui/icons-material/FilterAltOff";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  Calendrier,
  CalendrierStatus,
  useListCompanies,
  useListThemes,
  useListUsers,
  useListeCalendriers
} from "../../../api/services";
import { SubTaskStatus, SubTaskStatusLabels } from "../../../api/taskTypes";
import { UserContext } from "../../../global/providers/UserProvider";
import { CalendrierPageFilters } from "./Types";

const statuses = [
  SubTaskStatus.PENDING,
  SubTaskStatus.DONE,
  SubTaskStatus.SKIPPED,
  SubTaskStatus.POSTPONED,
  SubTaskStatus.REMAINDER
];

const deadlines = ["Toutes", "Echues", "Echues & En cours", "En cours", "Futures"];

type CalendriersFiltersProps = {
  viewFilter: CalendrierPageFilters;
  setViewFilter: React.Dispatch<React.SetStateAction<CalendrierPageFilters>>;
};

const CalendarsFilters = ({ viewFilter, setViewFilter }: CalendriersFiltersProps) => {
  const { data: companies } = useListCompanies();
  const { data: themes } = useListThemes();
  const { data: allCalendriers } = useListeCalendriers();
  const { data: users } = useListUsers();
  const [calendars, setCalendriers] = useState<Calendrier[]>([]);
  const currentUser = useContext(UserContext);

  useEffect(() => {
    if (allCalendriers) {
      // projette les taches suivant les choix de regroupement qui serviront a l'affichage
      setCalendriers(allCalendriers.filter((c) => c.status === CalendrierStatus.IN_PROGRESS));
    }
  }, [allCalendriers, setCalendriers]);

  const filterByCompany = (event: any) => {
    setViewFilter({ ...viewFilter, companies: event.target.value });
  };

  const filterByCalendrier = (event: any) => {
    setViewFilter({ ...viewFilter, calendars: event.target.value });
  };

  const filterByTheme = (event: any) => {
    setViewFilter({ ...viewFilter, themes: event!.target.value });
  };

  const filterByAssignedTo = (event: any) => {
    const users = event!.target.value as Array<number | undefined>;
    setViewFilter({ ...viewFilter, assignedToUsers: users });
  };

  const filterByStatus = (event: any) => {
    setViewFilter({ ...viewFilter, statuses: event.target.value });
  };

  const filterByDeadline = (event: any) => {
    setViewFilter({ ...viewFilter, deadlines: event.target.value });
  };

  const resetFilter = () => {
    setViewFilter({
      companies: [],
      calendars: [],
      themes: [],
      assignedToUsers: [],
      statuses: [],
      deadlines: "Toutes"
    });
  };

  // Plus de Filtres
  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const toggleList = () => {
    const newValue = !isFiltersOpen;
    setFiltersOpen(newValue);
    localStorage.setItem("isFiltersOpen", JSON.stringify(newValue));
  };

  useEffect(() => {
    // Vérifier si la valeur est déjà présente dans le localStorage
    const storedValue = localStorage.getItem("isFiltersOpen");
    if (storedValue) {
      setFiltersOpen(JSON.parse(storedValue));
    }
  }, []);

  return (
    <>
      <Grid container spacing={1} mt={2}>
        {isFiltersOpen && (
          <>
            {/* Calendars */}
            <Grid item xs={12} sm={6}>
              {calendars && (
                <FormControl fullWidth>
                  <InputLabel>Calendrier</InputLabel>
                  <Select label="Calendrier" multiple value={viewFilter.calendars} onChange={filterByCalendrier}>
                    {calendars.map((calendar) => (
                      <MenuItem key={calendar.id} value={calendar.id}>
                        {calendar.companyLabel} - {calendar.shortName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par calendrier</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* Société */}
            <Grid item xs={12} sm={3}>
              {companies && (
                <FormControl fullWidth>
                  <InputLabel>Société</InputLabel>
                  <Select label="Société " multiple value={viewFilter.companies} onChange={filterByCompany}>
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par société</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* Statuses */}
            <Grid item xs={12} sm={3}>
              {statuses && (
                <FormControl fullWidth>
                  <InputLabel>Statut</InputLabel>
                  <Select label="Statut" multiple value={viewFilter.statuses} onChange={filterByStatus}>
                    {statuses.map((status, index) => (
                      <MenuItem key={status} value={status}>
                        {SubTaskStatusLabels[status]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par statut</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* Deadlines */}
            <Grid item xs={12} sm={4}>
              {deadlines && (
                <FormControl fullWidth>
                  <InputLabel>Echéance</InputLabel>
                  <Select label="Echéance" value={viewFilter.deadlines} onChange={filterByDeadline}>
                    {deadlines.map((deadline, index) => (
                      <MenuItem key={deadline} value={deadline}>
                        {deadline}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par échéance</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* Themes */}
            <Grid item xs={12} sm={4}>
              {themes && (
                <FormControl fullWidth>
                  <InputLabel>Thème</InputLabel>
                  <Select label="Thème" multiple value={viewFilter.themes} onChange={filterByTheme}>
                    {themes.map((theme) => (
                      <MenuItem key={theme.id} value={theme.id}>
                        {theme.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par thème</FormHelperText>
                </FormControl>
              )}
            </Grid>

            {/* Users */}
            <Grid item xs={12} sm={4}>
              {users && (
                <FormControl fullWidth>
                  <InputLabel>Assigné à</InputLabel>
                  <Select
                    label="assignedToUsers"
                    multiple
                    value={viewFilter.assignedToUsers}
                    onChange={filterByAssignedTo}
                  >
                    <MenuItem key={"assignedToIdNull"}>Non assignée</MenuItem>
                    {users.map((user) => (
                      <MenuItem key={"assignedToId" + user.id} value={user.id}>
                        {user.login}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filtrer par utilisateur</FormHelperText>
                </FormControl>
              )}
            </Grid>
          </>
        )}
      </Grid>

      {/* Reset */}
      <Grid container mt={0} spacing={2} justifyContent="center">
        <Grid item>
          <Button
            onClick={toggleList}
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={isFiltersOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
          >
            {isFiltersOpen ? "Masquer les filtres" : "Afficher les filtres"}
          </Button>
        </Grid>

        <Grid item>
          {isFiltersOpen && (
            <Button
              color="inherit"
              variant="outlined"
              size="small"
              onClick={resetFilter}
              startIcon={<ClearRoundedIcon />}
            >
              Réinitialiser les filtres
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CalendarsFilters;
