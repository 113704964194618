import SaveIcon from "@mui/icons-material/Save";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { usePartialEditSubTaskMutation } from "../../../api/services";
import { SubTask } from "../../../api/taskTypes";

interface Values {
  executionDetails: string;
}

type DetailsFormProps = {
  subtask: SubTask;
};

const DetailsForm = ({ subtask }: DetailsFormProps) => {
  const partialEditSubTaskMutation = usePartialEditSubTaskMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      executionDetails: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    formMethods.reset({
      executionDetails: subtask?.executionDetails ?? ""
    });
  }, [subtask, formMethods]);

  const onSubmit = async (values: Values) => {
    partialEditSubTaskMutation.mutate({
      id: subtask.id,
      executionDuration: subtask.executionDuration,
      executionDetails: values.executionDetails
    });
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Controller
        name="executionDetails"
        control={formMethods.control}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            minRows={4}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" type="submit">
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </form>
  );
};
export default DetailsForm;
