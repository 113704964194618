import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { fetchGet, fetchPost, fetchPut } from "./apiConfig";
import { HasId, useToken } from "./servicesBase";
import { SubTask, SubTaskStatus, Task } from "./taskTypes";

export interface CalendrierOverview {
  nbTasks: number;
  nbSubTasks: number;
  nbSubTasksPending: number;
  nbSubTasksFinished: number;
  progress: number;
}

// TODO: code dupliqué à déplacer dans une lib globale
function b64EncodeUnicode(str: string): string {
  const uriEncoding: string = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match: string, p1: string): string {
      return String.fromCharCode(parseInt(p1, 16));
    }
  );
  return btoa(uriEncoding);
}

export const useCalendrierTasks = ({ id }: HasId) => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["cloture/calendar/tasks", id],
    (): Promise<Array<Task>> => {
      return fetchGet(`cloture/calendar/${id}/tasks`, token);
    },
    {
      enabled: token !== undefined && id !== undefined,
      onSuccess: (data) => {
        data.map(
          (task) =>
            task.subTasks?.map((subtask) => {
              if (subtask.instructions) {
                try {
                  subtask.instructions = decodeURIComponent(escape(atob(subtask.instructions)));
                } catch (e) {
                  console.error("Erreur de décodage pour la sous-tâche", subtask.id, e);
                }
              }
            })
        );
      }
    }
  );

  return { data, status };
};

export const useCalendrierOverview = ({ id }: HasId) => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["cloture/calendar/overview", id],
    (): Promise<CalendrierOverview> => {
      return fetchGet(`cloture/calendar/${id}/overview`, token);
    },
    {
      enabled: token !== undefined && id !== undefined
    }
  );

  return { data, status };
};

export interface CreateTaskRequest {
  calendarId: number;
  taskId?: number | null;
  rank?: string | null;
  themeId: number | null;
  taskLabel: string | null;
  label: string | null;
  assignedToId: number | null;
  theoricStart: number | null;
  deadline: number | null;
  estimatedDuration: number | null;
  instructions: any | null;
  executionDetails?: any | null;
  eligibleRemainder: boolean | false;
  remainder: boolean | false;
}

export const useCreateTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Task, any, CreateTaskRequest, any>(
    ({
      calendarId,
      taskId,
      rank,
      themeId,
      taskLabel,
      label,
      assignedToId,
      theoricStart,
      deadline,
      estimatedDuration,
      instructions,
      executionDetails,
      eligibleRemainder,
      remainder
    }: CreateTaskRequest) => {
      const payload = {
        calendarId,
        taskId,
        rank,
        themeId,
        taskLabel,
        label,
        assignedToId,
        theoricStart,
        deadline,
        estimatedDuration,
        instructions: instructions ? b64EncodeUnicode(instructions) : null,
        executionDetails: executionDetails ? b64EncodeUnicode(executionDetails) : null,
        eligibleRemainder,
        remainder
      };
      return fetchPost("cloture/create-task", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["cloture/calendar/tasks", variables.calendarId]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      },
      onSuccess: () => {
        toast.success("Tâche créée");
      },
      onError: () => {
        toast.error("Erreur lors de la création de la tâche");
      }
    }
  );
};

export interface UpdateTaskRequest {
  id: number;
  ancestorId?: number | null;
  label: string | null;
  calendarId: number;
  rank?: number | null;
  themeId?: number | null;
}

export const useUpdateTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Task, any, UpdateTaskRequest, any>(
    ({ id, label, calendarId, rank, ancestorId, themeId }: UpdateTaskRequest) => {
      const payload = { id, label, rank, calendarId, ancestorId, themeId };
      return fetchPut("tasks", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("tasks");
        queryCache.invalidateQueries("sub-tasks");
        queryCache.invalidateQueries(["cloture/calendar/tasks"]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
        queryCache.invalidateQueries("cloture/subtasks/in_progress");
      },
      onSuccess: () => {
        toast.success("Tâche modifiée");
      },
      onError: () => {
        toast.error("Erreur lors de la modification de la tâche");
      }
    }
  );
};

export interface UpdateSubTaskRequest {
  id: number;
  status: SubTaskStatus;
  executedOn?: Date | null;
  taskId: number | null;
  executedById?: number | null;
  label: string | null;
  rank: number | null;
  assignedToId?: number | null;
  theoricStart?: number | null;
  deadline?: number | null;
  estimatedDuration?: number | null;
  executionDuration?: number | null;
  instructions?: any;
  executionDetails?: any;
  eligibleRemainder: boolean | false;
}

export const useUpdateSubTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<SubTask, any, UpdateSubTaskRequest, any>(
    ({
      id,
      status,
      executedOn,
      taskId,
      executedById,
      label,
      rank,
      assignedToId,
      theoricStart,
      deadline,
      estimatedDuration,
      executionDuration,
      instructions,
      executionDetails,
      eligibleRemainder
    }: UpdateSubTaskRequest) => {
      const payload = {
        id,
        status,
        executedOn,
        taskId,
        executedById,
        label,
        rank,
        assignedToId,
        theoricStart,
        deadline,
        estimatedDuration,
        executionDuration,
        instructions: instructions ? b64EncodeUnicode(instructions) : null,
        executionDetails: executionDetails ? b64EncodeUnicode(executionDetails) : null,
        eligibleRemainder
      };
      return fetchPut("sub-tasks", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["sub-tasks", variables.id]);
        queryCache.invalidateQueries("cloture/calendar/tasks");
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      }
    }
  );
};

export const useSubTaskDetail = ({ id }: HasId) => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["sub-tasks", id],
    (): Promise<SubTask> => {
      return fetchGet(`sub-tasks/${id}`, token);
    },
    {
      enabled: token !== undefined && id !== undefined && id !== null,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data.instructions) {
          try {
            data.instructions = decodeURIComponent(escape(atob(data.instructions)));
          } catch (e) {
            console.error("Erreur de décodage pour la sous-tâche", data.id, e);
          }
        }
        if (data.executionDetails) {
          try {
            data.executionDetails = decodeURIComponent(escape(atob(data.executionDetails)));
          } catch (e) {
            console.error("Erreur de décodage pour la sous-tâche", data.id, e);
          }
        }
      }
    }
  );

  return { data, status };
};

export const useTaskDetail = ({ id }: HasId) => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["tasks", id],
    (): Promise<Task> => {
      return fetchGet(`tasks/${id}`, token);
    },
    {
      enabled: token !== undefined && id !== undefined && id !== null
    }
  );

  return { data, status };
};

export interface MoveTaskRequest {
  taskId: number | null | undefined;
  target: string;
}

export const useMoveTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Task, any, MoveTaskRequest, any>(
    ({ taskId, target }: MoveTaskRequest) => {
      const payload = { taskId, target };
      return fetchPost("cloture/move-task", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["tasks", variables.taskId]);
        queryCache.invalidateQueries(["cloture/calendar/tasks"]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      }
    }
  );
};

export interface MoveSubTaskRequest {
  subTaskId: number | null | undefined;
  target: string;
}

export const useMoveSubTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<SubTask, any, MoveSubTaskRequest, any>(
    ({ subTaskId, target }: MoveSubTaskRequest) => {
      const payload = { subTaskId, target };
      return fetchPost("cloture/move-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["sub-tasks"]);
        queryCache.invalidateQueries(["cloture/calendar/tasks"]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      }
    }
  );
};

export interface TranslocateTaskRequest {
  taskId: number | null | undefined;
  calendarId: number;
}

export const useTranslocateTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Task, any, TranslocateTaskRequest, any>(
    (payload: TranslocateTaskRequest) => {
      return fetchPost("cloture/translocate-task", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["tasks", variables.taskId]);
        queryCache.invalidateQueries(["cloture/calendar/tasks"]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      }
    }
  );
};

export interface TranslocateSubTaskRequest {
  subTaskId: number | null | undefined;
  taskId?: number | null;
  calendarId: number | null | undefined;
  taskLabel?: string | null;
  themeId?: number | null;
}

export const useTranslocateSubTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Task, any, TranslocateSubTaskRequest, any>(
    (payload: TranslocateSubTaskRequest) => {
      return fetchPost("cloture/translocate-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries(["tasks", variables.taskId]);
        queryCache.invalidateQueries(["cloture/calendar/tasks"]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      }
    }
  );
};

export const useDeleteSubTaskMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<void, any, HasId, any>(
    ({ id }: HasId) => {
      const payload = { id };
      return fetchPost("cloture/delete-subtask", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("task");
        queryCache.invalidateQueries("sub-tasks");
        queryCache.invalidateQueries(["cloture/calendar/tasks"]);
        queryCache.invalidateQueries("cloture/my-tasks");
        queryCache.invalidateQueries("cloture/tasks-for");
        queryCache.invalidateQueries("cloture/in_progress/tasks");
      }
    }
  );
};
