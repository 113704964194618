import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, TextField } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../../api/services";
import { InlineError } from "../../../components/ErrorUtils";

interface ResetPasswordFormData {
  mail: string;
}

export function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ResetPasswordFormData>();
  const navigate = useNavigate();

  const resetPasswordMutation = useResetPasswordMutation();

  useEffect(() => {
    if (resetPasswordMutation.status === "success") {
      navigate("/authenticate");
    }
  }, [resetPasswordMutation.status, navigate]);

  const onSubmit = async (data: ResetPasswordFormData) => {
    await resetPasswordMutation.mutate({ mail: data.mail });
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="mail"
          label="Adresse e-mail"
          name="mail"
          autoComplete="email"
          type="email"
          inputProps={register("mail", { required: "Champs requis" })}
        />

        {errors.mail && <InlineError error={errors.mail.message} />}

        <Box style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/login" sx={{ mr: 2 }}>
            Annuler
          </Button>

          <Button variant="outlined" startIcon={<SendIcon />} type="submit">
            Réinitialiser mon mot de passe
          </Button>
        </Box>
      </Box>
    </>
  );
}
