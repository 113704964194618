import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "./apiConfig";
import { HasId, useToken } from "./servicesBase";

export interface Theme extends HasId {
  id: number;
  label: string;
  description: string;
}

export interface AddThemeRequest {
  label: string;
  description: string;
}

export const useListThemes = () => {
  const { token } = useToken();
  return useQuery(
    ["themes"],
    (): Promise<Array<Theme>> => {
      return fetchGet(`themes?sort=label&page=0&size=200`, token);
    },
    {
      enabled: token !== undefined
    }
  );
};

export const useThemeDetail = ({ id }: HasId) => {
  const { token } = useToken();
  return useQuery(
    ["themes", id],
    (): Promise<Theme> => {
      return fetchGet(`themes/${id}`, token);
    },
    {
      enabled: token !== undefined && id !== null
    }
  );
};

export const useAddThemeMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Theme, any, AddThemeRequest, any>(
    ({ label, description }) => {
      const payload = { label, description };
      return fetchPost("themes", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("themes");
      },
      onSuccess: (data) => {
        toast.success('Thème "' + data.label + '" ajouté');
      },
      onError: () => {
        toast.error("Erreur lors de l'ajout du thème");
      }
    }
  );
};

export const useUpdateThemeMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<Theme, any, Theme, any>(
    ({ id, label, description }) => {
      const payload = { id, label, description };
      return fetchPut("themes", payload, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("themes");
      },
      onSuccess: (data) => {
        toast.success('Thème "' + data.label + '" modifié');
      },
      onError: () => {
        toast.error("Erreur lors de la modification du thème");
      }
    }
  );
};

export const useDeleteThemeMutation = () => {
  const queryCache = useQueryClient();
  const { token } = useToken();
  return useMutation<void, any, HasId, any>(
    ({ id }) => {
      return fetchDelete(`themes/${id}`, token);
    },
    {
      onSettled: (data, error, variables, onMutateValue) => {
        queryCache.invalidateQueries("themes");
      },
      onSuccess: () => {
        toast.success("Thème supprimé");
      },
      onError: () => {
        toast.error("Erreur lors de la suppression du thème");
      }
    }
  );
};
