import { Box, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  const theme = useTheme();

  return (
    <Box
      Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh"
      }}
    >
      <CircularProgress style={{ color: theme.palette.secondary.main }} />
    </Box>
  );
};

export default Loading;
