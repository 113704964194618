import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useResetPasswordFinishMutation } from "../../../api/services";
import { InlineError } from "../../../components/ErrorUtils";

interface PasswordResetFinishFormData {
  newpassword: string;
  confirmnewpassword: string;
}

export function PasswordResetFinish() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<PasswordResetFinishFormData>();
  const navigate = useNavigate();

  const resetPasswordFinishMutation = useResetPasswordFinishMutation();
  const [disabledSubmit] = useState(false);

  const onSubmit = async (data: PasswordResetFinishFormData) => {
    toast.error("Cette fonctionnalité n'est pas encore disponible !");
    //await resetPasswordFinishMutation.mutateAsync({ key: key, newpassword: values.newpassword });
  };

  useEffect(() => {
    if (resetPasswordFinishMutation.status === "success") {
      navigate("/authenticate");
    }
  }, [resetPasswordFinishMutation.status, navigate]);

  return (
    <>
      {resetPasswordFinishMutation.status === "error" && <InlineError error="Clé de réinitialisation invalide !" />}

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("newpassword", { required: "Champs requis" })}
          name="newpassword"
          label="Nouveau mot de passe"
          type="password"
          fullWidth
          margin="normal"
        />
        {errors.newpassword && <InlineError error={errors.newpassword.message} />}

        <TextField
          {...register("confirmnewpassword", {
            required: "Champs requis",
            validate: (value) => value === getValues("newpassword") || "La confirmation doit être identique !"
          })}
          name="confirmnewpassword"
          label="Confirmation du nouveau mot de passe"
          type="password"
          fullWidth
          margin="normal"
        />

        {errors.confirmnewpassword && <InlineError error={errors.confirmnewpassword.message} />}

        <Box style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Button variant="outlined" type="submit" disabled={resetPasswordFinishMutation.isLoading || disabledSubmit}>
            Valider
          </Button>
        </Box>
      </Box>
    </>
  );
}
