import { useQuery } from "react-query";
import { fetchGet } from "./apiConfig";
import { HasId, useToken } from "./servicesBase";

export interface User extends HasId {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
}

export const useListUsers = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["users"],
    (): Promise<Array<User>> => {
      return fetchGet(`users?sort=lastName`, token);
    },
    {
      enabled: token !== undefined
    }
  );

  return { data, status };
};
