import { Button, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCompanyDetail, useUpdateCompanyMutation } from "../../../api/services";
import { EditCompanyContext } from "./Types";

interface CompanyValues {
  label: string;
  description: string;
}

type EditCompanieFormProps = {
  editContext: EditCompanyContext;
  clearEditContext: Function;
};

const CompanieFormEdit = ({ editContext, clearEditContext }: EditCompanieFormProps) => {
  const updateCompanyMutation = useUpdateCompanyMutation();

  const { data: company } = useCompanyDetail({ id: editContext.companyId });

  const formMethods = useForm<CompanyValues>({
    defaultValues: {
      label: "",
      description: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (company) {
      formMethods.reset(company);
    }
  }, [company, formMethods]);

  const onSubmit = async (values: CompanyValues) => {
    await updateCompanyMutation.mutateAsync({
      id: company!.id,
      label: values.label,
      description: values.description
    });

    if (clearEditContext) {
      clearEditContext();
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          <Controller
            name="label"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nom"
                error={!!formMethods.formState.errors.label}
                helperText={formMethods.formState.errors?.label?.message ?? "Modifiez le nom de la société"}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="description"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                helperText="Modifiez la présentation de la société"
                multiline
                minRows={4}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Mettre à jour
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default CompanieFormEdit;
