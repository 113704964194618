import { Container, Typography, useTheme } from "@mui/material";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useInprogressCalendriersTasks } from "../../../api/services";
import { HasId } from "../../../api/servicesBase";
import { Task } from "../../../api/taskTypes";
import DialogTask from "../../../components/DialogTask";
import Loading from "../../../components/Loading";
import MainTitle from "../../../components/MainTitle";
import SubTaskView from "../../execution/components/SubTaskView";
import CalendarsFilters from "../components/CalendarsFilters";
import CalendarsTasksTableInProgress from "../components/CalendarsTasksTableInProgress";
import { CalendrierPageFilters, EditContext, EditMode } from "../components/Types";

const taskWithSubTasksFiltered = (task: Task, viewFilter: CalendrierPageFilters, maintenant: Moment): Task | null => {
  const sts = task.subTasks!.filter((subtask) => {
    return (
      (viewFilter.statuses.length === 0 || viewFilter.statuses.includes(subtask.status)) &&
      (viewFilter.assignedToUsers.length === 0 ||
        viewFilter.assignedToUsers.includes(subtask.assignedToId) ||
        (subtask.assignedToId === null && viewFilter.assignedToUsers.includes(undefined))) &&
      (viewFilter.deadlines === "Toutes" ||
        (viewFilter.deadlines === "Echues & En cours" && moment(subtask.theoricStartDate, "YYYY-MM-DD") < maintenant) ||
        (viewFilter.deadlines === "Echues" && moment(subtask.deadlineDate, "YYYY-MM-DD") < maintenant) ||
        (viewFilter.deadlines === "En cours" &&
          moment(subtask.theoricStartDate, "YYYY-MM-DD") < maintenant &&
          maintenant < moment(subtask.deadlineDate, "YYYY-MM-DD")) ||
        (viewFilter.deadlines === "Futures" && maintenant < moment(subtask.theoricStartDate, "YYYY-MM-DD")))
    );
  });
  if (sts.length === 0) {
    return null;
  }
  return { ...task, subTasks: sts };
};

const InProgressCloturesPage = () => {
  const [viewFilter, setViewFilter] = useState<CalendrierPageFilters>({
    companies: [],
    calendars: [],
    themes: [],
    assignedToUsers: [],
    statuses: [],
    deadlines: "Toutes"
  });
  const [filteredData, setFilteredData] = useState<Task[]>([]);
  const { data: calendarTasks }: { data: Task[] | undefined } = useInprogressCalendriersTasks();

  const theme = useTheme();

  useEffect(() => {
    const maintenant: Moment = moment();
    calendarTasks &&
      setFilteredData(
        calendarTasks
          .filter((task) => {
            return (
              (viewFilter.companies.length === 0 || viewFilter.companies.includes(task.companyId)) &&
              (viewFilter.calendars.length === 0 || viewFilter.calendars.includes(task.calendarId)) &&
              (viewFilter.themes.length === 0 || viewFilter.themes.includes(task.themeId))
            );
          })
          .map((task) => taskWithSubTasksFiltered(task, viewFilter, maintenant))
          .filter((task) => task != null) as Task[]
      );
  }, [viewFilter, calendarTasks]);

  const [editContext, setEditContext] = useState<EditContext>({
    editMode: EditMode.NONE
  });

  const clearEditContext = () => {
    setEditContext({ editMode: EditMode.NONE });
  };

  const editModeViewSubTask = ({ id }: HasId) => {
    if (id) {
      setEditContext({ editMode: EditMode.VIEW_SUBTASK, subTaskId: id });
    }
  };

  if (!calendarTasks) {
    return <Loading />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <MainTitle title="Suivi opérationnel">
          {calendarTasks && (
            <Typography variant="h5" component="h3" gutterBottom style={{ color: theme.palette.secondary.main }}>
              {calendarTasks.length > 1
                ? `${filteredData.length} tâches `
                : // `${viewFilter.statuses.map((status) => SubTaskStatusLabels[status].toLowerCase()).join(", ")}`
                  "Aucune tâche"}
            </Typography>
          )}
        </MainTitle>

        <CalendarsFilters viewFilter={viewFilter} setViewFilter={setViewFilter} />
      </Container>

      <CalendarsTasksTableInProgress tasks={filteredData} editModeViewSubTask={editModeViewSubTask} />

      <DialogTask title={"Sous-tâche"} onClose={clearEditContext} open={editContext.editMode === EditMode.VIEW_SUBTASK}>
        <SubTaskView editContext={editContext} clearEditContext={clearEditContext} />
      </DialogTask>
    </>
  );
};

export default InProgressCloturesPage;
