import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";

const DialogSide = (props) => {
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      props.onClose();
    }

    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };

  // const handleConfirm = () => {
  //   if (typeof props.submitFunction === "function") {
  //     props.submitFunction();
  //   }
  // };

  // const onNewSubmit = () => {
  //   console.log("onNewSubmit");
  // };

  const theme = useTheme();

  // TODO: style temporaire
  const dialogStyle = {
    display: "flex",
    justifyContent: "flex-end"
  };

  const dialogTitleStyle = {
    backgroundColor: theme.palette.secondary.main,
    width: 600
  };

  const titleStyle = {
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  };

  const dialogContentStyle = {
    width: 600,
    border: "1px solid white"
  };

  const dialogActionsStyle = {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    justifyContent: "space-between",
    padding: 20
  };

  return (
    <Dialog fullScreen open={props.open} onClose={handleClose} style={dialogStyle}>
      <DialogTitle id="scroll-dialog-title" style={dialogTitleStyle}>
        <div style={titleStyle}>
          <Typography componant="h2" variant="h5">
            {props.title}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={props.onClose}
            style={{ color: theme.palette.grey[100] }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent
        style={dialogContentStyle}
        // onNewSubmit={() => {
        //   console.log("Editer onNewSubmit");
        // }}
      >
        {props.children}
      </DialogContent>

      <DialogActions style={dialogActionsStyle}>
        <Button variant="outlined" onClick={handleClose}>
          Annuler
        </Button>

        {/* {props.dialogAction && (
          <Button variant="outlined" autoFocus onClick={props.onSubmit}>
            {props.dialogAction}
          </Button>
        )} */}
      </DialogActions>
    </Dialog>
  );
};

export default DialogSide;
