import { TextField } from "@mui/material";
import { Controller, FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

interface ControllerSelectProps<T extends FieldValues> {
  formMethods: UseFormReturn<T, any, undefined>;
  mappedMenuItems: JSX.Element[];
  name: FieldPath<T>;
  label: string;
  required: boolean;
  helperText: string;
  id: string;
}

const ControllerSelect = <T extends FieldValues>({
  formMethods,
  mappedMenuItems,
  name,
  label,
  required,
  helperText,
  id
}: ControllerSelectProps<T>) => {
  return (
    <Controller
      name={name}
      control={formMethods.control}
      rules={required ? { required: { value: true, message: "Champ Requis" } } : {}}
      render={({ field }) => (
        <TextField
          {...field}
          id={id}
          label={label}
          select
          fullWidth
          required={required}
          error={!!formMethods.formState.errors?.[name]}
          helperText={
            required && !!formMethods.formState.errors?.[name]
              ? formMethods.formState.errors?.[name]?.message?.toString()
              : helperText
          }
        >
          {mappedMenuItems}
        </TextField>
      )}
    />
  );
};

ControllerSelect.defaultProps = {
  required: false,
  helperText: "",
  id: ""
};

export default ControllerSelect;
