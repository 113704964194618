import { CssBaseline } from "@mui/material";
import mermaid from "mermaid";
import TagManager from "react-gtm-module";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { queryClientConfig } from "./api/apiConfig";
import { UserProvider } from "./global/providers/UserProvider";
import routes from "./routes";
import "./styles/styles.css";

// Configuration Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-WXFKMDZ"
};

TagManager.initialize(tagManagerArgs);

const queryClientInstance = queryClientConfig();

mermaid.initialize({
  startOnLoad: true
});

function App() {
  return (
    <QueryClientProvider client={queryClientInstance}>
      <UserProvider>
        <CssBaseline />
        <Router>{routes}</Router>
        <ReactQueryDevtools initialIsOpen />
        <ToastContainer />
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
