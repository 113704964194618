import { Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAddCompanyMutation } from "../../../api/services";

interface CompanyValues {
  label: string;
  description: string;
}

interface AddCompanieFormProps {
  clearEditContext: Function;
}

const CompanieFormAdd = ({ clearEditContext }: AddCompanieFormProps) => {
  const addCompanyMutation = useAddCompanyMutation();

  // const { register, handleSubmit } = useForm<CompanyValues>();
  const formMethods = useForm<CompanyValues>({
    defaultValues: {
      label: "",
      description: ""
    },
    mode: "onChange"
  });

  const onSubmit = async (data: CompanyValues) => {
    await addCompanyMutation.mutateAsync({
      label: data.label,
      description: data.description
    });

    if (clearEditContext) clearEditContext();
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          {/* <TextField {...register("label")} label="Nom" fullWidth /> */}
          <Controller
            name="label"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nom"
                error={!!formMethods.formState.errors.label}
                helperText={
                  formMethods.formState.errors?.label?.message ?? "Veuillez saisir le nom de la nouvelle société"
                }
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="description"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                helperText="Veuillez saisir la description de la nouvelle société"
                multiline
                minRows={4}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Ajouter
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default CompanieFormAdd;
