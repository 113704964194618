import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Calendrier, Theme, User } from "../../../api/services";

export interface SelectData {
  label: string;
  value: string | number;
  disabled?: boolean;
}

export const defaultData = [{ label: "Non spécifié", value: "" }] as SelectData[];

export const useUsersWithUnspecified = (users: User[] | undefined): SelectData[] => {
  const [availableUsers, setAvailableUsers] = React.useState<SelectData[]>(defaultData);
  useEffect(() => {
    if (users) {
      setAvailableUsers(
        defaultData.concat(
          users.map((user) => {
            return { label: user.login, value: user.id };
          })
        )
      );
    }
  }, [users, setAvailableUsers]);
  return availableUsers;
};

export const useThemesWithUnspecified = (themes: Theme[] | undefined): SelectData[] => {
  const [availableThemes, setAvailableThemes] = React.useState<SelectData[]>(defaultData);
  useEffect(() => {
    if (themes) {
      setAvailableThemes(
        defaultData.concat(
          themes.map((theme) => {
            return { label: theme.label, value: theme.id };
          })
        )
      );
    }
  }, [themes, setAvailableThemes]);
  return availableThemes;
};

export const useCalendarsWithUnspecified = (calendars: Calendrier[] | undefined): SelectData[] => {
  const [availableCalendriers, setAvailableCalendriers] = React.useState<SelectData[]>(defaultData);
  useEffect(() => {
    if (calendars) {
      setAvailableCalendriers(
        defaultData.concat(
          calendars.map((calendars) => {
            return { label: calendars.shortName, value: calendars.id };
          })
        )
      );
    }
  }, [calendars, setAvailableCalendriers]);
  return availableCalendriers;
};

/** un hook pour récupérer un élément d'url sous forme de number
 * (basé sur react-router useParams)
 */
export const useNumericParam = (name: string): number | undefined => {
  const p: { [name: string]: string | undefined } = useParams();
  const v = p.calendarId ? parseInt(p.calendarId) : undefined;
  return v && !isNaN(v) ? v : undefined;
};
