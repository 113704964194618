import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import mermaid from "mermaid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Mermaid = ({ chart, id }) => {
  useEffect(() => {
    const config = {
      startOnLoad: true
    };
    mermaid.initialize(config);
    mermaid.contentLoaded();
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(`/preparation/calendars/${id}`);
  };

  const handleGoToCalendarsList = () => {
    navigate("/preparation/calendars");
  };

  return (
    <>
      {/* Table actions */}
      <Box display="flex" justifyContent="space-evenly" p={2}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={handleGoBack}>
          Retour au calendrier
        </Button>

        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={handleGoToCalendarsList}>
          Retour à la liste
        </Button>
      </Box>

      <div className="mermaid">{chart}</div>
    </>
  );
};

export default Mermaid;
