import { Alert, Button, Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";

import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCalendrierDetail, useListCompanies, useUpdateCalendrierMutation } from "../../../api/services";
import { dateYYYYMMDDFormatter } from "../../../api/tasksUtils";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { EditCalendrierContext } from "./Types";

interface Values {
  shortName: string;
  longName: string;
  dateJ: Date | "";
  companyId: number | "";
}

type EditCalendrierFormProps = {
  editContext: EditCalendrierContext;
  clearEditContext: Function;
};
const CalendarFormEdit = ({ editContext, clearEditContext }: EditCalendrierFormProps) => {
  const { data: calendar } = useCalendrierDetail({ id: editContext.calendarId });
  const { data: companies } = useListCompanies();

  const updateCalendrierMutation = useUpdateCalendrierMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      shortName: "",
      longName: "",
      dateJ: undefined,
      companyId: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (calendar) {
      formMethods.reset({
        shortName: calendar.shortName,
        longName: calendar.longName,
        dateJ: calendar.dateJ,
        companyId: calendar.companyId
      });
    }
  }, [calendar, formMethods]);

  const onSubmit = async (values: Values) => {
    if (values.companyId === "") {
      toast.error("Veuillez choisir une société.");
      return;
    }

    if (calendar !== undefined) {
      await updateCalendrierMutation.mutateAsync({
        id: calendar.id,
        shortName: values.shortName,
        longName: values.longName,
        status: calendar.status,
        dateJ: dateYYYYMMDDFormatter(values.dateJ),
        ancestorId: calendar.ancestorId,
        companyId: values.companyId
      });

      if (clearEditContext) {
        clearEditContext();
      }
    }
  };
  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12}>
          <Controller
            name="shortName"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nom court"
                error={!!formMethods.formState.errors.shortName}
                helperText={formMethods.formState.errors?.shortName?.message ?? "Saisir le nom abrégé du calendrier."}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="longName"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                error={!!formMethods.formState.errors.longName}
                helperText={formMethods.formState.errors?.longName?.message ?? "Saisir une description au calendrier."}
                fullWidth
                required
              />
            )}
          />
        </Grid>

        {companies && (
          <Grid item xs={12} sm={6}>
            <ControllerSelect
              name="companyId"
              formMethods={formMethods}
              required
              label="Société"
              helperText="Sélectionnez la société rattachée"
              mappedMenuItems={companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.label}
                </MenuItem>
              ))}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Controller
            control={formMethods.control}
            name="dateJ"
            render={({ field: { onChange, value, name, ref } }) => {
              const valueDate = value ? new Date(value) : null;
              return (
                <DatePicker
                  value={valueDate}
                  label="Date J"
                  onChange={onChange}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      helperText: formMethods.formState.errors?.dateJ?.message ?? "Sélectionnez une date J."
                    }
                  }}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Alert severity="info">
            <strong>Notes : </strong> Toutes les tâches seront ensuite calculée en fonction du nombre de jours avant ou
            après la <strong>date J</strong>.
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit">
            Mettre à jour
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default CalendarFormEdit;
