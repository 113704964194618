import { useQuery } from "react-query";
import { fetchGet } from "./apiConfig";
import { useToken } from "./servicesBase";
import { SubTaskV, Task } from "./taskTypes";

export const useInprogressCalendriersTasks = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["cloture/in_progress/tasks"],
    (): Promise<Array<Task>> => {
      return fetchGet("cloture/in_progress/tasks", token);
    },
    {
      enabled: token !== undefined,
      refetchOnWindowFocus: true
    }
  );

  return { data, status };
};

export const useMyTasks = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    "cloture/my-tasks",
    (): Promise<Array<SubTaskV>> => {
      return fetchGet("cloture/my-tasks", token);
    },
    {
      enabled: token !== undefined
    }
  );

  return { tasks: data, status };
};

export const useTasksFor = (login: string | null | undefined) => {
  const { token } = useToken();
  const { data, status } = useQuery(
    ["cloture/tasks-for", login],
    (): Promise<Array<SubTaskV>> => {
      return fetchGet(`cloture/tasks-for/${login}`, token);
    },
    {
      enabled: token !== undefined && login !== undefined && login !== null
    }
  );

  return { tasks: data, status };
};

export const useTasks = () => {
  const { token } = useToken();
  const { data, status } = useQuery(
    "cloture/subtasks/in_progress",
    (): Promise<Array<SubTaskV>> => {
      return fetchGet("cloture/subtasks/in_progress", token);
    },
    {
      enabled: token !== undefined,
      onSuccess: (data) => {
        data.map((subtask) => {
          if (subtask.executionDetails) {
            try {
              subtask.executionDetails = decodeURIComponent(escape(atob(subtask.executionDetails)));
            } catch (e) {
              console.error("Erreur de décodage pour la sous-tâche", subtask.id, e);
            }
          }
        });
      }
    }
  );

  return { tasks: data, status };
};
