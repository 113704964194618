export enum SubTaskStatus {
  PENDING = "PENDING",
  DONE = "DONE",
  SKIPPED = "SKIPPED",
  POSTPONED = "POSTPONED",
  REMAINDER = "REMAINDER"
}

export const SubTaskStatusLabels = {
  [SubTaskStatus.PENDING]: "En attente",
  [SubTaskStatus.DONE]: "Fait",
  [SubTaskStatus.SKIPPED]: "Non applicable",
  [SubTaskStatus.POSTPONED]: "Terminé avec le reliquat",
  [SubTaskStatus.REMAINDER]: "Restant"
};

export interface SubTask {
  id: number;
  label: string;
  instructions?: string | null;
  rank: number;
  theoricStart?: number | null;
  theoricStartDate?: Date | null;
  deadline?: number | null;
  deadlineDate?: Date | null;
  executionDetails?: string | null;
  estimatedDuration?: number | null;
  executionDuration?: number | null;
  status: SubTaskStatus;
  executedOn?: Date | null;
  assignedToId?: number;
  assignedToLogin?: string;
  executedById?: number | null;
  executedByLogin?: string | null;
  taskId: number;
  taskLabel?: string;
  companyId: number;
  companyLabel: string;
  calendarId: number;
  calendarShortName: string;
  ancestorId?: number | null;
  themeId: number;
  themeLabel: string;
  eligibleRemainder: boolean | false;
}

export interface Task {
  id: number;
  ancestorId?: number | null;
  label: string;
  companyId: number;
  companyLabel: string;
  calendarId: number;
  calendarShortName: string;
  themeId: number;
  themeLabel: string;
  rank: number;
  subTasks?: SubTask[];
  theoricStart?: number;
  theoricStartDate?: Date;
  deadline?: number;
  deadlineDate?: Date;
  avancement?: number;
  nbSubTasks?: number;
  estimatedDuration?: number;
}

export interface ProjectedTask {
  id: number;
  subtaskId?: number;
  displayType?: string;
  subtaskLabel?: string;
  label: string;
  instructions?: string | null;
  rank: number;
  theoricStart?: number;
  theoricStartDate?: Date;
  deadline?: number;
  deadlineDate?: Date;
  executionDetails?: any;
  estimatedDuration?: number;
  executionDuration?: number;
  status?: SubTaskStatus;
  executedOn?: Date;
  assignedToId?: number;
  assignedToLogin?: string;
  executedById?: number;
  executedByLogin?: string;
  avancement?: number;
  taskId?: number;
  taskLabel?: string;
  companyId: number;
  companyLabel: string;
  calendarId: number;
  calendarShortName: string;
  ancestorId?: number;
  themeId: number;
  themeLabel: string;
  nbSubTasks?: number;
}

export interface SubTaskV {
  id: number;
  label: string;
  instructions?: string | null;
  rank: number;
  theoricStart: number;
  theoricStartDate: Date;
  deadline: number;
  deadlineDate: Date;
  executionDetails?: string | null;
  estimatedDuration?: number;
  executionDuration?: number;
  status: SubTaskStatus;
  executedOn?: Date;
  assignedToId?: number;
  assignedToLogin?: string;
  executedById?: number;
  executedByLogin?: string;
  taskId: number;
  taskLabel: string;
  companyId: number;
  companyLabel: string;
  calendarId: number;
  calendarShortName: string;
  ancestorId?: number;
  themeId?: number;
  themeLabel?: string;
  eligibleRemainder: boolean | false;
}
