import { useContext } from "react";

import { UserContext, UserContextInterface } from "../global/providers/UserProvider";

export interface HasId {
  id: number | null | undefined;
}
export const sleep = (ms: number | undefined) => new Promise((resolve) => setTimeout(resolve, ms));

export interface UseTokenResult {
  token?: string | null;
  setToken: (value: string | null) => void;
}
export const useToken = (): UseTokenResult => {
  const userContext = useContext<UserContextInterface | null>(UserContext);
  const setStoredValue = (value: string | null) => {
    if (value) {
      localStorage.setItem("token", value);
      userContext!.setToken(value);
    } else {
      localStorage.removeItem("token");
      userContext!.setToken(null);
      userContext!.setUser(null);
    }
  };
  return { token: userContext!.token, setToken: setStoredValue };
};
