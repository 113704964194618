import { Button, FormControlLabel, Grid, InputAdornment, MenuItem, Switch, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateTaskMutation, useListThemes, useListUsers } from "../../../api/services";
import { durationFormatter, parseDuration } from "../../../api/tasksUtils";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { useThemesWithUnspecified, useUsersWithUnspecified } from "./Hooks";
import { EditTaskContext, EditTaskMode } from "./Types";

interface formValues {
  rank: string;
  themeId: number | "";
  taskLabel: string;
  label: string;
  instructions: string;
  assignedToId: number | "";
  // These are strings because TextField always return a string, even when type="number" is specified
  theoricStart: string;
  deadline: string;
  estimatedDuration: string;
  eligibleRemainder: boolean;
}

interface AddTaskFormProps {
  editContext: EditTaskContext;
  clearEditContext: Function;
}

const TaskFormAdd = ({ editContext, clearEditContext }: AddTaskFormProps) => {
  const { data: users } = useListUsers();
  const availableUsers = useUsersWithUnspecified(users);
  const { data: themes } = useListThemes();
  const availableThemes = useThemesWithUnspecified(themes);

  const createTaskMutation = useCreateTaskMutation();

  const formMethods = useForm<formValues>({
    defaultValues: {
      rank: "",
      themeId: "",
      taskLabel: "",
      label: "",
      instructions: "",
      assignedToId: "",
      theoricStart: "",
      deadline: "",
      estimatedDuration: "",
      eligibleRemainder: false
    },
    mode: "onChange"
  });

  useEffect(() => {
    if (editContext) {
      formMethods.setValue("eligibleRemainder", editContext.editMode === EditTaskMode.ADD_SUBTASK);
    }
  }, [editContext, formMethods]);

  const onSubmit = async (values: formValues) => {
    if (!editContext.calendarId) {
      return;
    }

    const theoricStart = values.theoricStart === "" ? null : parseInt(values.theoricStart);
    const deadline = values.deadline === "" ? null : parseInt(values.deadline);

    if (theoricStart !== null && deadline !== null && theoricStart !== undefined && deadline !== undefined) {
      if (deadline < theoricStart) {
        toast.error("Erreur : La date de début doit être avant la Date butoir");
        return;
      }
    }

    await createTaskMutation.mutateAsync({
      calendarId: editContext.calendarId,
      taskId: editContext.parentTaskId,
      themeId: values.themeId === "" ? null : values.themeId,
      taskLabel: values.taskLabel,
      label: values.label,
      assignedToId: values.assignedToId === "" ? null : values.assignedToId,
      theoricStart: theoricStart,
      deadline: deadline,
      estimatedDuration: parseDuration(values.estimatedDuration),
      instructions: values.instructions,
      eligibleRemainder: values.eligibleRemainder,
      remainder: false
    });

    if (clearEditContext) {
      clearEditContext();
    }
  };
  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4} mt={1}>
        {editContext.editMode === EditTaskMode.ADD_TASK && themes && (
          <Grid item xs={12}>
            <ControllerSelect
              name="themeId"
              formMethods={formMethods}
              id="themeId"
              label="Thème"
              mappedMenuItems={availableThemes.map((theme, index) => (
                <MenuItem key={theme.value} value={theme.value}>
                  {theme.label}
                </MenuItem>
              ))}
            />
          </Grid>
        )}

        {editContext.editMode === EditTaskMode.ADD_TASK && (
          <Grid item xs={12}>
            <Controller
              name="taskLabel"
              control={formMethods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Tache"
                  helperText={
                    formMethods.formState.errors?.taskLabel?.message ??
                    "Si vous laissez ce champ vide, la tache prendra le même libellé que la sous-tâche"
                  }
                  fullWidth
                  focused
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Controller
            name="label"
            control={formMethods.control}
            rules={{ required: { value: true, message: "Champ Requis" } }}
            render={({ field }) => (
              <TextField
                {...field}
                id="label"
                label="Sous-tâche"
                error={!!formMethods.formState.errors.label}
                helperText={formMethods.formState.errors?.label?.message ?? "Nom de la sous-tâche"}
                required
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="instructions"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                id="instructions"
                label="Instructions"
                error={!!formMethods.formState.errors.instructions}
                helperText={
                  formMethods.formState.errors?.instructions?.message ??
                  "Saisissez ici toutes les instructions pour réaliser cette sous-tâche"
                }
                multiline
                minRows={4}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          {users && (
            <ControllerSelect
              name="assignedToId"
              formMethods={formMethods}
              id="assignedToId"
              label="Assigné à"
              mappedMenuItems={availableUsers.map((user, index) => (
                <MenuItem key={user.value} value={user.value}>
                  {user.label}
                </MenuItem>
              ))}
            />
          )}
        </Grid>

        {/* Si aucune date J */}
        <Grid item xs={6}>
          <Controller
            name="theoricStart"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date début"
                type="number"
                helperText="En jours par rapport à J"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">jours</InputAdornment>
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="deadline"
            control={formMethods.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date butoir"
                type="number"
                helperText="En jours par rapport à J"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">jours</InputAdornment>
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="estimatedDuration"
            control={formMethods.control}
            rules={{
              validate: {
                value: (value) => {
                  if (value) {
                    const duration = durationFormatter(parseDuration(value));
                    if (!duration) {
                      return "Durée invalide";
                    }
                  }
                  return true;
                }
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Durée estimée"
                type="text"
                error={!!formMethods.formState.errors.estimatedDuration}
                helperText={
                  formMethods.formState.errors?.estimatedDuration?.message ??
                  "En heures ou minutes : 01h30m, 01h ou 30m"
                }
                sx={{
                  "& .MuiFormHelperText-root": {
                    whiteSpace: "nowrap"
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="eligibleRemainder"
            control={formMethods.control}
            render={({ field }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label="Eligible au reliquat" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" type="submit" disabled={formMethods.formState.isSubmitting}>
            Créer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default TaskFormAdd;
