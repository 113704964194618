import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  useTheme
} from "@mui/material";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function ConfirmPopIn(props) {
  const theme = useTheme();

  // TODO: style temporaire
  const dialogStyle = {
    border: "1px solid red"
  };

  const dialogTitleStyle = {
    backgroundColor: theme.palette.secondary.main,
    cursor: "move",
    marginBottom: 16,
    width: 600
  };

  const titleStyle = {
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  };

  const dialogContentStyle = {
    minHeight: "4Ovh"
  };

  const dialogActionsStyle = {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    justifyContent: "space-between",
    padding: 20
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      style={dialogStyle}
    >
      <DialogTitle id="draggable-dialog-title" style={dialogTitleStyle}>
        <div style={titleStyle}>
          {props.dialogTitle}
          <IconButton
            aria-label="close"
            style={{ color: theme.palette.grey[100] }}
            onClick={props.onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent style={dialogContentStyle}>
        <DialogContentText mt={2} mb={2}>
          {props.dialogContent}
        </DialogContentText>
      </DialogContent>

      <DialogActions style={dialogActionsStyle}>
        <Button variant="outlined" onClick={props.onClose}>
          Annuler
        </Button>

        {props.dialogAction && (
          <Button variant="contained" onClick={props.onConfirm}>
            {props.dialogAction}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
