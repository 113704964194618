import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditRoundedIcon from "@mui/icons-material/Edit";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { GridHeaderFilterCellProps } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { Theme, useDeleteThemeMutation } from "../../../api/services";
import ConfirmPopIn from "../../../components/DialogConfirm";
import DialogSide from "../../../components/DialogSide";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import ThemeFormEdit from "./ThemeFormEdit";
import { CrudEditMode, EditThemeContext } from "./Types";

type ThemesTableProps = {
  themes: Theme[];
};

const ThemesTable = ({ themes }: ThemesTableProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [editContext, setEditContext] = useState<EditThemeContext>({ editMode: CrudEditMode.NONE, themeId: null });

  const deleteThemeMutation = useDeleteThemeMutation();

  const clearEditContext = () => {
    setEditContext({ editMode: CrudEditMode.NONE, themeId: null });
  };

  const editModeEditTheme = (themeId: number) => {
    setEditContext({ editMode: CrudEditMode.EDIT, themeId });
  };

  const editModeDeleteTheme = (themeId: number) => {
    setEditContext({ editMode: CrudEditMode.DEL, themeId });
  };

  const doDeleteTheme = async () => {
    if (editContext.themeId !== null) {
      await deleteThemeMutation.mutateAsync({ id: editContext.themeId });
    }
    clearEditContext();
  };

  const columns = [
    {
      field: "label",
      headerName: "Thème",
      minWidth: 400,
      renderCell: (params: { row: Theme }) => {
        return (
          <Stack direction="row" spacing={2}>
            <LocalOfferIcon fontSize="small" />
            <strong>{params.row.label}</strong>
          </Stack>
        );
      }
    },
    {
      field: "description",
      headerName: "Description",
      filterable: true,
      minWidth: 200,
      flex: 1
    },
    {
      field: "actions",
      headerName: "Actions",
      disableReorder: true,
      width: 110,
      resizable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: { row: Theme }) => {
        const onClickEdit = () => {
          editModeEditTheme(params.row.id);
        };
        const onClickDelete = () => {
          editModeDeleteTheme(params.row.id);
        };

        return (
          <>
            <Tooltip title="Editer le theme">
              <IconButton onClick={onClickEdit} size="large">
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Supprimer le theme">
              <IconButton onClick={onClickDelete} size="large">
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  ];

  const rows = themes;

  return (
    <>
      <DataGrid
        datagridKey="ThemesTable"
        // disableColumnFilter
        // disableColumnSelector
        disableDensitySelector
        rows={rows}
        columns={columns}
        initialState={{
          pinnedColumns: { right: ["actions"] },
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          },
          sorting: {
            sortModel: [{ field: "label", sort: "asc" }]
          }
        }}
        pageSizeOptions={[10, 20, 50]}
      />

      {/* {themes && (
        <details>
          <summary>Themes</summary>
          <pre>
            <code>{JSON.stringify(themes, null, 2)}</code>
          </pre>
        </details>
      )} */}

      {/* Dialogs */}
      <>
        <ConfirmPopIn
          open={editContext.editMode === CrudEditMode.DEL}
          onClose={clearEditContext}
          onConfirm={() => doDeleteTheme()}
          title={"Veuillez confirmer la suppression de ce thème"}
          dialogContent={
            "Attention, cette opération est irréversible et ne peut réussir que si aucune tâche ne lui est associée"
          }
          dialogAction={"Confirmer"}
        />

        <DialogSide
          open={editContext.editMode === CrudEditMode.EDIT}
          onClose={clearEditContext}
          title={"Editer le theme"}
          dialogAction={"dialogAction"}
        >
          <ThemeFormEdit editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default ThemesTable;
