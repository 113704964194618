// import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro";

function CustomNoRowsOverlay() {
  return (
    <GridOverlay>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {/* <InfoIcon color="disabled" style={{ fontSize: 48, marginBottom: "8px" }} /> */}
        <Typography variant="h6">Aucune tâche !</Typography>
        <Typography variant="p">Filtrez les données pour voir plus de tâches.</Typography>
      </div>
    </GridOverlay>
  );
}

export default CustomNoRowsOverlay;
