import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CropFreeIcon from "@mui/icons-material/CropFree";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditRoundedIcon from "@mui/icons-material/Edit";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
// import ListIcon from "@mui/icons-material/List";
import InfoIcon from "@mui/icons-material/Info";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { Box, Button, Chip, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { GridHeaderFilterCellProps } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import {
  Calendrier,
  useDeleteSubTaskMutation,
  useMoveSubTaskMutation,
  useMoveTaskMutation
} from "../../../api/services";
import { ProjectedTask, SubTaskStatusLabels, Task } from "../../../api/taskTypes";
import { durationFormatter, projectTasks, relatedToDateJFormatter } from "../../../api/tasksUtils";
import DialogSide from "../../../components/DialogSide";
import DataGrid from "../../../components/datagrid/DataGrid";
import RowToolbar from "../../../components/datagrid/RowToolbar";
import RowToolbarButton from "../../../components/datagrid/RowToolbarButton";
import CalendarFormEdit from "./CalendarFormEdit";
import { EditCalendrierContext, EditCalendrierMode } from "./Types";

function cellClassHandler() {
  return (params: RowsOfProjectedTasks) => (params.row.displayType === "SUBTASK" ? "task sub" : "task group");
}

interface RowsOfProjectedTasks {
  row: ProjectedTask;
}

type CalendrierTasksTableProps = {
  calendar: Calendrier;
  tasks: Task[];
  editModeAddTask: Function;
  editModeAddSubTaskTo: Function;
  editModeEditTask: Function;
  editModeEditSubTask: Function;
  editModeTranslocateTask: Function;
  editModeTranslocateSubTask: Function;
};

const CalendarTasksTable = ({
  calendar,
  tasks,
  editModeAddTask,
  editModeAddSubTaskTo,
  editModeEditTask,
  editModeEditSubTask,
  editModeTranslocateTask,
  editModeTranslocateSubTask
}: CalendrierTasksTableProps) => {
  //fixme
  // const appbarSlot = document.getElementById("appbar-slot");
  const navigate = useNavigate();

  const [groupedTaskIds, setGroupedTaskIds] = useState<(number | null | undefined)[]>([]);
  const [rows, setRows] = useState<ProjectedTask[]>([]);
  const moveTaskMutation = useMoveTaskMutation();
  const moveSubTaskMutation = useMoveSubTaskMutation();
  const deleteSubTaskMutation = useDeleteSubTaskMutation();
  const [focusedTaskId, setFocusedTaskId] = useState<number | null | undefined>(null);

  const groupAll = useCallback(() => {
    const ids: number[] = tasks.filter((t) => t.subTasks!.length >= 1).map((t) => t.id);

    setGroupedTaskIds([...ids]);
  }, [tasks]);

  useEffect(() => {
    if (tasks && !groupedTaskIds) {
      // projette les taches suivant les choix de regroupement qui serviront a l'affichage
      groupAll();
    }
  }, [tasks, groupedTaskIds, setGroupedTaskIds, groupAll]);

  useEffect(() => {
    if (tasks && !groupedTaskIds) {
      // projette les taches suivant les choix de regroupement qui serviront a l'affichage
      groupAll();
    }
  }, [tasks, groupedTaskIds, setGroupedTaskIds, groupAll]);

  useEffect(() => {
    if (tasks && groupedTaskIds) {
      // projette les taches suivant les choix de regroupement qui serviront a l'affichage
      setRows(projectTasks(tasks, groupedTaskIds, focusedTaskId));
    }
  }, [tasks, groupedTaskIds, focusedTaskId, setRows]);

  function groupByTaskId(taskId: number | number | undefined) {
    setGroupedTaskIds([...groupedTaskIds, taskId]);
  }

  function ungroupByTaskId(taskId: number | number | undefined) {
    setGroupedTaskIds(groupedTaskIds!.filter((i) => i !== taskId));
  }

  function ungroupAll() {
    setGroupedTaskIds([]);
  }

  const exportFile = () => {
    const data = projectTasks(tasks, [], null)
      .sort((a, b) => a.rank - b.rank)
      .map((row) => {
        return [
          row.calendarShortName,
          row.companyLabel,
          row.status,
          row.themeLabel,
          row.taskLabel,
          row.subtaskLabel,
          row.assignedToLogin,
          row.theoricStart,
          row.deadline,
          durationFormatter(row.estimatedDuration)
        ];
      });

    data.unshift([
      "Calendrier",
      "Société",
      "Statut",
      "Theme",
      "Tâche",
      "Sous-Tâche",
      "Acteur",
      "Date début",
      "Date butoir",
      "Durée estimée"
    ]);

    /* convert state to workbook */
    const ws = utils.aoa_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Preparation");

    /* generate XLSX file and send to client */
    writeFile(wb, "cloturer-preparation.xlsx");
  };

  const sortModel = [{ field: "rank" }];

  const columns = [
    {
      field: "group",
      headerName: "",
      width: 40,
      minWidth: 40,
      resizable: false,
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        const onUngroup = () => {
          ungroupByTaskId(params.row.taskId);
        };

        const onGroup = () => {
          groupByTaskId(params.row.taskId);
        };
        return (
          <Box>
            {params.row.displayType === "GROUPED_TASK" && !focusedTaskId && (
              <Tooltip title="Afficher les sous-tâches">
                <IconButton onClick={onUngroup} size="large">
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            )}

            {params.row.displayType === "UNGROUPED_TASK" && !focusedTaskId && (
              <Tooltip title="Masquer les sous-tâches">
                <IconButton onClick={onGroup} size="large">
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      }
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        if (params.row.status != null && params.row.status != null)
          return (
            <>
              <Chip
                label={SubTaskStatusLabels[params.row.status]}
                color={
                  SubTaskStatusLabels[params.row.status] === "Fait"
                    ? "success"
                    : SubTaskStatusLabels[params.row.status] === "En attente"
                      ? "warning"
                      : "default"
                }
                variant="outlined"
                size="small"
              />
            </>
          );
      }
    },
    {
      field: "themeLabel",
      headerName: "Thème",
      minWidth: 250,
      // hide: true,
      renderCell: (params: RowsOfProjectedTasks) => {
        return <>{editModeEditTask && params.row.displayType !== "SUBTASK" && <span>{params.row.themeLabel}</span>}</>;
      }
    },
    {
      field: "taskLabel",
      headerName: "Tâche",
      minWidth: 400,
      // hide: true,
      // ### Calucl du nombre de Sous-Tâches
      // renderCell: (params: RowsOfProjectedTasks) => {
      //   const taskId = params.row.taskId;
      //   const subtasks = rows.filter((row) => row.taskId === taskId && row.displayType === "SUBTASK");
      //   return subtasks.length;
      // },
      renderCell: (params: RowsOfProjectedTasks) => {
        const onClickEditTask = () => {
          editModeEditTask(params.row.taskId);
        };

        return (
          <>
            {editModeEditTask && params.row.displayType !== "SUBTASK" && (
              <>
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  onClick={onClickEditTask}
                  className="truncate"
                >
                  <span className="clickable">{params.row.label}</span>
                </Typography>
              </>
            )}
          </>
        );
      }
    },
    {
      field: "label",
      headerName: "Sous-tâche",
      minWidth: 500,
      flex: 1,
      sortable: true,
      renderCell: (params: RowsOfProjectedTasks) => {
        const onClickEditSubTask = () => {
          editModeEditSubTask(params.row.subtaskId);
        };

        const onFocusTask = () => {
          setFocusedTaskId(params.row.taskId);
        };

        const onUnfocusTask = () => {
          setFocusedTaskId(null);
        };

        return (
          <>
            {editModeEditTask && params.row.displayType !== "SUBTASK" && (
              <>
                {params.row.displayType === "UNGROUPED_TASK" && !focusedTaskId && params.row.nbSubTasks && (
                  <Tooltip title="Focus sur la tâche">
                    <Chip label={`${params.row.nbSubTasks}`} color="secondary" size="small" onClick={onFocusTask} />
                  </Tooltip>
                )}

                {params.row.displayType === "UNGROUPED_TASK" && focusedTaskId && (
                  <Tooltip title="Enlever le focus sur la tâche">
                    <Chip
                      label={`${params.row.nbSubTasks}`}
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={onUnfocusTask}
                    />
                  </Tooltip>
                )}

                {params.row.displayType === "GROUPED_TASK" && <Chip label={`${params.row.nbSubTasks}`} size="small" />}
              </>
            )}

            {editModeEditSubTask &&
              (params.row.displayType === "SUBTASK" || params.row.displayType === "SINGLE_STASK") && (
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  onClick={onClickEditSubTask}
                  className="truncate"
                >
                  - <span className="clickable">{params.row.label}</span>
                </Typography>
              )}
          </>
        );
      }
    },
    {
      field: "instructions",
      headerName: "",
      minWidth: 50,
      resizable: false,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        const onClickEditSubTask = () => {
          editModeEditSubTask(params.row.subtaskId);
        };

        return (
          <>
            {params.row.displayType === "SUBTASK" && params.row.instructions !== null && !focusedTaskId && (
              <Tooltip title={params.row.instructions}>
                <InfoIcon fontSize="small" color="warning" />
              </Tooltip>
            )}
          </>
        );
      }
    },
    {
      field: "assignedToLogin",
      headerName: "Assigné à",
      align: "center",
      headerAlign: "center",
      maxWidth: 250,
      minWidth: 150,
      renderCell: (params: RowsOfProjectedTasks) => {
        const personnes = params.row.assignedToLogin ? params.row.assignedToLogin.split(" ") : null;
        const nombreDePersonnes = personnes ? personnes.length : null;
        return <>{!nombreDePersonnes ? " … " : nombreDePersonnes === 1 ? <>{params.row.assignedToLogin}</> : " … "}</>;
      }
    },
    {
      field: "theoricStart",
      headerName: "Date début",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        // Date début
        const dateDebut = params.row.theoricStartDate;
        const selectedDateDebut = dateDebut ? new Date(dateDebut) : null;
        const formattedDateDebut = selectedDateDebut ? format(selectedDateDebut, "dd/MM/yy", { locale: frLocale }) : "";

        return (
          <>
            {params.row.theoricStartDate ? (
              <>
                <Tooltip title={relatedToDateJFormatter(params.row.theoricStart)}>
                  <span>{formattedDateDebut}</span>
                </Tooltip>
              </>
            ) : (
              <>{relatedToDateJFormatter(params.row.theoricStart)}</>
            )}
          </>
        );
      }
    },
    {
      field: "deadline",
      headerName: "Date butoir",
      maxWidth: 320,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        // Date butoir
        const dateButoir = params.row?.deadlineDate;
        const selectedDateButoir = dateButoir ? new Date(dateButoir) : null;
        const formatteDateButoir = selectedDateButoir
          ? format(selectedDateButoir, "dd/MM/yy", { locale: frLocale })
          : "";

        return (
          <>
            {params.row?.deadlineDate ? (
              <Tooltip title={relatedToDateJFormatter(params.row.deadline)}>
                <span>{formatteDateButoir}</span>
              </Tooltip>
            ) : (
              <>{relatedToDateJFormatter(params.row.deadline)}</>
            )}
          </>
        );
      }
    },
    {
      field: "estimatedDuration",
      headerName: "Durée estimée",
      maxWidth: 400,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        return (
          <>
            {params.row.estimatedDuration ? (
              <>
                {!params.row.subtaskId ? (
                  <Typography variant="body2" fontWeight="bold" color="primary">
                    {durationFormatter(params.row.estimatedDuration)}
                  </Typography>
                ) : (
                  durationFormatter(params.row.estimatedDuration)
                )}
              </>
            ) : (
              <> - </>
            )}
          </>
        );
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      disableReorder: true,
      minWidth: 90,
      resizable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: RowsOfProjectedTasks) => {
        const onClickAdd = () => {
          editModeAddSubTaskTo(params.row.taskId);
        };

        const onClickEditTask = () => {
          editModeEditTask(params.row.taskId);
        };

        const onClickEditSubTask = () => {
          editModeEditSubTask(params.row.subtaskId);
        };

        const onClickMoveToTop = async () => {
          moveTaskMutation.mutateAsync({
            taskId: params.row.taskId,
            target: "TOP"
          });
        };

        const onClickMoveOneUp = () => {
          moveTaskMutation.mutateAsync({
            taskId: params.row.taskId,
            target: "PREVIOUS"
          });
        };

        const onClickMoveOneDown = () => {
          moveTaskMutation.mutateAsync({
            taskId: params.row.taskId,
            target: "NEXT"
          });
        };

        const onClickMoveToBottom = () => {
          moveTaskMutation.mutateAsync({
            taskId: params.row.taskId,
            target: "BOTTOM"
          });
        };

        const onClickTranlocateTask = () => {
          editModeTranslocateTask(params.row.taskId);
        };

        const onClickMoveToTopSubTask = async () => {
          moveSubTaskMutation.mutateAsync({
            subTaskId: params.row.subtaskId,
            target: "TOP"
          });
        };

        const onClickMoveOneUpSubTask = () => {
          moveSubTaskMutation.mutateAsync({
            subTaskId: params.row.subtaskId,
            target: "PREVIOUS"
          });
        };

        const onClickMoveOneDownSubTask = () => {
          moveSubTaskMutation.mutateAsync({
            subTaskId: params.row.subtaskId,
            target: "NEXT"
          });
        };

        const onClickMoveToBottomSubTask = () => {
          moveSubTaskMutation.mutateAsync({
            subTaskId: params.row.subtaskId,
            target: "BOTTOM"
          });
        };

        const onClickTranlocateSubTask = () => {
          editModeTranslocateSubTask(params.row.subtaskId);
        };

        const onClickDeleteSubTask = () => {
          deleteSubTaskMutation.mutateAsync({
            id: params.row.subtaskId
          });
        };

        const onFocusTask = () => {
          setFocusedTaskId(params.row.taskId);
        };

        const onUnfocusTask = () => {
          setFocusedTaskId(null);
        };

        return (
          <>
            <RowToolbar maxItems="0">
              {editModeAddSubTaskTo && params.row.displayType !== "SUBTASK" && (
                <>
                  <RowToolbarButton title="Ajouter une sous tâche" onClick={onClickAdd}>
                    <PlaylistAddIcon />
                  </RowToolbarButton>

                  <Divider />
                </>
              )}

              <>
                {/* Focus */}
                {params.row.displayType === "UNGROUPED_TASK" && !focusedTaskId && (
                  <RowToolbarButton title="Focus sur la tâche" onClick={onFocusTask}>
                    <FilterCenterFocusIcon />
                  </RowToolbarButton>
                )}

                {params.row.displayType === "UNGROUPED_TASK" && focusedTaskId && (
                  <RowToolbarButton title="Enlever le focus" onClick={onUnfocusTask}>
                    <CropFreeIcon />
                  </RowToolbarButton>
                )}

                {/* Edition tâche */}
                {editModeEditTask && params.row.displayType !== "SUBTASK" && (
                  <RowToolbarButton title="Editer la tâche" onClick={onClickEditTask}>
                    <EditRoundedIcon />
                  </RowToolbarButton>
                )}

                {/* Edition sous-tâche */}
                {editModeEditSubTask &&
                  (params.row.displayType === "SUBTASK" || params.row.displayType === "SINGLE_STASK") && (
                    <RowToolbarButton title="Editer la sous-tâche" onClick={onClickEditSubTask}>
                      <EditRoundedIcon />
                    </RowToolbarButton>
                  )}

                {params.row.displayType !== "UNGROUPED_TASK" && params.row.displayType !== "GROUPED_TASK" && (
                  <RowToolbarButton title="Supprimer la sous-tâche" onClick={onClickDeleteSubTask}>
                    <DeleteForeverIcon />
                  </RowToolbarButton>
                )}

                <RowToolbarButton
                  title="Déplacer la tâche"
                  onClick={params.row.displayType === "SUBTASK" ? onClickTranlocateSubTask : onClickTranlocateTask}
                >
                  <MoveToInboxIcon />
                </RowToolbarButton>

                <Divider />
              </>

              <>
                <RowToolbarButton
                  title="Monter en tête de liste"
                  onClick={params.row.displayType === "SUBTASK" ? onClickMoveToTopSubTask : onClickMoveToTop}
                >
                  <VerticalAlignTopIcon />
                </RowToolbarButton>

                <RowToolbarButton
                  title="Monter"
                  onClick={params.row.displayType === "SUBTASK" ? onClickMoveOneUpSubTask : onClickMoveOneUp}
                >
                  <ArrowUpwardIcon />
                </RowToolbarButton>

                <RowToolbarButton
                  title="Descendre"
                  onClick={params.row.displayType === "SUBTASK" ? onClickMoveOneDownSubTask : onClickMoveOneDown}
                >
                  <ArrowDownwardIcon />
                </RowToolbarButton>

                <RowToolbarButton
                  title="Descendre en bas de liste"
                  onClick={params.row.displayType === "SUBTASK" ? onClickMoveToBottomSubTask : onClickMoveToBottom}
                >
                  <VerticalAlignBottomIcon />
                </RowToolbarButton>
              </>
            </RowToolbar>
          </>
        );
      }
    },
    {
      field: "rank",
      headerName: "Rang",
      minWidth: 100,
      sortable: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: RowsOfProjectedTasks) => {
        return <small>{params.row.rank}</small>;
      }
    }
  ];

  // const trigger = useScrollTrigger({
  //   threshold: 400, // Valeur pour le déclenchement du scroll
  //   disableHysteresis: true,
  // });

  // editer les infos du calendrier
  const [editContext, setEditContext] = useState<EditCalendrierContext>({
    editMode: EditCalendrierMode.NONE,
    calendarId: null
  });

  const clearEditContext = () => {
    setEditContext({ editMode: EditCalendrierMode.NONE, calendarId: null });
  };

  const editModeEditCalendrier = (calendarId: number) => {
    setEditContext({ editMode: EditCalendrierMode.EDIT, calendarId });
  };

  const onClickOpenPlanning = (id: number) => {
    // history.push(`/preparation/planning/${id}`);
    navigate(`/preparation/planning/${id}`);
  };

  return (
    <>
      {/* Table actions */}
      {/* <Box
        position={trigger ? "fixed" : "relative"}
        top={trigger ? 0 : "auto"}
        left={trigger ? 0 : "auto"}
        zIndex={100}
        minWidth="100%"
        bgcolor="white"
        boxShadow={trigger ? 1 : 0}
        marginTop={trigger ? 0 : 2}
        display="flex"
        justifyContent="space-evenly"
        mb={4}
        p={2}
      > */}
      <Box
        position={"relative"}
        top={"auto"}
        left={"auto"}
        zIndex={100}
        minWidth="100%"
        bgcolor="white"
        boxShadow={0}
        display="flex"
        justifyContent="space-evenly"
        mt={2}
        mb={2}
        p={2}
      >
        <Link to="/preparation/calendars">
          <Button variant="outlined" startIcon={<ArrowBackIcon />}>
            Retour
          </Button>
        </Link>

        <Button variant="outlined" startIcon={<EditRoundedIcon />} onClick={() => editModeEditCalendrier(calendar.id)}>
          Editer
        </Button>

        {groupedTaskIds.length === 0 && (
          <Button
            variant="outlined"
            startIcon={<UnfoldLessIcon />}
            onClick={() => {
              groupAll();
            }}
          >
            Grouper
          </Button>
        )}

        {groupedTaskIds.length > 0 && (
          <Button
            variant="outlined"
            startIcon={<UnfoldMoreIcon />}
            onClick={() => {
              ungroupAll();
            }}
          >
            Dégrouper
          </Button>
        )}

        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => onClickOpenPlanning(calendar.id)}>
          Vue Planning
        </Button>

        <Button
          variant="outlined"
          startIcon={<SaveAltIcon />}
          onClick={() => {
            exportFile();
          }}
        >
          Exporter
        </Button>

        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => editModeAddTask()}>
          Ajouter une tache
        </Button>
      </Box>

      <DataGrid
        datagridKey="CalendarTasksTable"
        rows={rows}
        columns={columns}
        density="compact"
        initialState={{
          columns: {
            columnVisibilityModel: {
              rank: false
            }
          },
          pinnedColumns: { right: ["actions"] },
          // pagination: {
          //   paginationModel: {
          //     pageSize: 20,
          //   },
          // },
          sorting: {
            sortModel: [{ field: "rank", sort: "asc" }]
          }
        }}
        // pageSizeOptions={[20]}
      />

      {/* Dialogs */}
      <DialogSide
        title={"Editer le calendrier"}
        onClose={clearEditContext}
        open={editContext.editMode === EditCalendrierMode.EDIT}
      >
        <CalendarFormEdit editContext={editContext} clearEditContext={clearEditContext} />
      </DialogSide>
    </>
  );
};

export default CalendarTasksTable;
