import ApartmentIcon from "@mui/icons-material/Apartment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditRoundedIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { GridHeaderFilterCellProps } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { Company, useDeleteCompanyMutation } from "../../../api/services";
import ConfirmPopIn from "../../../components/DialogConfirm";
import DialogSide from "../../../components/DialogSide";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import CompanieFormEdit from "./CompanieFormEdit";
import { CrudEditMode, EditCompanyContext } from "./Types";

type CompaniesTableProps = {
  companies: Company[];
};

const CompaniesTable = ({ companies }: CompaniesTableProps) => {
  const [editContext, setEditContext] = useState<EditCompanyContext>({ editMode: CrudEditMode.NONE, companyId: null });

  const deleteCompanieMutation = useDeleteCompanyMutation();

  const clearEditContext = () => {
    setEditContext({ editMode: CrudEditMode.NONE, companyId: null });
  };

  const editModeEditCompanie = (companyId: number) => {
    setEditContext({ editMode: CrudEditMode.EDIT, companyId });
  };

  const editModeDeleteCompanie = (companyId: number) => {
    setEditContext({ editMode: CrudEditMode.DEL, companyId });
  };

  const doDeleteCompanie = async () => {
    deleteCompanieMutation.mutate({ id: editContext.companyId });
    clearEditContext();
  };

  const columns = [
    {
      field: "label",
      headerName: "Société",
      filterable: true,
      minWidth: 400,
      renderCell: (params: { row: Company }) => {
        return (
          <Stack direction="row" spacing={2}>
            <ApartmentIcon fontSize="small" />
            <strong>{params.row.label}</strong>
          </Stack>
        );
      }
    },
    {
      field: "description",
      headerName: "Description",
      filterable: true,
      minWidth: 200,
      flex: 1
    },
    {
      field: "actions",
      headerName: "Actions",
      disableReorder: true,
      minWidth: 90,
      resizable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => <></>,
      renderCell: (params: { row: Company }) => {
        const onClickEdit = () => {
          editModeEditCompanie(params.row.id);
        };

        const onClickDelete = () => {
          editModeDeleteCompanie(params.row.id);
        };

        return (
          <Box display="flex" alignItems="center">
            <Tooltip title="Editer la société">
              <IconButton onClick={onClickEdit} size="large">
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Supprimer la société">
              <IconButton onClick={onClickDelete} size="large">
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    }
  ];

  const rows = companies;

  return (
    <>
      <DataGrid
        datagridKey="CompaniesTable"
        // disableColumnFilter
        // disableColumnSelector
        disableDensitySelector
        rows={rows}
        columns={columns}
        initialState={{
          pinnedColumns: { right: ["actions"] },
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          },
          sorting: {
            sortModel: [{ field: "label", sort: "asc" }]
          }
        }}
      />

      {/* {companies && (
        <details>
          <summary>Companies</summary>
          <pre>
            <code>{JSON.stringify(companies, null, 2)}</code>
          </pre>
        </details>
      )} */}

      {/* Dialogs */}
      <>
        <ConfirmPopIn
          open={editContext.editMode === CrudEditMode.DEL}
          onClose={clearEditContext}
          onConfirm={() => doDeleteCompanie()}
          dialogTitle={"Veuillez confirmer la suppression de cette société"}
          dialogContent={
            "Attention, cette opération est irréversible et ne peut réussir que si aucun calendrier ne lui est associé "
          }
          dialogAction={"Confirmer"}
        />

        <DialogSide
          title={"Editer la société"}
          onClose={clearEditContext}
          open={editContext.editMode === CrudEditMode.EDIT}
        >
          <CompanieFormEdit editContext={editContext} clearEditContext={clearEditContext} />
        </DialogSide>
      </>
    </>
  );
};

export default CompaniesTable;
