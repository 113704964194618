/* eslint-disable no-unused-vars */
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Button, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { useState } from "react";
import { useSubTaskDetail } from "../../../api/services";
import { SubTaskStatus, SubTaskStatusLabels } from "../../../api/taskTypes";
import { durationFormatter } from "../../../api/tasksUtils";
import Loading from "../../../components/Loading";
import DetailsForm from "./DetailsForm";
import DurationForm from "./DurationForm";
import { EditContext } from "./Types";

// import CheckBoxIcon from "@mui/icons-material/CheckBox";

type SubTaskViewProps = {
  editContext: EditContext;
  clearEditContext: Function;
  onClickComplete?: Function;
};

const statuses = [
  SubTaskStatus.PENDING,
  SubTaskStatus.DONE,
  SubTaskStatus.SKIPPED,
  SubTaskStatus.POSTPONED,
  SubTaskStatus.REMAINDER
];

const SubTaskView = (props: SubTaskViewProps) => {
  const { data: subtask } = useSubTaskDetail({ id: props.editContext.subTaskId });
  const [isDurationVisible, setIsDurationVisible] = useState(false);
  const toggleDurationVisibility = () => {
    setIsDurationVisible(!isDurationVisible);
  };

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const toggleDetailsVisibility = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  // 2. GESTION DES DATES
  // Date début
  const dateDebut = subtask?.theoricStartDate;
  const selectedDateDebut = dateDebut ? new Date(dateDebut) : null;
  const formattedDateDebut = selectedDateDebut ? format(selectedDateDebut, "dd MMMM yyyy", { locale: frLocale }) : "";

  // Date butoir
  const dateButoir = subtask?.deadlineDate;
  const selectedDateButoir = dateButoir ? new Date(dateButoir) : null;
  const formatteDateButoir = selectedDateButoir ? format(selectedDateButoir, "dd MMMM yyyy", { locale: frLocale }) : "";

  // Date réalisation
  const dateReal = subtask?.executedOn;
  const selectedDateReal = dateReal ? new Date(dateReal) : null;
  const formattedDateReal = selectedDateReal
    ? format(selectedDateReal, "dd MMMM yyyy à HH:mm", { locale: frLocale })
    : "";

  // TODO: style temporaire
  const th = {
    backgroundColor: "#eee",
    width: "40%"
  };

  if (!subtask) {
    return <Loading />;
  }

  // CustomTableCell pour supprimer le padding
  const CustomTableCell = withStyles({
    root: {
      padding: 0
    }
  })(TableCell);

  return (
    <>
      {/* {subtask && (
        <pre>
          <code>{JSON.stringify(subtask, null, 2)}</code>
        </pre>
      )} */}

      <TableContainer sx={{ marginTop: 2 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <CustomTableCell colSpan={2}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {subtask.label}
                </Typography>
              </CustomTableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Calendrier</strong>
              </TableCell>
              <TableCell>{subtask.calendarShortName}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Instructions </strong>
              </TableCell>
              <TableCell>
                {subtask.instructions != null && subtask.instructions !== "null" ? subtask.instructions : "-"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Thème </strong>
              </TableCell>
              <TableCell>{subtask.themeLabel}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Tâche </strong>
              </TableCell>
              <TableCell>{subtask.taskLabel}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Date début </strong>
              </TableCell>
              <TableCell>
                <ListItemText secondary={<>{formattedDateDebut}</>} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Date butoir</strong>
              </TableCell>
              <TableCell>
                <ListItemText secondary={<>{formatteDateButoir}</>} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Date de réalisation</strong>
              </TableCell>
              <TableCell>
                {subtask.status !== "DONE" && (
                  <span style={{ textTransform: "lowercase" }}>{SubTaskStatusLabels[subtask.status]}</span>
                )}
                <ListItemText secondary={<>{formattedDateReal}</>} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Durée prévue</strong>
              </TableCell>
              <TableCell>{subtask.estimatedDuration ? durationFormatter(subtask.estimatedDuration) : "-"}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Durée réelle</strong>
              </TableCell>
              <TableCell>
                <Button
                  startIcon={isDurationVisible ? <CloseIcon /> : <EditIcon />}
                  onClick={toggleDurationVisibility}
                />
                {isDurationVisible ? (
                  <DurationForm subtask={subtask} />
                ) : (
                  <>{durationFormatter(subtask.executionDuration)}</>
                )}
                {/* {isDurationVisible ? "Fermer" : "Saisir un temps"}
                </Button> */}
              </TableCell>
            </TableRow>

            {subtask.eligibleRemainder && (
              <TableRow>
                <TableCell component="th" scope="row" style={th}>
                  <strong>Reliquat</strong>
                </TableCell>
                <TableCell>Eligible</TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell component="th" scope="row" style={th}>
                <strong>Commentaires</strong>
              </TableCell>
              <TableCell>
                <Button startIcon={isDetailsVisible ? <CloseIcon /> : <EditIcon />} onClick={toggleDetailsVisibility} />
                {isDetailsVisible ? <DetailsForm subtask={subtask} /> : <>{subtask.executionDetails}</>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* TODO: Ajouter les actions dans la modales */}
      {/* {subtask.status === "PENDING" && <Button variant="outlined" startIcon={<CheckBoxIcon />}>Compléter</Button>} */}
    </>
  );
};
export default SubTaskView;
