import { Button, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Calendrier, CalendrierStatus, useListeCalendriers, useTranslocateTaskMutation } from "../../../api/services";
import ControllerSelect from "../../../components/form/ControllerSelect";
import { EditTaskContext } from "./Types";

interface Values {
  calendarId: number | "";
}

type TranslocateTaskFormProps = {
  editContext: EditTaskContext;
  clearEditContext: Function;
};

const TaskFormTranslocate = ({ editContext, clearEditContext }: TranslocateTaskFormProps) => {
  const { data: calendars } = useListeCalendriers();

  const [filteredCalendriers, setFilteredCalendriers] = useState<Calendrier[]>([]);

  useEffect(() => {
    if (calendars) {
      setFilteredCalendriers(calendars?.filter((calendar) => calendar.status !== CalendrierStatus.ARCHIVED));
    }
  }, [calendars, setFilteredCalendriers]);

  const translocateTaskMutation = useTranslocateTaskMutation();

  const formMethods = useForm<Values>({
    defaultValues: {
      calendarId: ""
    },
    mode: "onChange"
  });

  const onSubmit = async (values: Values) => {
    if (values.calendarId === "") {
      toast.error("Aucune tâche n'a été sélectionnée");
      return;
    }
    if (editContext.calendarId === values.calendarId) {
      toast.error("Cette tâche est déjà dans ce calendrier");
      return;
    }
    await translocateTaskMutation.mutateAsync({
      taskId: editContext.taskId,
      calendarId: values.calendarId
    });

    if (clearEditContext) {
      clearEditContext();
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
      {filteredCalendriers && !!filteredCalendriers.length && (
        <ControllerSelect
          name="calendarId"
          formMethods={formMethods}
          required
          label="Déplacer vers le calendrier "
          mappedMenuItems={filteredCalendriers.map((calendar, index) => (
            <MenuItem key={calendar.id} value={calendar.id}>
              {calendar.companyLabel} - {calendar.shortName}
            </MenuItem>
          ))}
        />
      )}

      <Button variant="outlined" type="submit">
        Déplacer
      </Button>
    </form>
  );
};
export default TaskFormTranslocate;
